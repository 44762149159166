import styled from "styled-components";
import WidgetContainer from "../WidgetContainer/WidgetContainer";
import { useState } from "react";

const RealCashFlow = ({ kpi, onChange }) => {

  const [ range, setRange ] = useState('month');

  const selectRangeHandler = (range) => () => {
    setRange(range);
    onChange(range);
  };

  return (
    <WidgetContainer sm={ 2 } md={ 2 } lg={ 2 }>
      <TitleContainer>
        <h4>Cash Flow Effettivo</h4>
        <Switcher>
          <span
            onClick={ selectRangeHandler('month') }
            className={ `${ range === 'month' ? 'active' : '' }` }>
            Mensile
          </span>
          <span>/</span>
          <span
            onClick={ selectRangeHandler('year') }
            className={ `${ range === 'year' ? 'active' : '' }` }>
            Annuale
          </span>
        </Switcher>
      </TitleContainer>
      <CounterContainer>
        <Counter>{ (Math.round(kpi * 100) / 100).toFixed(2) }</Counter>
        <Currency>€</Currency>
      </CounterContainer>
    </WidgetContainer>
  );

};

export default RealCashFlow;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Switcher = styled.div`
  span {
    margin-right: 5px;
    cursor: pointer;
    &.active {
      cursor: default;
      font-weight: bold;
      color: #228BE6;
    }
  }
  span:last-child {
    margin-right: 0;
  }
`;

const CounterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const Counter = styled.div`
  font-size: 3rem;
  color: #228BE6;
`;

const Currency = styled.div`
  font-size: 3rem;
  color: #228BE6;
  margin-left: 10px;
`;
