import { useSelector } from "react-redux";
import {
  operatorModalSelector,
  operatorModalType,
  operatorsListSelector,
  operatorsLoadingSelector,
  selectedOperatorSelector
} from "../store/operators/selectors";

const useOperators = () => {

  const operatorsList = useSelector(operatorsListSelector);
  const selectedOperator = useSelector(selectedOperatorSelector);
  const showOperatorsModal = useSelector(operatorModalSelector);
  const modalType = useSelector(operatorModalType);
  const isLoading = useSelector(operatorsLoadingSelector);

  return {
    operatorsList,
    selectedOperator,
    showOperatorsModal,
    modalType,
    isLoading
  };

};

export default useOperators;
