const { createSelector } = require("@reduxjs/toolkit");

const CourseSearchStore = (state) => state.courseSearch;

export const courseSearchLoadingSelector = createSelector(
  CourseSearchStore,
  (courseSearchState) => courseSearchState.loading
);

export const courseSearchErrorSelector = createSelector(
  CourseSearchStore,
  (courseSearchState) => courseSearchState.error
);

export const courseSearchResultsSelector = createSelector(
  CourseSearchStore,
  (courseSearchState) => courseSearchState.results
);
