import axios from "axios";
import { BASE_URL } from "../constants/endpoints";

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

export const setAuthorizationToken = (token = null) => {
  if (token && typeof token === 'string' && token.trim()) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${ token }`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export const setAuthInterceptor = () => {
  let returnError = false;
  instance.interceptors.response.use(
    (response) => response,
    (error) => {
      const { status, data } = error.response;
      if (
        (status === 401 && data.type === 'TokenExpiredException') ||
        (status === 403 && data.type === 'InvalidSignatureException')
      ) {
        localStorage.clear();
        returnError = true;
      }
      return Promise.reject(error);
  });
  return returnError;
};

export const http = async (endpoint, config) => instance.request({ url: endpoint, ...config });
