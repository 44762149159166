import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveOperatorsList = createAsyncThunk('Operators/List', async (name) => {
  let data;
  let config = {
    method: 'GET'
  };
  if (name && name.trim()) {
    config.params = {
      name
    };
  }
  try {
    const response = await http(endpoints.OPERATORS, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.operatori;
});

export const addOperator = createAsyncThunk('Operators/Add', async ({ name, surname, sex, email, role }) => {
  let insertedOperator;
  try {
    const response = await http(endpoints.OPERATORS, {
      method: 'POST',
      data: {
        name,
        surname,
        sex,
        email,
        role
      }
    });
    insertedOperator = response.data.operatore;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedOperator;
});

export const editOperator = createAsyncThunk('Operators/Edit', async ({ id, operator }) => {
  console.log('id', id);
  console.log('operator', operator);
  let response;
  try {
    response = await http(`${ endpoints.OPERATORS }/${ id }`, {
      method: 'PATCH',
      data: {
        ...operator
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.operatore;
});

export const changeStatusOperator = createAsyncThunk('Operators/Change Status', async ({ id }) => {
  let response;
  try {
    response = await http(`${ endpoints.OPERATORS }/${ id }`, {
      method: 'PATCH'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.operatore;
});

export const deleteOperator = createAsyncThunk('Operators/Delete', async (id) => {
  try {
    await http(`${ endpoints.OPERATORS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreOperator = createAsyncThunk('Operators/Restore', async (id) => {
  try {
    await http(`${ endpoints.OPERATORS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

const thunks = { retrieveOperatorsList, addOperator, editOperator, changeStatusOperator, deleteOperator, restoreOperator };

export default thunks;
