import { Modal } from "react-bootstrap";
import useCourses from "../../../hooks/useCourses";
import { useDispatch } from "react-redux";
import { closeCoursesModal } from "../../../store/courses/actions";
import { addCourse, deleteCourse, editCourse, restoreCourse } from "../../../store/courses/thunk";
import { Modals } from "../../../constants/modals";
import CourseModalDelete from "./CourseModalDelete";
import CourseModalRestore from "./CourseModalRestore";
import CourseModalAdd from "./CourseModalAdd";
import CourseModalEdit from "./CourseModalEdit";

const CourseModal = () => {

  const dispatch = useDispatch();
  const { selectedCourse, showCoursesModal, modalType } = useCourses();

  const closeModalHandler = () => dispatch(closeCoursesModal());

  const addCourseHandler = (course) => dispatch(addCourse(course));
  const editCourseHandler = (course) => dispatch(editCourse({ id: selectedCourse.id, course }));
  const deleteCourseHandler = () => dispatch(deleteCourse(selectedCourse.id));
  const restoreCourseHandler = () => dispatch(restoreCourse(selectedCourse.id));

  return (
    <Modal
      size="xl"
      centered
      show={ showCoursesModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.Courses.ADD &&
        <CourseModalAdd
          onClose={ closeModalHandler }
          onAdd={ addCourseHandler }/>
      }
      {
        modalType && modalType === Modals.Courses.EDIT &&
        <CourseModalEdit
          course={ selectedCourse }
          onClose={ closeModalHandler }
          onEdit={ editCourseHandler }/>
      }
      {
        modalType && modalType === Modals.Courses.DELETE &&
        <CourseModalDelete
          course={ selectedCourse }
          onClose={ closeModalHandler }
          onDelete={ deleteCourseHandler }/>
      }
      {
        modalType && modalType === Modals.Courses.RESTORE &&
        <CourseModalRestore
          course={ selectedCourse }
          onClose={ closeModalHandler }
          onRestore={ restoreCourseHandler }/>
      }
    </Modal>
  );

}

export default CourseModal;
