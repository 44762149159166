import styled from "styled-components";
import { Badge, Form, FormGroup, Spinner } from "react-bootstrap";
import { useState } from "react";

const LiveSearch = ({
  label,
  type,
  disabled = false,
  list = [],
  loading = false,
  onChange = () => null,
  onSelect = () => null,
  existingValue = null
}) => {

  const [ value, setValue ] = useState(existingValue
    ? existingValue.nome && existingValue.cognome
      ? `${ existingValue.nome } ${ existingValue.cognome }`
      : existingValue.titolo
    : ''
  );
  const [ show, setShow ] = useState(false);

  const toggleHandler = () => setShow((state) => !state);
  const onBlurHandler = () => null;
  const onChangeHandler = (e) => {
    setShow(true);
    setValue(e.target.value);
    onChange(e.target.value);
  };
  const onSelectItemHandler = (item) => () => {
    let value;
    switch (type) {
      case 'students':
      case 'operators': {
        value = `${ item.nome } ${ item.cognome }`;
        break;
      }
      case 'courses': {
        value = item.titolo;
        break;
      }
      default: {
        value = null;
      }
    }
    setValue(value);
    setShow(false);
    onSelect(item);
  };

  return (
    <Container
      onMouseLeave={ onBlurHandler }>
      <FormGroup>
        <Form.Label>{ label }</Form.Label>
        <Form.Control
          style={{ borderColor: '#ced4da', boxShadow: 'none' }}
          onChange={ onChangeHandler }
          onClick={ toggleHandler }
          value={ value }
          disabled={ loading || disabled }
          type="text"
          placeholder="Scrivi per cercare oppure seleziona dalla lista"/>
        {
          show && !loading && (
            <ListContainer onMouseLeave={ onBlurHandler }>
              {
                list && list.map((item) => (
                  <ListItem key={ item.id } onClick={ onSelectItemHandler(item) }>
                    <div>
                      {
                        type === 'students' && (
                          <>
                            <span>{ `${ item.nome } ${ item.cognome }` }</span>
                            <div>
                              <CustomBadge className="bg-primary text-light">
                                {
                                  new Intl.DateTimeFormat('it-IT', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    timeZone: 'Europe/Rome'
                                  }).format(new Date(item.data_nascita))
                                }
                              </CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.codice_fiscale }</CustomBadge>
                            </div>
                          </>
                        )
                      }
                      {
                        type === 'operators' && (
                          <>
                            <span>{ `${ item.nome } ${ item.cognome }` }</span>
                            <div>
                              <CustomBadge className="bg-primary text-light">{ item.ruolo }</CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.email }</CustomBadge>
                            </div>
                          </>
                        )
                      }
                      {
                        type === 'courses' && (
                          <>
                            <span>{ item.titolo }</span>
                            <div>
                              <CustomBadge className="bg-primary text-light">
                                {
                                  new Intl.DateTimeFormat('it-IT', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                    timeZone: 'Europe/Rome'
                                  }).format(new Date(item.data_inizio))
                                }
                              </CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.sede }</CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.turno }</CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.ore } ore</CustomBadge>
                              <CustomBadge className="bg-primary text-light">{ item.frequenza }</CustomBadge>
                            </div>
                          </>
                        )
                      }
                    </div>
                  </ListItem>
                ))
              }
              {
                !list.length && (
                  <ListItem className="no-results">
                    <div>
                      <>
                        <span>Nessun elemento trovato</span>
                      </>
                    </div>
                  </ListItem>
                )
              }
            </ListContainer>
          )
        }
        {
          loading && (
            <Loading>
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                aria-hidden="true"
                variant="primary"
              />
            </Loading>
          )
        }
      </FormGroup>
    </Container>
  );

};

export default LiveSearch;

const Container = styled.div`
  position: relative;
`;

const ListContainer = styled.ul`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 250px;
  background-color: white;
  border-bottom-right-radius: .25rem;
  border-bottom-left-radius: .25rem;
  z-index: 10;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform: translateY(calc(100% - 2px));
  border: 1px solid #ced4da;
  overflow-y: auto;
`;

const ListItem = styled.li`
  padding: 5px;
  border-bottom: 1px solid #ced4da;
  
  &:last-child {
    border-bottom: 0;
  }
  
  &:hover:not(.no-results) {
    cursor: pointer;
    background-color: #fafafa;
  }
  
  > span {
    display: block;
    padding: .375rem .75rem;
  }
`;

const Loading = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CustomBadge = styled(Badge)`
  margin-right: 5px;
`;
