export const Slices = {
  AUTH: 'auth',
  COURSES: 'courses',
  COURSE_SUBSCRIPTIONS: 'courseSubscriptions',
  COURSE_TURNS: 'courseTurns',
  COURSE_TITLES: 'courseTitles',
  TYPES: 'types',
  STUDENTS: 'students',
  LOCATIONS: 'locations',
  OPERATORS: 'operators',
  COURSE_SEARCH: 'course-search',
  KPI: 'kpi'
};
