import { useSelector } from "react-redux";
import { courseTitlesListSelector, selectedCourseTitleSelector, courseTitleModalSelector, courseTitleModalType } from "../store/courseTitles/selectors";

const useCourseTitles = () => {

  const courseTitlesColumns = [
    { id: 'id', name: 'ID' },
    { id: 'titolo', name: 'Titolo corso' },
    { id: 'deleted', name: 'Stato' },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const courseTitlesList = useSelector(courseTitlesListSelector);
  const selectedCourseTitle = useSelector(selectedCourseTitleSelector);
  const showCourseTitleModal = useSelector(courseTitleModalSelector);
  const modalType = useSelector(courseTitleModalType);

  return {
    courseTitlesColumns,
    courseTitlesList,
    selectedCourseTitle,
    showCourseTitleModal,
    modalType
  };

};

export default useCourseTitles;
