import { Button, Form, Spinner } from "react-bootstrap";
import { useState } from "react";
import classes from './LoginForm.module.scss';
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const LoginForm = ({ loading, errorMessage, onSubmit }) => {

  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');
  const [ emailIsValid, setEmailIsValid ] = useState(false);
  const [ passwordIsValid, setPasswordIsValid ] = useState(false);
  const [ emailIsTouched, setEmailIsTouched] = useState(false);
  const [ passwordIsTouched, setPasswordIsTouched] = useState(false);

  const validateEmail = (value) => EMAIL_REGEX.test(value);
  const validatePassword = (value) => value && value.trim() && value.trim().length >= 8;

  const emailHandler = (e) => setEmail(e.target.value);
  const passwordHandler = (e) => setPassword(e.target.value);

  const emailBlurHandler = (e) => {
    setEmailIsTouched(true);
    setEmailIsValid(validateEmail(e.target.value));
  }
  const passwordBlurHandler = (e) => {
    setPasswordIsTouched(true);
    setPasswordIsValid(validatePassword(e.target.value));
  }

  const submitHandler = (e) => {
    e.preventDefault();
    if (emailIsValid && passwordIsValid) {
      onSubmit({ email, password });
    }
  };

  return (
    <Form
      noValidate
      className={ classes['login--form'] }
      onSubmit={ submitHandler }>
      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          onChange={ emailHandler }
          onBlur={ emailBlurHandler }
          type="email"
          value={ email }
          placeholder="Inserisci la tua email"
          required/>
        { !emailIsValid && emailIsTouched && <small className="text-danger">Email non valida</small> }
      </Form.Group>
      <Form.Group controlId="formBasicPassword">
        <Form.Label>Password</Form.Label>
        <Form.Control
          onChange={ passwordHandler }
          onBlur={ passwordBlurHandler }
          type="password"
          value={ password }
          placeholder="Inserisci la tua password"
          required/>
        { !passwordIsValid && passwordIsTouched && <small className="text-danger">La password deve essere lunga almeno 8 caratteri</small> }
      </Form.Group>
      <Form.Group className="d-flex align-items-center justify-content-end">
        { errorMessage && errorMessage.trim().length > 0 &&
        <small className={ `text-danger ${ classes['login-error'] }` }>{ errorMessage }</small> }
        <Button variant="primary" type="submit" disabled={ loading }>
          { loading && <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          /> }
          <FontAwesomeIcon icon={ faSignInAlt }/> Login
        </Button>
      </Form.Group>
    </Form>
  );

}

export default LoginForm;
