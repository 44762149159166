import { Button, Modal } from "react-bootstrap";

const StudentModalRestore = ({ loading, student, onClose, onRestore }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso ripristino studente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          student && <p>Vuoi davvero ripristinare lo studente <strong>{ student.nome } { student.cognome }</strong>?</p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }>Chiudi</Button>
        <Button
          variant="primary"
          disabled={ loading }
          onClick={ onRestore }>{ loading ? 'Ripristino in corso...' : 'Ripristina studente' }</Button>
      </Modal.Footer>
    </>
  );

}

export default StudentModalRestore;
