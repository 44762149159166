const StudentsInitialState = {
  students: [],
  loading: false,
  error: null,
  selectedStudent: null,
  showModal: false,
  modalType: null
};

export default StudentsInitialState;
