const CourseTitlesInitialState = {
  titles: [],
  loading: false,
  error: null,
  selectedCourseTitle: null,
  showModal: false,
  modalType: null
};

export default CourseTitlesInitialState;
