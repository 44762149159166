import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";

const SearchCourseResults = ({
  pristine,
  results,
  onStudentExport,
  onStudentCompleteExport,
  onSubscriptionExport,
  onInstallmentsExport,
  onFadExport,
  onDetailsExport
}) => {

  const studentExport = (courseId) => () => onStudentExport(courseId);
  const studentCompleteExport = (courseId) => () => onStudentCompleteExport(courseId);
  const subscriptionExport = (courseId) => () => onSubscriptionExport(courseId);
  const installmentsExport = (courseId) => () => onInstallmentsExport(courseId);
  const fadExport = (courseId) => () => onFadExport(courseId);
  const detailsExport = (courseId) => () => onDetailsExport(courseId);

  return (
    <Container>
      {
        results && results.length > 0 && results.map((result) => (
          <CustomCard key={ result.id }>
            <CardDetails>
              <h4>{ result.titolo }</h4>
              {
                result.codice_bsa && (
                  <div>
                    Codice BSA: <strong>{ result.codice_bsa }</strong>
                  </div>
                )
              }
              <div>
                Data di inizio: <strong>{
                  new Intl.DateTimeFormat('it-IT', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    timeZone: 'Europe/Rome'
                  }).format(new Date(result.data_inizio))
                }</strong>
              </div>
              <div>Turno: <strong>{ result.turno }</strong></div>
              <div>Ore: <strong>{ result.ore }</strong></div>
              <div>Frequenza: <strong>{ result.frequenza }</strong></div>
              <div>Sede: <strong>{ result.sede }</strong></div>
            </CardDetails>
            <CardActions>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ studentExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> Studenti
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ studentCompleteExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> Stud. Compl.
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ subscriptionExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> Iscrizioni
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ installmentsExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> Rate
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ fadExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> FAD
              </Button>
              <Button
                variant="outline-primary"
                size="sm"
                onClick={ detailsExport(result.id) }>
                <FontAwesomeIcon icon={ faDownload }/> Dati corso
              </Button>
            </CardActions>
          </CustomCard>
        ))
      }
      {
        !pristine && results && results.length === 0 && (
          <CustomCard className="d-flex align-items-center justify-content-center">
            <p>Nessun risultato trovato</p>
          </CustomCard>
        )
      }
    </Container>
  );

};

export default SearchCourseResults;

const Container = styled.div`
  margin-top: 30px;
`;

const CustomCard = styled.div`
  background-color: #fff;
  margin: 20px 0;
  padding: 20px;
  border-radius: 15px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, .125);
  display: flex;
  align-items: center;
  
  p {
    margin-bottom: 0;
  }
`;

const CardDetails = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
  
`;

const CardActions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  
  button {
    margin-bottom: 20px;
    min-width: 120px;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;
