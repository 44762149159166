import classes from './FloatingButton.module.scss';
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const FloatingButton = ({ onClick }) => {

  return (
    <div className={ classes['floating-button'] } onClick={ onClick }>
      <FontAwesomeIcon icon={ faPlus }/>
    </div>
  );

}

export default FloatingButton;
