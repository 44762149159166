import { Button, Modal } from "react-bootstrap";

const SubscriptionModalRestore = ({ sub, onClose, onRestore }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso ripristino contratto
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Vuoi davvero ripristinare il contratto di iscrizione <strong>{ sub && sub.codice_contratto }</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ onRestore }>Ripristina iscrizione</Button>
      </Modal.Footer>
    </>
  );

}

export default SubscriptionModalRestore;
