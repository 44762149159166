import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const OperatorModalAdd = ({ loading, onClose, onAdd }) => {

  const [ name, setName ] = useState('');
  const [ surname, setSurname ] = useState('');
  const [ sex, setSex ] = useState(0);
  const [ email, setEmail ] = useState('');
  const [ role, setRole ] = useState(0);
  const [ nameIsTouched, setNameIsTouched ] = useState(false);
  const [ surnameIsTouched, setSurnameIsTouched ] = useState(false);
  const [ sexIsTouched, setSexIsTouched ] = useState(false);
  const [ emailIsTouched, setEmailIsTouched ] = useState(false);
  const [ roleIsTouched, setRoleIsTouched ] = useState(false);
  const [ nameIsValid, setNameIsValid ] = useState(false);
  const [ surnameIsValid, setSurnameIsValid ] = useState(false);
  const [ sexIsValid, setSexIsValid ] = useState(false);
  const [ emailIsValid, setEmailIsValid ] = useState(false);
  const [ roleIsValid, setRoleIsValid ] = useState(false);

  const formIsValid = nameIsValid && surnameIsValid && sexIsValid && emailIsValid && roleIsValid;

  const validateName = (value) => value && value.trim() && value.trim().length >= 3;
  const validateSurname = (value) => value && value.trim() && value.trim().length >= 3;
  const validateEmail = (value) => EMAIL_REGEX.test(value);
  const validateSelect = (value) => value && value !== 0;

  const nameHandler = (e) => setName(e.target.value);
  const surnameHandler = (e) => setSurname(e.target.value);
  const sexHandler = (e) => setSex(e.target.value);
  const emailHandler = (e) => setEmail(e.target.value);
  const roleHandler = (e) => setRole(e.target.value);

  const nameBlurHandler = (e) => {
    setNameIsTouched(true);
    setNameIsValid(validateName(e.target.value));
  };
  const surnameBlurHandler = (e) => {
    setSurnameIsTouched(true);
    setSurnameIsValid(validateSurname(e.target.value));
  };
  const sexBlurHandler = (e) => {
    setSexIsTouched(true);
    setSexIsValid(validateSelect(e.target.value));
  };
  const emailBlurHandler = (e) => {
    setEmailIsTouched(true);
    setEmailIsValid(validateEmail(e.target.value));
  };
  const roleBlurHandler = (e) => {
    setRoleIsTouched(true);
    setRoleIsValid(validateSelect(e.target.value));
  };

  const addOperatorHandler = (e) => {
    e.preventDefault();
    if (formIsValid) {
      onAdd({ name, surname, sex, email, role });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Creazione nuovo operatore
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ addOperatorHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  onChange={ nameHandler }
                  onBlur={ nameBlurHandler }
                  value={ name }
                  type="text"
                  placeholder="Inserisci il nome dell'operatore"
                  required/>
                { !nameIsValid && nameIsTouched && <small className="text-danger">Nome non valido</small> }
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formSurname">
                <Form.Label>Cognome</Form.Label>
                <Form.Control
                  onChange={ surnameHandler }
                  onBlur={ surnameBlurHandler }
                  value={ surname }
                  type="text"
                  placeholder="Inserisci il cognome dell'operatore"
                  required/>
                { !surnameIsValid && surnameIsTouched && <small className="text-danger">Cognome non valido</small> }
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formSex">
                <Form.Label>Sesso</Form.Label>
                <Form.Control
                  as="select"
                  value={ sex }
                  onChange={ sexHandler }
                  onBlur={ sexBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona il sesso</option>
                  <option value="M">Uomo</option>
                  <option value="F">Donna</option>
                </Form.Control>
                { !sexIsValid && sexIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formRole">
                <Form.Label>Ruolo</Form.Label>
                <Form.Control
                  as="select"
                  value={ role }
                  onChange={ roleHandler }
                  onBlur={ roleBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona il ruolo</option>
                  <option value="admin">Amministratore</option>
                  <option value="operator">Operatore</option>
                </Form.Control>
                { !roleIsValid && roleIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  value={ email }
                  onChange={ emailHandler }
                  onBlur={ emailBlurHandler }
                  type="text"
                  placeholder="Inserisci l'email dell'operatore"
                  required />
                { !emailIsValid && emailIsTouched && <small className="text-danger">Email non valida</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }
          disabled={ loading }>Chiudi</Button>
        <Button
          variant="primary"
          onClick={ addOperatorHandler }
          disabled={ !formIsValid || loading }>{ loading ? 'Inserimento in corso...' : 'Inserisci operatore' }</Button>
      </Modal.Footer>
    </>
  );

}

export default OperatorModalAdd;
