import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import ViewTitle from "../../ViewTitle/ViewTitle";

// eslint-disable-next-line
const EMAIL_REGEX = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
// eslint-disable-next-line
const TAXCODE_REGEX = /^(?:[A-Z][AEIOU][AEIOUX]|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;


const StudentModalEdit = ({ student, loading, onClose, onEdit }) => {

  const validateName = (value) => value && value.trim() && value.trim().length >= 3;
  const validateSurname = (value) => value && value.trim() && value.trim().length >= 3;
  const validateTaxCode = (value) => TAXCODE_REGEX.test(value);
  const validateEmail = (value) => EMAIL_REGEX.test(value);
  const validateSelect = (value) => value && value !== 0;

  const [ name, setName ] = useState(student && student.nome ? student.nome : '');
  const [ surname, setSurname ] = useState(student && student.cognome ? student.cognome : '');
  const [ sex, setSex ] = useState(student && student.sesso ? student.sesso : 0);
  const [ taxCode, setTaxCode ] = useState(student && student.codice_fiscale ? student.codice_fiscale : '');
  const [ qualificationTitle, setQualificationTitle ] = useState(student && student.titolo_studio ? student.titolo_studio : 0);
  const [ qualificationYear, setQualificationYear ] = useState(student && student.anno_conseguimento ? student.anno_conseguimento : '');
  const [ documentType, setDocumentType ] = useState(student && student.tipo_documento ? student.tipo_documento : 0);
  const [ documentNumber, setDocumentNumber ] = useState(student && student.numero_documento ? student.numero_documento : '');
  const [ birthday, setBirthday ] = useState(student && student.data_nascita ? student.data_nascita.split('T')[0] : '');
  const [ citizenship, setCitizenship ] = useState(student && student.cittadinanza ? student.cittadinanza : '');
  const [ birthCountry, setBirthCountry ] = useState(student && student.provincia_nascita ? student.provincia_nascita : '');
  const [ birthNation, setBirthNation ] = useState(student && student.nazione_nascita ? student.nazione_nascita : '');
  const [ nationality, setNationality ] = useState(student && student.nazionalita ? student.nazionalita : 0);
  const [ residencePermitNumber, setResidencePermitNumber ] = useState(student && student.numero_permesso_soggiorno ? student.numero_permesso_soggiorno : '');
  const [ residencePermitExpiration, setResidencePermitExpiration ] = useState(student && student.scadenza_permesso_soggiorno ? student.scadenza_permesso_soggiorno.split('T')[0] : '');
  const [ residenceCity, setResidenceCity ] = useState(student && student.citta_residenza ? student.citta_residenza : '');
  const [ residenceCountry, setResidenceCountry ] = useState(student && student.provincia_residenza ? student.provincia_residenza : '');
  const [ residenceAddress, setResidenceAddress ] = useState(student && student.indirizzo_residenza ? student.indirizzo_residenza : '');
  const [ residencePostalCode, setResidencePostalCode ] = useState(student && student.cap_residenza ? student.cap_residenza : '');
  const [ cellular, setCellular ] = useState(student && student.cellulare ? student.cellulare : '');
  const [ phone, setPhone ] = useState(student && student.telefono ? student.telefono : '');
  const [ email, setEmail ] = useState(student && student.email ? student.email : '');
  const [ fadEmail, setFadEmail ] = useState(student && student.email_fad ? student.email_fad : '');
  const [ notes, setNotes ] = useState(student && student.note ? student.note : '');

  const [ nameIsTouched, setNameIsTouched ] = useState(false);
  const [ surnameIsTouched, setSurnameIsTouched ] = useState(false);
  const [ sexIsTouched, setSexIsTouched ] = useState(false);
  const [ taxCodeIsTouched, setTaxCodeIsTouched ] = useState(false);
  const [ qualificationTitleIsTouched, setQualificationTitleIsTouched ] = useState(false);
  const [ qualificationYearIsTouched, setQualificationYearIsTouched ] = useState(false);
  const [ documentTypeIsTouched, setDocumentTypeIsTouched ] = useState(false);
  const [ documentNumberIsTouched, setDocumentNumberIsTouched ] = useState(false);
  const [ birthdayIsTouched, setBirthdayIsTouched ] = useState(false);
  const [ citizenshipIsTouched, setCitizenshipIsTouched ] = useState(false);
  const [ birthCountryIsTouched, setBirthCountryIsTouched ] = useState(false);
  const [ birthNationIsTouched, setBirthNationIsTouched ] = useState(false);
  const [ nationalityIsTouched, setNationalityIsTouched ] = useState(false);
  const [ residencePermitNumberIsTouched, setResidencePermitNumberIsTouched ] = useState(false);
  const [ residencePermitExpirationIsTouched, setResidencePermitExpirationIsTouched ] = useState(false);
  const [ residenceCityIsTouched, setResidenceCityIsTouched ] = useState(false);
  const [ residenceCountryIsTouched, setResidenceCountryIsTouched ] = useState(false);
  const [ residenceAddressIsTouched, setResidenceAddressIsTouched ] = useState(false);
  const [ residencePostalCodeIsTouched, setResidencePostalCodeIsTouched ] = useState(false);
  const [ cellularIsTouched, setCellularIsTouched ] = useState(false);
  const [ phoneIsTouched, setPhoneIsTouched ] = useState(false);
  const [ emailIsTouched, setEmailIsTouched ] = useState(false);
  const [ fadEmailIsTouched, setFadEmailIsTouched ] = useState(false);
  const [ notesIsTouched, setNotesIsTouched ] = useState(false);

  const [ nameIsValid, setNameIsValid ] = useState(validateName(name));
  const [ surnameIsValid, setSurnameIsValid ] = useState(validateSurname(surname));
  const [ sexIsValid, setSexIsValid ] = useState(validateSelect(sex));
  const [ taxCodeIsValid, setTaxCodeIsValid ] = useState(validateTaxCode(taxCode));
  const [ emailIsValid, setEmailIsValid ] = useState(validateEmail(email));
  const [ fadEmailIsValid, setFadEmailIsValid ] = useState(validateEmail(fadEmail));

  const formIsValid = nameIsValid && surnameIsValid && sexIsValid && taxCodeIsValid;

  const nameHandler = (e) => setName(e.target.value);
  const surnameHandler = (e) => setSurname(e.target.value);
  const sexHandler = (e) => setSex(e.target.value);
  const taxCodeHandler = (e) => setTaxCode(e.target.value);
  const qualificationTitleHandler = (e) => setQualificationTitle(e.target.value);
  const qualificationYearHandler = (e) => setQualificationYear(e.target.value);
  const documentTypeHandler = (e) => setDocumentType(e.target.value);
  const documentNumberHandler = (e) => setDocumentNumber(e.target.value);
  const birthdayHandler = (e) => setBirthday(e.target.value);
  const citizenshipHandler = (e) => setCitizenship(e.target.value);
  const birthCountryHandler = (e) => setBirthCountry(e.target.value);
  const birthNationHandler = (e) => setBirthNation(e.target.value);
  const nationalityHandler = (e) => setNationality(e.target.value);
  const residencePermitNumberHandler = (e) => setResidencePermitNumber(e.target.value);
  const residencePermitExpirationHandler = (e) => setResidencePermitExpiration(e.target.value);
  const residenceCityHandler = (e) => setResidenceCity(e.target.value);
  const residenceCountryHandler = (e) => setResidenceCountry(e.target.value);
  const residenceAddressHandler = (e) => setResidenceAddress(e.target.value);
  const residencePostalCodeHandler = (e) => setResidencePostalCode(e.target.value);
  const cellularHandler = (e) => setCellular(e.target.value);
  const phoneHandler = (e) => setPhone(e.target.value);
  const emailHandler = (e) => setEmail(e.target.value);
  const fadEmailHandler = (e) => setFadEmail(e.target.value);
  const notesHandler = (e) => setNotes(e.target.value);

  const nameBlurHandler = () => {
    setNameIsTouched(true);
    setNameIsValid(validateName(name));
  };
  const surnameBlurHandler = () => {
    setSurnameIsTouched(true);
    setSurnameIsValid(validateSurname(surname));
  };
  const taxCodeBlurHandler = () => {
    setTaxCodeIsTouched(true);
    setTaxCodeIsValid(validateTaxCode(taxCode));
  };
  const emailBlurHandler = () => {
    setEmailIsTouched(true);
    setEmailIsValid(validateEmail(email));
  };
  const fadEmailBlurHandler = () => {
    setFadEmailIsTouched(true);
    setFadEmailIsValid(validateEmail(fadEmail));
  };
  const sexBlurHandler = () => {
    setSexIsTouched(true);
    setSexIsValid(validateSelect(sex));
  }
  const qualificationTitleBlurHandler = () => setQualificationTitleIsTouched(true);
  const qualificationYearBlurHandler = () => setQualificationYearIsTouched(true);
  const documentTypeBlurHandler = () => setDocumentTypeIsTouched(true);
  const documentNumberBlurHandler = () => setDocumentNumberIsTouched(true);
  const birthdayBlurHandler = () => setBirthdayIsTouched(true);
  const citizenshipBlurHandler = () => setCitizenshipIsTouched(true);
  const birthCountryBlurHandler = () => setBirthCountryIsTouched(true);
  const birthNationBlurHandler = () => setBirthNationIsTouched(true);
  const nationalityBlurHandler = () => setNationalityIsTouched(true);
  const residencePermitNumberBlurHandler = () => setResidencePermitNumberIsTouched(true);
  const residencePermitExpirationBlurHandler = () => setResidencePermitExpirationIsTouched(true);
  const residenceCityBlurHandler = () => setResidenceCityIsTouched(true);
  const residenceCountryBlurHandler = () => setResidenceCountryIsTouched(true);
  const residenceAddressBlurHandler = () => setResidenceAddressIsTouched(true);
  const residencePostalCodeBlurHandler = () => setResidencePostalCodeIsTouched(true);
  const cellularBlurHandler = () => setCellularIsTouched(true);
  const phoneBlurHandler = () => setPhoneIsTouched(true);
  const notesBlurHandler = () => setNotesIsTouched(true);

  const editStudentHandler = (e) => {
    e.preventDefault();
    if (formIsValid) {
      onEdit(Object.fromEntries(Object.entries({
        name,
        surname,
        sex,
        taxCode,
        qualificationTitle,
        qualificationYear,
        documentType,
        documentNumber,
        birthday,
        citizenship,
        birthCountry,
        birthNation,
        nationality,
        residencePermitNumber,
        residencePermitExpiration,
        residenceCity,
        residenceCountry,
        residenceAddress,
        residencePostalCode,
        cellular,
        phone,
        email,
        fadEmail,
        notes
      }).filter(([ _, v ]) => v != null && v !== 0 && v.trim().length > 0)));
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica studente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editStudentHandler }>

          <Row>
            <Col sm="12">
              <ViewTitle title="Generalità" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control
                  onChange={ nameHandler }
                  onBlur={ nameBlurHandler }
                  value={ name }
                  type="text"
                  placeholder="Inserisci il nome dello studente"
                  required/>
                { !nameIsValid && nameIsTouched && <small className="text-danger">Lunghezza minima 3 caratteri</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formSurname">
                <Form.Label>Cognome</Form.Label>
                <Form.Control
                  onChange={ surnameHandler }
                  onBlur={ surnameBlurHandler }
                  value={ surname }
                  type="text"
                  placeholder="Inserisci il cognome dello studente"
                  required/>
                { !surnameIsValid && surnameIsTouched && <small className="text-danger">Lunghezza minima 3 caratteri</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formSex">
                <Form.Label>Sesso</Form.Label>
                <Form.Control
                  as="select"
                  value={ sex }
                  onChange={ sexHandler }
                  onBlur={ sexBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona il sesso</option>
                  <option value="M">M</option>
                  <option value="F">F</option>
                </Form.Control>
                { !sexIsValid && sexIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Codice Fiscale</Form.Label>
                <Form.Control
                  onChange={ taxCodeHandler }
                  onBlur={ taxCodeBlurHandler }
                  value={ taxCode }
                  type="text"
                  placeholder="Inserisci il codice fiscale"
                  required/>
                { !taxCodeIsValid && taxCodeIsTouched && <small className="text-danger">Codice fiscale non valido</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Titolo di studio</Form.Label>
                <Form.Control
                  as="select"
                  onChange={ qualificationTitleHandler }
                  onBlur={ qualificationTitleBlurHandler }
                  value={ qualificationTitle }
                  placeholder="Inserisci il titolo di studio">
                  <option disabled value={ 0 }>Seleziona il titolo</option>
                  <option value="Licenza Media">Licenza Media</option>
                  <option value="Attestato Professionale">Attestato Professionale</option>
                  <option value="Diploma Superiore">Diploma Superiore</option>
                  <option value="Laurea">Laurea</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Anno conseguimento</Form.Label>
                <Form.Control
                  onChange={ qualificationYearHandler }
                  onBlur={ qualificationYearBlurHandler }
                  value={ qualificationYear }
                  type="text"
                  placeholder="Inserisci anno di conseguimento"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Tipo documento</Form.Label>
                <Form.Control
                  as="select"
                  onChange={ documentTypeHandler }
                  onBlur={ documentTypeBlurHandler }
                  value={ documentType }
                  placeholder="Inserisci il tipo di documento">
                  <option disabled value={ 0 }>Seleziona il documento</option>
                  <option value="Carta di Identità">Carta di Identità</option>
                  <option value="Patente">Patente</option>
                  <option value="Passaporto">Passaporto</option>
                  <option value="Permesso di soggiorno">Permesso di soggiorno</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Numero documento</Form.Label>
                <Form.Control
                  onChange={ documentNumberHandler }
                  onBlur={ documentNumberBlurHandler }
                  value={ documentNumber }
                  type="text"
                  placeholder="Inserisci il numero del documento"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCitizenship">
                <Form.Label>Cittadinanza</Form.Label>
                <Form.Control
                  type="text"
                  value={ citizenship }
                  onChange={ citizenshipHandler }
                  onBlur={ citizenshipBlurHandler }
                  placeholder="Inserisci la cittadinanza"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Comunitario o extracomunitario?</Form.Label>
                <Form.Control
                  as="select"
                  value={ nationality }
                  onChange={ nationalityHandler }
                  onBlur={ nationalityBlurHandler }
                  placeholder="Inserisci la nazionalità">
                  <option disabled value={ 0 }>Seleziona la nazionalità</option>
                  <option value="Comunitaria">Comunitaria</option>
                  <option value="Extracomunitaria">Extracomunitaria</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formResidencePermitNumber">
                <Form.Label>Permesso di soggiorno N° <small>(solo extracomunitari)</small></Form.Label>
                <Form.Control
                  type="text"
                  value={ residencePermitNumber }
                  onChange={ residencePermitNumberHandler }
                  onBlur={ residencePermitNumberBlurHandler }
                  placeholder="Inserisci il numero del permesso di soggiorno"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formResidencePermitExpiration">
                <Form.Label>Scadenza permesso <small>(solo extracomunitari)</small></Form.Label>
                <Form.Control
                  type="date"
                  value={ residencePermitExpiration }
                  onChange={ residencePermitExpirationHandler }
                  onBlur={ residencePermitExpirationBlurHandler }
                  placeholder="Inserisci la scadenza del permesso di soggiorno"/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Natalità" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formBirthday">
                <Form.Label>Data di nascita</Form.Label>
                <Form.Control
                  onChange={ birthdayHandler }
                  onBlur={ birthdayBlurHandler }
                  value={ birthday }
                  type="date"
                  placeholder="Inserisci la data di nascita"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Città di nascita</Form.Label>
                <Form.Control
                  value={ birthCountry }
                  onChange={ birthCountryHandler }
                  onBlur={ birthCountryBlurHandler }
                  type="text"
                  placeholder="Inserisci la città di nascita"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Nazione di nascita</Form.Label>
                <Form.Control
                  value={ birthNation }
                  onChange={ birthNationHandler }
                  onBlur={ birthNationBlurHandler }
                  type="text"
                  placeholder="Inserisci la nazione di nascita"/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Residenza" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Città</Form.Label>
                <Form.Control
                  value={ residenceCity }
                  onChange={ residenceCityHandler }
                  onBlur={ residenceCityBlurHandler }
                  type="text"
                  placeholder="Inserisci la città di residenza"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="2">
              <Form.Group controlId="formEmail">
                <Form.Label>Provincia</Form.Label>
                <Form.Control
                  value={ residenceCountry }
                  onChange={ residenceCountryHandler }
                  onBlur={ residenceCountryBlurHandler }
                  type="text"
                  placeholder="Inserisci la provincia di residenza"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="4">
              <Form.Group controlId="formEmail">
                <Form.Label>Indirizzo</Form.Label>
                <Form.Control
                  value={ residenceAddress }
                  onChange={ residenceAddressHandler }
                  onBlur={ residenceAddressBlurHandler }
                  type="text"
                  placeholder="Inserisci l'indirizzo di residenza"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>CAP</Form.Label>
                <Form.Control
                  value={ residencePostalCode }
                  onChange={ residencePostalCodeHandler }
                  onBlur={ residencePostalCodeBlurHandler }
                  type="text"
                  placeholder="Inserisci il CAP di residenza"/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Contatti" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCellular">
                <Form.Label>Cellulare</Form.Label>
                <Form.Control
                  onChange={ cellularHandler }
                  onBlur={ cellularBlurHandler }
                  value={ cellular }
                  type="text"
                  placeholder="Inserisci il cellulare"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCellular">
                <Form.Label>Telefono</Form.Label>
                <Form.Control
                  onChange={ phoneHandler }
                  onBlur={ phoneBlurHandler }
                  value={ phone }
                  type="text"
                  placeholder="Inserisci il cellulare"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Email personale</Form.Label>
                <Form.Control
                  value={ email }
                  onChange={ emailHandler }
                  onBlur={ emailBlurHandler }
                  type="text"
                  placeholder="Inserisci l'email personale"/>
                { !emailIsValid && emailIsTouched && <small className="text-danger">Email non valida</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmailFad">
                <Form.Label>Email FAD</Form.Label>
                <Form.Control
                  value={ fadEmail }
                  onChange={ fadEmailHandler }
                  onBlur={ fadEmailBlurHandler }
                  type="text"
                  placeholder="Inserisci l'email FAD"/>
                { !emailIsValid && emailIsTouched && <small className="text-danger">Email non valida</small> }
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Note" size="h4"/>
            </Col>
            <Col sm="12" md="12" lg="12">
              <Form.Group controlId="formCellular">
                <Form.Control
                  as="textarea"
                  style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                  onChange={ notesHandler }
                  onBlur={ notesBlurHandler }
                  value={ notes }
                  type="text"
                  placeholder="Inserisci qui eventuali note da allegare alla pratica"/>
              </Form.Group>
            </Col>
          </Row>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }
          disabled={ loading }>Chiudi</Button>
        <Button
          variant="primary"
          onClick={ editStudentHandler }
          disabled={ !formIsValid || loading }>{ loading ? 'Aggiornamento in corso...' : 'Aggiorna studente' }</Button>
      </Modal.Footer>
    </>
  );

}

export default StudentModalEdit;
