import { useSelector } from "react-redux";
import {
  globalForecastCashFlowSelector,
  globalRealCashFlowSelector,
  courseSubscriptionEnrollmentsSelector,
  courseSubscriptionTypesSelector,
  forecastCashFlowSelector,
  howKnowAboutSchoolRanksSelector,
  realCashFlowSelector
} from "../store/kpi/selectors";

const useKpi = () => {

  const globalForecastCashFlow = useSelector(globalForecastCashFlowSelector);
  const globalRealCashFlow = useSelector(globalRealCashFlowSelector);
  const forecastCashFlow = useSelector(forecastCashFlowSelector);
  const realCashFlow = useSelector(realCashFlowSelector);
  const courseSubscriptionEnrollments = useSelector(courseSubscriptionEnrollmentsSelector);
  const courseSubscriptionTypes = useSelector(courseSubscriptionTypesSelector);
  const howKnowAboutSchoolRanks = useSelector(howKnowAboutSchoolRanksSelector);

  return {
    globalForecastCashFlow,
    globalRealCashFlow,
    forecastCashFlow,
    realCashFlow,
    courseSubscriptionEnrollments,
    courseSubscriptionTypes,
    howKnowAboutSchoolRanks
  };

};

export default useKpi;
