const { createSelector } = require("@reduxjs/toolkit");

const CoursesTypesStore = (state) => state.types;

export const coursesTypeLoadingSelector = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.loading
);

export const coursesTypeErrorSelector = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.error
);

export const coursesTypeListSelector = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.types
);

export const selectedTypeSelector = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.selectedType
);

export const courseTypeModalSelector = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.showModal
);

export const courseTypeModalType = createSelector(
  CoursesTypesStore,
  (coursesTypesState) => coursesTypesState.modalType
);
