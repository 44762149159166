import styled from "styled-components";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useState } from "react";
import useCourseTitles from "../../../hooks/useCourseTitles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import useCourseTurns from "../../../hooks/useCourseTurns";
import useLocations from "../../../hooks/useLocations";

const SearchCourseForm = ({ loading, onSubmit }) => {

  const { courseTitlesList } = useCourseTitles();
  const { turnsList } = useCourseTurns();
  const { locationsList } = useLocations();

  const [ state, setState ] = useState({
    title: 0,
    startingDate: '',
    duration: 0,
    turn: 0,
    location: 0
  });

  const titleHandler = (e) => setState((state) => ({ ...state, title: +e.target.value }));
  const startingDateHandler = (e) => setState((state) => ({ ...state, startingDate: e.target.value }));
  const durationHandler = (e) => setState((state) => ({ ...state, duration: +e.target.value }));
  const turnHandler = (e) => setState((state) => ({ ...state, turn: +e.target.value }));
  const locationHandler = (e) => setState((state) => ({ ...state, location: +e.target.value }));
  // const
  const submitHandler = (e) => {
    e.preventDefault();
    onSubmit({ ...state });
  };

  return (
    <Container>
      <Row className="d-flex align-items-end">
        <Col sm="12" md="6" lg="3">
          <Form.Group className="mb-0">
            <Form.Label>Titolo</Form.Label>
            <Form.Control
              as="select"
              onChange={ titleHandler }
              onBlur={ titleHandler }
              value={ state.title }>
              <option value={ 0 }>Nessun titolo</option>
              {
                courseTitlesList && courseTitlesList
                  .filter((t) => t.deleted === 0)
                  .sort((a, b) => a.titolo > b.titolo ? 1 : -1)
                  .map((t) => <option key={ t.id } value={ t.id }>{ t.titolo }</option>)
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" lg="2">
          <Form.Group className="mb-0">
            <Form.Label>Data di inizio</Form.Label>
            <Form.Control
              onChange={ startingDateHandler }
              value={ state.startingDate }
              type="date"/>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" lg="1">
          <Form.Group className="mb-0">
            <Form.Label>Ore</Form.Label>
            <Form.Control
              onChange={ durationHandler }
              value={ state.duration }
              type="number"
              placeholder="Inserisci le ore del corso"/>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" lg="2">
          <Form.Group className="mb-0">
            <Form.Label>Turno</Form.Label>
            <Form.Control
              as="select"
              value={ state.turn }
              onChange={ turnHandler }>
              <option value={ 0 }>Nessun turno</option>
              {
                turnsList && turnsList
                  .filter((t) => t.deleted === 0)
                  .map((t) => <option key={ t.id } value={ t.id }>{ t.turno }</option>)
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" lg="2">
          <Form.Group className="mb-0">
            <Form.Label>Sede</Form.Label>
            <Form.Control
              as="select"
              value={ state.location }
              onChange={ locationHandler }>
              <option value={ 0 }>Nessuna sede</option>
              {
                locationsList && locationsList
                  .filter((c) => c.deleted === 0)
                  .map((c) => <option key={ c.id } value={ c.id }>{ c.sede }</option>)
              }
            </Form.Control>
          </Form.Group>
        </Col>
        <Col sm="12" md="6" lg="2" className="d-flex justify-content-center">
          <Button
            disabled={ loading }
            variant="primary"
            onClick={ submitHandler }>

            {
              !loading && (
                <>
                  <FontAwesomeIcon icon={ faSearch } size="sm"/> Avvia ricerca
                </>
              )
            }
            {
              loading && (
                <>
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> Ricerca in corso...
                </>
              )
            }
          </Button>
        </Col>
      </Row>
    </Container>
  );

};

export default SearchCourseForm;

const Container = styled.div`
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, .125);
`;
