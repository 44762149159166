import { Button, Col, Form, FormGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useState } from "react";
import ViewTitle from "../../ViewTitle/ViewTitle";
import styled from "styled-components";
// import LiveSearch from "../../LiveSearch/LiveSearch";
// import useStudents from "../../../hooks/useStudents";
// import useCourses from "../../../hooks/useCourses";
// import useOperators from "../../../hooks/useOperators";
// import { retrieveStudentsList } from "../../../store/students/thunk";
// import { retrieveCoursesList } from "../../../store/courses/thunk";
// import { retrieveOperatorsList } from "../../../store/operators/thunk";
// import { useDispatch } from "react-redux";

const SubscriptionModalInstallmentsEdit = ({ sub, onClose, onInstallmentsEdit }) => {

  // const dispatch = useDispatch();

  // const { studentsList } = useStudents();
  // const { coursesList } = useCourses();
  // const { operatorsList } = useOperators();

  // const [ activeTab, setActiveTab ] = useState('installments');

  const [ rate, setRate ] = useState(sub && sub.rate ? sub.rate : []);

  // const [ subscription, setSubscription ] = useState({
    // dataIscrizione: sub && sub.data_iscrizione ? sub.data_iscrizione.split('T')[0] : '',
    // operatore: operatorsList.find((op) => op.id === sub.id_operatore),
    // studente: studentsList.find((op) => op.id === sub.id_studente),
    // corso: coursesList.find((op) => op.id === sub.id_corso),
    // tipologiaIscrizione: sub && sub.tipologia_iscrizione ? sub.tipologia_iscrizione : '',
    // costo: sub && sub.costo ? sub.costo : '',
    // acconto: sub && sub.acconto ? sub.acconto : '',
    // scadenzaAcconto: sub && sub.data_scadenza_acconto ? sub.data_scadenza_acconto.split('T')[0] : '',
    // kit: sub && sub.kit ? sub.kit : '',
    // tipologiaPagamento: sub && sub.tipologia_pagamento ? sub.tipologia_pagamento : '',
    // numeroRate: sub && sub.numero_rate ? sub.numero_rate : '',
    // rate: sub && sub.rate ? sub.rate : '',
    // note: sub && sub.note ? sub.note : '',
    // noteContratto: sub && sub.note_contratto ? sub.note_contratto : ''
  // });
  // const [ survey, setSurvey ] = useState({
  //   chooseReason: sub && sub.questionario.motivo_scelta ? sub.questionario.motivo_scelta : '',
  //   howKnowAboutUs: sub && sub.questionario.conosciuto_scuola ? sub.questionario.conosciuto_scuola : '',
  //   sentToUs: sub && sub.questionario.referente ? sub.questionario.referente : '',
  //   whereDidYouSeeUs: sub && sub.questionario.dove_visto ? sub.questionario.dove_visto : '',
  //   howWillYouComeToClasses: sub && sub.questionario.viaggio ? sub.questionario.viaggio : '',
  //   occupation: sub && sub.questionario.professione ? sub.questionario.professione : '',
  //   attendedToOtherCourse: sub && sub.questionario.partecipato_altri_corsi ? sub.questionario.partecipato_altri_corsi : 0,
  //   attendedCourses: sub && sub.questionario.altri_corsi ? sub.questionario.altri_corsi : '',
  //   attendedToOtherPrivateCourse: sub && sub.questionario.partecipato_altri_corsi_privati ? sub.questionario.partecipato_altri_corsi_privati : 0,
  //   attendedPrivateCourses: sub && sub.questionario.altri_corsi_privati ? sub.questionario.altri_corsi_privati : '',
  //   noteQuestionario: sub && sub.questionario.note ? sub.questionario.note : ''
  // });

  const installmentsHandler = (e) => {
    const type = e.target.id.split('-')[0];
    const index = +e.target.id.split('-')[1];
    console.log(type, index);
    setRate((state) => {
      const newState = [ ...state ].map((el) => {
        console.log('el', el);
        if (el.numero_rata === (index + 1)) {
          const obj = { ...el };
          obj[type] = type === 'importo_rata' ? +e.target.value : e.target.value;
          return obj;
        }
        return el;
      });
      return [ ...newState ];
    });
  };

  const editSubscriptionHandler = (e) => {
    e.preventDefault();
    onInstallmentsEdit([ ...rate ]);
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Aggiorna rate
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          {/*<Form>*/}
          {
            rate && rate.length > 0 && (
              rate.map((el, index) => (
                <Col key={ index } sm="12">
                  <InstallmentContainer>
                    <Col sm="12">
                      <ViewTitle title={ `${ el.numero_rata }° Rata` } size="h4"/>
                    </Col>
                    <Row>
                      <Col key={ `importo-${ el.numero_rata }` } sm="12" lg="2">
                        <FormGroup>
                          <Form.Label>Importo</Form.Label>
                          <Form.Control
                            id={ `importo_rata-${ index }` }
                            value={ el.importo_rata }
                            onChange={ installmentsHandler }
                            type="number"/>
                        </FormGroup>
                      </Col>
                      <Col key={ `scadenza-${ el.numero_rata }` } sm="12" lg="3">
                        <FormGroup>
                          <Form.Label>Scadenza</Form.Label>
                          <Form.Control
                            disabled
                            id={ `scadenza_rata-${ index }` }
                            value={ el.scadenza_rata.split('T')[0] }
                            onChange={ installmentsHandler }
                            type="date"/>
                        </FormGroup>
                      </Col>
                      <Col key={ `stato-${ el.numero_rata }` } sm="12" lg="2">
                        <FormGroup>
                          <Form.Label>Stato pagamento</Form.Label>
                          <Form.Control
                            as="select"
                            id={ `stato_pagamento-${ index }` }
                            value={ el.stato_pagamento }
                            onChange={ installmentsHandler }>
                            <option value="da saldare">Da saldare</option>
                            <option value="saldata">Saldata</option>
                            <option value="scaduta">Scaduta</option>
                          </Form.Control>
                        </FormGroup>
                      </Col>
                      <Col key={ `data-pagamento-${ el.numero_rata }` } sm="12" lg="3">
                        <FormGroup>
                          <Form.Label>Data Fattura</Form.Label>
                          <Form.Control
                            disabled={ el.stato_pagamento !== 'saldata' }
                            id={ `data_pagamento-${ index }` }
                            value={ el.data_pagamento ? el.data_pagamento.split('T')[0] : '' }
                            onChange={ installmentsHandler }
                            type="date"/>
                        </FormGroup>
                      </Col>
                      <Col key={ `numero-fattura-${ el.numero_rata }` } sm="12" lg="2">
                        <FormGroup>
                          <Form.Label>Numero Fattura</Form.Label>
                          <Form.Control
                            disabled={ el.stato_pagamento !== 'saldata' }
                            id={ `numero_fattura-${ index }` }
                            value={ el.numero_fattura ? el.numero_fattura : '' }
                            onChange={ installmentsHandler }
                            type="text"/>
                        </FormGroup>
                      </Col>
                    </Row>
                  </InstallmentContainer>
                </Col>
              ))
            )
          }
          {/*</Form>*/}
        </Row>
        {
          !rate.length && (
            <VAlignContainer>
              <p>Non sono previste rate per questo contratto</p>
            </VAlignContainer>
          )
        }
        {/*<Tabs activeKey={ activeTab } onSelect={ (tab) => setActiveTab(tab) } id="uncontrolled-tab-example" className="mb-3">*/}

          {/*<Tab eventKey="subscription" title="Iscrizione">*/}
          {/*  <Form>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Dettagli pratica" size="h4"/>*/}
          {/*      </Col>*/}
          {/*      <Col>*/}
          {/*        <FormGroup>*/}
          {/*          <Form.Label>Data iscrizione</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            disabled*/}
          {/*            id="dataIscrizione"*/}
          {/*            value={ subscription.dataIscrizione }*/}
          {/*            type="date"/>*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col>*/}
          {/*        <LiveSearch*/}
          {/*          disabled*/}
          {/*          label="Ricerca operatore"*/}
          {/*          type="operators"*/}
          {/*          list={ operatorsList }*/}
          {/*          existingValue={ subscription.operatore }/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Studente e Corso" size="h4"/>*/}
          {/*      </Col>*/}
          {/*      <Col sm="12" lg="4">*/}
          {/*        <LiveSearch*/}
          {/*          disabled*/}
          {/*          label="Ricerca studente"*/}
          {/*          type="students"*/}
          {/*          list={ studentsList }*/}
          {/*          existingValue={ subscription.studente }/>*/}
          {/*      </Col>*/}
          {/*      <Col sm="12" lg="8">*/}
          {/*        <LiveSearch*/}
          {/*          disabled*/}
          {/*          label="Ricerca corso"*/}
          {/*          type="courses"*/}
          {/*          list={ coursesList }*/}
          {/*          existingValue={ subscription.corso }/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Metodo di pagamento" size="h4"/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row className="mb-3">*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          disabled*/}
          {/*          checked={ subscription.tipologiaPagamento === 'rate' }*/}
          {/*          inline*/}
          {/*          label="Rate"*/}
          {/*          type="radio"*/}
          {/*          name="paymentMethod"*/}
          {/*          id="rate"/>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          disabled*/}
          {/*          checked={ subscription.tipologiaPagamento === 'finanziaria' }*/}
          {/*          inline*/}
          {/*          label="Finanziaria"*/}
          {/*          type="radio"*/}
          {/*          name="paymentMethod"*/}
          {/*          id="finanziaria"/>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          disabled*/}
          {/*          checked={ subscription.tipologiaPagamento === 'cessione' }*/}
          {/*          inline*/}
          {/*          label="Cessione del credito"*/}
          {/*          type="radio"*/}
          {/*          name="paymentMethod"*/}
          {/*          id="cessione"/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Costi" size="h4"/>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <FormGroup>*/}
          {/*          <Form.Label>Costo del corso</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*          disabled*/}
          {/*            value={ subscription.costo }*/}
          {/*            type="number"/>*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <FormGroup>*/}
          {/*          <Form.Label>Acconto</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*          disabled*/}
          {/*            value={ subscription.acconto }*/}
          {/*            type="number"/>*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col>*/}
          {/*        <FormGroup>*/}
          {/*          <Form.Label>Data scadenza acconto</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*          disabled*/}
          {/*            value={ subscription.scadenzaAcconto }*/}
          {/*            type="date"/>*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <FormGroup>*/}
          {/*          <Form.Label>Kit</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            disabled*/}
          {/*            value={ subscription.kit }*/}
          {/*            type="number"/>*/}
          {/*        </FormGroup>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Tipologia iscrizione" size="h4"/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row className="mb-3">*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          checked={ subscription.tipologiaIscrizione === 'sede' }*/}
          {/*            onChange={ () => null }*/}
          {/*          inline*/}
          {/*          label="In sede"*/}
          {/*          type="radio"*/}
          {/*          name="subscriptionType"*/}
          {/*          id="sede"/>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          checked={ subscription.tipologiaIscrizione === 'online' }*/}
          {/*            onChange={ () => null }*/}
          {/*          inline*/}
          {/*          label="Online"*/}
          {/*          type="radio"*/}
          {/*          name="subscriptionType"*/}
          {/*          id="online"/>*/}
          {/*      </Col>*/}
          {/*      <Col lg="3">*/}
          {/*        <Form.Check*/}
          {/*          checked={ subscription.tipologiaIscrizione === 'distanza' }*/}
          {/*            onChange={ () => null }*/}
          {/*          inline*/}
          {/*          label="A distanza"*/}
          {/*          type="radio"*/}
          {/*          name="subscriptionType"*/}
          {/*          id="distanza"/>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Note contratto" size="h4"/>*/}
          {/*      </Col>*/}
          {/*      <Col sm="12" md="12" lg="12">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Control*/}
          {/*            as="textarea"*/}
          {/*            style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }*/}
          {/*            value={ subscription.noteContratto }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*    <Row>*/}
          {/*      <Col sm="12">*/}
          {/*        <ViewTitle title="Note interne" size="h4"/>*/}
          {/*      </Col>*/}
          {/*      <Col sm="12" md="12" lg="12">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Control*/}
          {/*            as="textarea"*/}
          {/*            style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }*/}
          {/*            value={ subscription.note }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </Form>*/}
          {/*</Tab>*/}

          {/*<Tab eventKey="survey" title="Questionario marketing">*/}
          {/*  <Form>*/}
          {/*    <Row>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Qual'è il motivo per il quale hai scelto questa scuola?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            id="chooseReason"*/}
          {/*            as="select"*/}
          {/*            value={ survey.chooseReason }*/}
          {/*            onChange={ () => null }>*/}
          {/*            <option value={ 0 }>Seleziona la motivazione</option>*/}
          {/*            <option value="Chiarezza nelle informazioni">Chiarezza nelle informazioni</option>*/}
          {/*            <option value="Vicinanza">Vicinanza</option>*/}
          {/*            <option value="Collegamento mezzi pubblici">Collegamento mezzi pubblici</option>*/}
          {/*            <option value="Prezzo">Prezzo</option>*/}
          {/*            <option value="Accoglienza">Accoglienza</option>*/}
          {/*            <option value="Professionalità">Professionalità</option>*/}
          {/*            <option value="Buona impressione">Buona impressione</option>*/}
          {/*          </Form.Control>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Come hai conosciuto questa scuola?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            id="howKnowAboutUs"*/}
          {/*            as="select"*/}
          {/*            value={ survey.howKnowAboutUs }*/}
          {/*            onChange={ () => null }>*/}
          {/*            <option value={ 0 }>Seleziona dalla lista</option>*/}
          {/*            <option value="Google">Google</option>*/}
          {/*            <option value="Facebook">Facebook</option>*/}
          {/*            <option value="TikTok">TikTok</option>*/}
          {/*            <option value="Radio">Radio</option>*/}
          {/*            <option value="Passa-parola amici">Passa-parola amici</option>*/}
          {/*            <option value="Emagister">Emagister</option>*/}
          {/*            <option value="Email Marketing">Email Marketing</option>*/}
          {/*            <option value="Riviste di settore">Riviste di settore</option>*/}
          {/*            <option value="Fiera estetica">Fiera estetica</option>*/}
          {/*            <option value="Aziende partner">Aziende partner</option>*/}
          {/*            <option value="Consulenti">Consulenti</option>*/}
          {/*            <option value="Sono un ex-allievo">Sono un ex-allievo</option>*/}
          {/*            <option value="Altro">Altro</option>*/}
          {/*          </Form.Control>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Chi ti ha mandato da noi?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            value={ survey.sentToUs }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Dove ci hai visto?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            value={ survey.whereDidYouSeeUs }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Con che mezzo verrai alle lezioni?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            id="howWillYouComeToClasses"*/}
          {/*            as="select"*/}
          {/*            value={ survey.howWillYouComeToClasses }*/}
          {/*            onChange={ () => null }>*/}
          {/*            <option value={ 0 }>Seleziona il mezzo di trasporto</option>*/}
          {/*            <option value="Macchina/Motorino">Macchina/Motorino</option>*/}
          {/*            <option value="Metro A">Metro A</option>*/}
          {/*            <option value="Metro + treno">Metro + treno</option>*/}
          {/*            <option value="Metro A + Metro B">Metro A + Metro B</option>*/}
          {/*            <option value="Autobus">Autobus</option>*/}
          {/*            <option value="Bicicletta">Bicicletta</option>*/}
          {/*            <option value="Monopattino">Monopattino</option>*/}
          {/*            <option value="A piedi">A piedi</option>*/}
          {/*          </Form.Control>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Qual'è la tua attuale occupazione?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            id="occupation"*/}
          {/*            as="select"*/}
          {/*            value={ survey.occupation }*/}
          {/*            onChange={ () => null }>*/}
          {/*            <option value={ 0 }>Seleziona il mezzo di trasporto</option>*/}
          {/*            <option value="Casalinga">Casalinga</option>*/}
          {/*            <option value="Studente">Studente</option>*/}
          {/*            <option value="Inoccupato">Inoccupato</option>*/}
          {/*            <option value="Disoccupato">Disoccupato</option>*/}
          {/*            <option value="Occupato">Occupato</option>*/}
          {/*            <option value="Libero professionista/Imprenditore">Libero professionista/Imprenditore</option>*/}
          {/*          </Form.Control>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Row>*/}
          {/*          <Col>*/}
          {/*            <Form.Label>In passato hai partecipato ad altri corsi regionali e/o provinciali?</Form.Label>*/}
          {/*          </Col>*/}
          {/*        </Row>*/}
          {/*        <Row>*/}
          {/*          <VAlignContainer>*/}
          {/*            <Form.Check*/}
          {/*              checked={ survey.attendedToOtherCourse === 1 }*/}
          {/*              onChange={ () => null }*/}
          {/*              inline*/}
          {/*              label="Si"*/}
          {/*              type="radio"*/}
          {/*              name="attendedToOtherCourse"*/}
          {/*              id="si"/>*/}
          {/*            <Form.Check*/}
          {/*              checked={ survey.attendedToOtherCourse === 0 }*/}
          {/*              onChange={ () => null }*/}
          {/*              inline*/}
          {/*              label="No"*/}
          {/*              type="radio"*/}
          {/*              name="attendedToOtherCourse"*/}
          {/*              id="no"/>*/}
          {/*          </VAlignContainer>*/}
          {/*        </Row>*/}
          {/*      </Col>*/}
          {/*      <Col>*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Quali?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            value={ survey.attendedCourses }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col lg="6">*/}
          {/*        <Row>*/}
          {/*          <Col>*/}
          {/*            <Form.Label>In passato hai partecipato ad altri corsi privati?</Form.Label>*/}
          {/*          </Col>*/}
          {/*        </Row>*/}
          {/*        <Row>*/}
          {/*          <VAlignContainer>*/}
          {/*            <Form.Check*/}
          {/*              inline*/}
          {/*              checked={ survey.attendedToOtherPrivateCourse === 1 }*/}
          {/*            onChange={ () => null }*/}
          {/*              label="Si"*/}
          {/*              type="radio"*/}
          {/*              name="attendedToOtherPrivateCourse"*/}
          {/*              id="si"/>*/}
          {/*            <Form.Check*/}
          {/*              inline*/}
          {/*              checked={ survey.attendedToOtherPrivateCourse === 0 }*/}
          {/*            onChange={ () => null }*/}
          {/*              label="No"*/}
          {/*              type="radio"*/}
          {/*              name="attendedToOtherPrivateCourse"*/}
          {/*              id="no"/>*/}
          {/*          </VAlignContainer>*/}
          {/*        </Row>*/}
          {/*      </Col>*/}
          {/*      <Col>*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Label>Quali?</Form.Label>*/}
          {/*          <Form.Control*/}
          {/*            value={ survey.attendedPrivateCourses }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*      <Col sm="12" md="12" lg="12">*/}
          {/*        <Form.Group>*/}
          {/*          <Form.Control*/}
          {/*            as="textarea"*/}
          {/*            style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }*/}
          {/*            value={ survey.noteQuestionario }*/}
          {/*            onChange={ () => null }*/}
          {/*            type="text"/>*/}
          {/*        </Form.Group>*/}
          {/*      </Col>*/}
          {/*    </Row>*/}
          {/*  </Form>*/}
          {/*</Tab>*/}

          {/*<Tab eventKey="installments" title={ `Rate (${ subscription.rate.length })` }>*/}
          {/*  <Row>*/}
          {/*    {*/}
          {/*      rate && rate.length && (*/}
          {/*        rate.map((el, index) => (*/}
          {/*          <Col key={ index } sm="12">*/}
          {/*            <InstallmentContainer>*/}
          {/*              <Col sm="12">*/}
          {/*                <ViewTitle title={ `${ el.numero_rata }° Rata` } size="h4"/>*/}
          {/*              </Col>*/}
          {/*              <Row>*/}
          {/*                <Col key={ `importo-${ el.numero_rata }` } sm="12" lg="3">*/}
          {/*                  <FormGroup>*/}
          {/*                    <Form.Label>Importo</Form.Label>*/}
          {/*                    <Form.Control*/}
          {/*                      id={ `r${ index }` }*/}
          {/*                      value={ el.importo_rata }*/}
          {/*                      onChange={ () => null }*/}
          {/*                      type="number"/>*/}
          {/*                  </FormGroup>*/}
          {/*                </Col>*/}
          {/*                <Col key={ `scadenza-${ el.numero_rata }` } sm="12" lg="3">*/}
          {/*                  <FormGroup>*/}
          {/*                    <Form.Label>Scadenza</Form.Label>*/}
          {/*                    <Form.Control*/}
          {/*                      id={ `d${ index }` }*/}
          {/*                      value={ el.scadenza_rata.split('T')[0] }*/}
          {/*                      onChange={ () => null }*/}
          {/*                      type="date"/>*/}
          {/*                  </FormGroup>*/}
          {/*                </Col>*/}
          {/*                <Col key={ `stato-${ el.numero_rata }` } sm="12" lg="3">*/}
          {/*                  <FormGroup>*/}
          {/*                    <Form.Label>Stato pagamento</Form.Label>*/}
          {/*                    <Form.Control*/}
          {/*                      id={ `ds${ index }` }*/}
          {/*                      value={ el.stato_pagamento }*/}
          {/*                      onChange={ () => null }*/}
          {/*                      type="text"/>*/}
          {/*                  </FormGroup>*/}
          {/*                </Col>*/}
          {/*                <Col key={ `data-pagamento-${ el.numero_rata }` } sm="12" lg="3">*/}
          {/*                  <FormGroup>*/}
          {/*                    <Form.Label>Data Pagamento</Form.Label>*/}
          {/*                    <Form.Control*/}
          {/*                      id={ `dd${ index }` }*/}
          {/*                      value={ el.data_pagamento ? el.data_pagamento.split('T')[0] : '' }*/}
          {/*                      onChange={ () => null }*/}
          {/*                      type="date"/>*/}
          {/*                  </FormGroup>*/}
          {/*                </Col>*/}
          {/*              </Row>*/}
          {/*            </InstallmentContainer>*/}
          {/*          </Col>*/}
          {/*        ))*/}
          {/*      )*/}
          {/*    }*/}
          {/*  </Row>*/}
          {/*</Tab>*/}

        {/*</Tabs>*/}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        { rate && rate.length > 0 && <Button variant="primary" onClick={ editSubscriptionHandler }>Salva</Button> }
      </Modal.Footer>
    </>
  );

}

export default SubscriptionModalInstallmentsEdit;

const VAlignContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 30px;
  
  p {
    margin: 0;
  }
`;

const InstallmentContainer = styled.div`
  padding: 20px;
  background-color: #fafafa;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
`;

