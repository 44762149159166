import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import LocationModalDelete from "./LocationModalDelete";
import LocationModalRestore from "./LocationModalRestore";
import LocationModalAdd from "./LocationModalAdd";
import useLocations from "../../../hooks/useLocations";
import { closeLocationModal } from "../../../store/locations/actions";
import { addLocation, deleteLocation, editLocation, restoreLocation } from "../../../store/locations/thunk";
import LocationModalEdit from "./LocationModalEdit";

const LocationModal = () => {

  const dispatch = useDispatch();
  const { selectedLocation, showLocationModal, modalType } = useLocations();

  const closeModalHandler = () => dispatch(closeLocationModal());

  const addLocationHandler = ({ location }) => dispatch(addLocation({ location }));
  const editLocationHandler = (location) => dispatch(editLocation({ id: selectedLocation.id, location }));
  const deleteLocationHandler = () => dispatch(deleteLocation(selectedLocation.id));
  const restoreLocationHandler = () => dispatch(restoreLocation(selectedLocation.id));

  return (
    <Modal
      size="lg"
      centered
      show={ showLocationModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.Locations.ADD &&
        <LocationModalAdd
          onClose={ closeModalHandler }
          onAdd={ addLocationHandler }/>
      }
      {
        modalType && modalType === Modals.Locations.EDIT &&
        <LocationModalEdit
          location={ selectedLocation }
          onClose={ closeModalHandler }
          onEdit={ editLocationHandler }/>
      }
      {
        modalType && modalType === Modals.Locations.DELETE &&
        <LocationModalDelete
          location={ selectedLocation }
          onClose={ closeModalHandler }
          onDelete={ deleteLocationHandler }/>
      }
      {
        modalType && modalType === Modals.Locations.RESTORE &&
        <LocationModalRestore
          location={ selectedLocation }
          onClose={ closeModalHandler }
          onRestore={ restoreLocationHandler }/>
      }
    </Modal>
  );

}

export default LocationModal;
