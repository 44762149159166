import WidgetContainer from "../WidgetContainer/WidgetContainer";
import styled from "styled-components";
import { useState } from "react";

const CourseSubscriptionTypes = ({ kpi, onChange }) => {

  const [ range, setRange ] = useState('month');

  const selectRangeHandler = (range) => () => {
    setRange(range);
    onChange(range);
  };

  return (
    <WidgetContainer sm={ 2 } md={ 3 } lg={ 3 }>
      <TitleContainer>
        <h4>Modalità di iscrizione</h4>
        <Switcher>
          <span
            onClick={ selectRangeHandler('month') }
            className={ `${ range === 'month' ? 'active' : '' }` }>
            Mensile
          </span>
          <span>/</span>
          <span
            onClick={ selectRangeHandler('year') }
            className={ `${ range === 'year' ? 'active' : '' }` }>
            Annuale
          </span>
        </Switcher>
      </TitleContainer>
      <ListContainer>
        {
          kpi && kpi.length > 0 && kpi.map((el) => (
            <ListItem key={ el.titolo }>
              <ItemTitle>{ el.titolo }</ItemTitle>
              <ItemQuantity>{ el.quantita }</ItemQuantity>
            </ListItem>
          ))
        }
      </ListContainer>
    </WidgetContainer>
  );

};

export default CourseSubscriptionTypes;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Switcher = styled.div`
  span {
    margin-right: 5px;
    cursor: pointer;
    &.active {
      cursor: default;
      font-weight: bold;
      color: #228BE6;
    }
  }
  span:last-child {
    margin-right: 0;
  }
`;

const ListContainer = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  height: 300px;
  overflow: auto;
`;

const ListItem = styled.li`
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ItemTitle = styled.div`
  width: 90%;
  text-transform: capitalize;
`;

const ItemQuantity = styled.div`
  width: 10%;
  text-align: right;
`;
