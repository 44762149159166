import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveTurnsList = createAsyncThunk('Course Turns/List', async () => {
  let data;
  try {
    const response = await http(endpoints.COURSE_TURNS, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.turni;
});

export const addTurn = createAsyncThunk('Course Turns/Add', async ({ turn, description = '' }) => {
  let insertedTurn;
  try {
    const response = await http(endpoints.COURSE_TURNS, {
      method: 'POST',
      data: {
        turn,
        description
      }
    });
    insertedTurn = response.data.turno;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedTurn;
});

export const editTurn = createAsyncThunk('Course Turns/Edit', async ({ id, turn, description = '' }) => {
  let response;
  try {
    response = await http(`${ endpoints.COURSE_TURNS }/${ id }`, {
      method: 'PATCH',
      data: {
        turn,
        description
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.turno;
});

export const deleteTurn = createAsyncThunk('Course Turns/Delete', async (id) => {
  try {
    await http(`${ endpoints.COURSE_TURNS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreTurn = createAsyncThunk('Course Turns/Restore', async (id) => {
  try {
    await http(`${ endpoints.COURSE_TURNS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

const thunks = { retrieveTurnsList, addTurn, editTurn, deleteTurn, restoreTurn };

export default thunks;
