import { Slices } from "../../constants/slices";
import OperatorInitialState from "./state";
import { selectOperator, closeOperatorsModal } from "./actions";
import { retrieveOperatorsList, addOperator, editOperator, changeStatusOperator, deleteOperator, restoreOperator } from "./thunk";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.OPERATORS,
  initialState: OperatorInitialState,
  reducers: {},
  extraReducers: {
    // retrieve operators list
    [retrieveOperatorsList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveOperatorsList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      operators: [ ...action.payload ],
      error: null
    }),
    [retrieveOperatorsList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new operator
    [addOperator.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addOperator.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      operators: [ action.payload, ...state.operators ],
      error: null,
      selectedOperator: null,
      showModal: false
    }),
    [addOperator.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit operator
    [editOperator.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editOperator.fulfilled.type]: (state, action) => {
      const index = state.operators.findIndex((el) => el.id === action.payload.id);
      const operators = [ ...state.operators ];
      operators[index] = action.payload;
      return {
        ...state,
        loading: false,
        operators: [ ...operators ],
        error: null,
        selectedOperator: null,
        showModal: false
      };
    },
    [editOperator.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // change operator status
    [changeStatusOperator.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [changeStatusOperator.fulfilled.type]: (state, action) => {
      const index = state.operators.findIndex((el) => el.id === action.payload);
      const operators = [ ...state.operators ];
      operators[index] = {
        ...operators[index],
        status: operators[index].status === 'abilitato'
          ? 'disabilitato'
          : 'abilitato'
      };
      return {
        ...state,
        loading: false,
        operators: [ ...operators ],
        error: null,
        selectedOperator: null,
        showModal: false
      };
    },
    [changeStatusOperator.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete operator
    [deleteOperator.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteOperator.fulfilled.type]: (state, action) => {
      const index = state.operators.findIndex((el) => el.id === action.payload);
      const operators = [ ...state.operators ];
      operators[index] = { ...operators[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        operators: [ ...operators ],
        error: null,
        selectedOperator: null,
        showModal: false
      };
    },
    [deleteOperator.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore operator
    [restoreOperator.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreOperator.fulfilled.type]: (state, action) => {
      const index = state.operators.findIndex((el) => el.id === action.payload);
      const operators = [ ...state.operators ];
      operators[index] = { ...operators[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        operators: [ ...operators ],
        error: null,
        selectedOperator: null,
        showModal: false
      };
    },
    [restoreOperator.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific operator reference
    [selectOperator]: (state, action) => ({
      ...state,
      selectedOperator: action.payload.operator
        ? action.payload.operator
        : null,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeOperatorsModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedOperator: null,
      modalType: null
    })
  }
});

export default slice.reducer;
