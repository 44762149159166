import { Slices } from "../../constants/slices";
import CoursesSearchInitialState from "./state";
import { searchCourses, textSearchCourses } from './thunk';

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.COURSE_SEARCH,
  initialState: CoursesSearchInitialState,
  reducers: {},
  extraReducers: {
    [searchCourses.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [searchCourses.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      results: [ ...action.payload ],
      error: null
    }),
    [searchCourses.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    })
  }
});

export default slice.reducer;
