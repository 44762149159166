import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const CourseTitleModalEdit = ({ title, onClose, onEdit }) => {

  const validateTitle = (value) => value !== null && value.trim().length > 0;

  const [ state, setState ] = useState({
    value: title && title.titolo ? title.titolo : '',
    valid: validateTitle(title && title.titolo ? title.titolo : ''),
    touched: false
  });

  const titleHandler = (e) => setState((state) => ({
    ...state,
    value: e.target.value,
    valid: validateTitle(e.target.value),
    touched: true
  }));

  const editCourseTitleHandler = (e) => {
    e.preventDefault();
    if (state.valid) {
      onEdit(state.value);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica titolo corso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editCourseTitleHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseType">
                <Form.Label>Categoria</Form.Label>
                  <Form.Control
                    onChange={ titleHandler }
                    onBlur={ titleHandler }
                    value={ state.value }
                    type="text"
                    placeholder="Inserisci la categoria"
                    required/>
                  { !state.value && state.touched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ editCourseTitleHandler } disabled={ !state.valid }>Modifica titolo corso</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTitleModalEdit;
