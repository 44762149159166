import { Button, Col, Form, FormGroup, Modal, Row, Tab, Tabs } from "react-bootstrap";
import { useEffect, useState } from "react";
import ViewTitle from "../../ViewTitle/ViewTitle";
import LiveSearch from "../../LiveSearch/LiveSearch";
import useStudents from "../../../hooks/useStudents";
import useCourses from "../../../hooks/useCourses";
import { retrieveStudentsList } from "../../../store/students/thunk";
import { useDispatch } from "react-redux";
import { retrieveCoursesList } from "../../../store/courses/thunk";
import styled from "styled-components";
import useOperators from "../../../hooks/useOperators";
import { retrieveOperatorsList } from "../../../store/operators/thunk";

const SubscriptionModalAdd = ({ onClose, onAdd }) => {

  const dispatch = useDispatch();

  const { studentsList } = useStudents();
  const { coursesList } = useCourses();
  const { operatorsList } = useOperators();

  const [ activeTab, setActiveTab ] = useState('subscription');

  const today = new Date().toISOString().substr(0, 10);

  const [ subscriptionIsValid, setSubscriptionIsValid ] = useState(false);
  const [ surveyIsValid, setSurveyIsValid ] = useState(false);

  const [ subscription, setSubscription ] = useState({
    dataIscrizione: today,
    operatore: null,
    studente: null,
    corso: null,
    tipologiaIscrizione: null,
    costo: 0.0,
    acconto: 0.0,
    scadenzaAcconto: today,
    kit: 0.0,
    tipologiaPagamento: null,
    numeroRate: 0,
    costoRata: 0,
    rateAutomatiche: true,
    rateManuali: [
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' },
      { importo: 0.0, scadenza: '' }
    ],
    note: '',
    noteContratto: ''
  });
  const [ survey, setSurvey ] = useState({
    chooseReason: 0,
    howKnowAboutUs: 0,
    sentToUs: '',
    whereDidYouSeeUs: '',
    howWillYouComeToClasses: 0,
    occupation: 0,
    attendedToOtherCourse: 'no',
    attendedCourses: '',
    attendedToOtherPrivateCourse: 'no',
    attendedPrivateCourses: '',
    noteQuestionario: ''
  });

  useEffect(() => {
    setSubscriptionIsValid(
      subscription.dataIscrizione.trim().length > 0 &&
      subscription.operatore && subscription.operatore.id &&
      subscription.studente && subscription.studente.id &&
      subscription.corso && subscription.corso.id &&
      subscription.tipologiaIscrizione &&
      subscription.costo !== null &&
      subscription.acconto !== null &&
      subscription.scadenzaAcconto.trim().length > 0 &&
      subscription.kit !== null &&
      subscription.tipologiaPagamento &&
      subscription.numeroRate !== null &&
      subscription.costoRata !== null &&
      typeof subscription.rateAutomatiche === 'boolean' &&
      Array.isArray(subscription.rateManuali)
    )
  }, [ subscription ]);

  useEffect(() => {
    setSurveyIsValid(
      survey.chooseReason !== 0 &&
      survey.howKnowAboutUs !== 0 &&
      // survey.sentToUs.trim().length > 0 &&
      // survey.whereDidYouSeeUs.trim().length > 0 &&
      survey.howWillYouComeToClasses !== 0 &&
      survey.occupation !== 0
    );
  }, [ survey ]);

  const goToSurveyHandler = () => setActiveTab('survey');
  const goToSubscription = () => setActiveTab('subscription');
  const onStudentTypeHandler = (value) => dispatch(retrieveStudentsList(value.trim()));
  const onCourseTypeHandler = (value) => dispatch(retrieveCoursesList(value.trim()));
  const onOperatorTypeHandler = (value) => dispatch(retrieveOperatorsList(value.trim()));
  const onStudentSelectHandler = (studente) => setSubscription({ ...subscription, studente });
  const onCourseSelectHandler = (corso) => setSubscription({ ...subscription, corso });
  const onOperatorSelectHandler = (operatore) => setSubscription({ ...subscription, operatore });
  const subscriptionDateHandler = (e) => setSubscription((state) => ({ ...state, dataIscrizione: e.target.value }));
  const costInputHandler = (e) => setSubscription((state) => ({ ...state, costo: +e.target.value.replace(',', '.') }));
  const depositInputHandler = (e) => setSubscription((state) => ({ ...state, acconto: +e.target.value.replace(',', '.') }));
  const depositExpirationHandler = (e) => setSubscription((state) => ({ ...state, scadenzaAcconto: e.target.value }));
  const kitInputHandler = (e) => setSubscription((state) => ({ ...state, kit: +e.target.value.replace(',', '.') }));
  const contractNotesHandler = (e) => setSubscription((state) => ({ ...state, noteContratto: e.target.value }));
  const notesHandler = (e) => setSubscription((state) => ({ ...state, note: e.target.value }));
  const installmentsInputHandler = (e) => setSubscription((state) => ({
    ...state,
    numeroRate: +e.target.value,
    costoRata: isFinite(Math.round(((((state.costo + state.kit) - state.acconto) / +e.target.value) + Number.EPSILON) * 100) / 100)
      ? Math.round(((((state.costo + state.kit) - state.acconto) / +e.target.value) + Number.EPSILON) * 100) / 100
      : 0
  }));
  const manualInstallmentsHandler = (e) => {
    const prop = e.target.id.charAt(0) === 'r' ? 'importo' : 'scadenza';
    const index = e.target.id.charAt(1);
    const value = e.target.id.charAt(0) === 'r' ? +e.target.value : e.target.value;
    setSubscription((state) => {
      const newState = { ...state };
      newState.rateManuali[index][prop] = value;
      return { ...newState};
    });
  };
  const installmentsModeHandler = (e) => setSubscription((state) => ({
    ...state,
    rateAutomatiche: e.target.checked
  }));
  const subscriptionTypeHandler = (e) => setSubscription((state) => ({ ...state, tipologiaIscrizione: e.target.id }));
  const paymentMethodTypeHandler = (e) => setSubscription((state) => ({ ...state, tipologiaPagamento: e.target.id }));

  const selectHandler = (e) => setSurvey((state) => {
    const prop = e.target.id;
    const value = e.target.value;
    const newState = { ...state };
    newState[prop] = value;
    return { ...newState };
  });
  const radioHandler = (e) => setSurvey((state) => {
    const prop = e.target.name;
    const value = e.target.id;
    const newState = { ...state };
    newState[prop] = value;
    return { ...newState };
  });
  const sentToUsHandler = (e) => setSurvey((state) => ({ ...state, sentToUs: e.target.value }));
  const whereDidYouSeeUsHandler = (e) => setSurvey((state) => ({ ...state, whereDidYouSeeUs: e.target.value }));
  const surveyNoteHandler = (e) => setSurvey((state) => ({ ...state, noteQuestionario: e.target.value }));
  const attendedCoursesHandler = (e) => setSurvey((state) => ({ ...state, attendedCourses: e.target.value }));
  const attendedPrivateCoursesHandler = (e) => setSurvey((state) => ({ ...state, attendedPrivateCourses: e.target.value }));

  const addSubscriptionHandler = (e) => {
    e.preventDefault();
    if (subscriptionIsValid && surveyIsValid) {
      onAdd({ ...subscription, ...survey });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Inserimento nuova iscrizione
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Tabs activeKey={ activeTab } id="uncontrolled-tab-example" className="mb-3">

          <Tab eventKey="subscription" title="Iscrizione">
            <Form onSubmit={ goToSurveyHandler }>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Dettagli pratica" size="h4"/>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Data iscrizione</Form.Label>
                    <Form.Control
                      id="dataIscrizione"
                      value={ subscription.dataIscrizione }
                      type="date"
                      onChange={ subscriptionDateHandler }/>
                  </FormGroup>
                </Col>
                <Col>
                  <LiveSearch
                    label="Ricerca operatore"
                    type="operators"
                    list={ operatorsList }
                    onChange={ onOperatorTypeHandler }
                    onSelect={ onOperatorSelectHandler }/>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Studente e Corso" size="h4"/>
                </Col>
                <Col sm="12" lg="4">
                  <LiveSearch
                    label="Ricerca studente"
                    type="students"
                    list={ studentsList }
                    onChange={ onStudentTypeHandler }
                    onSelect={ onStudentSelectHandler } />
                </Col>
                <Col sm="12" lg="8">
                  <LiveSearch
                    label="Ricerca corso"
                    type="courses"
                    list={ coursesList }
                    onChange={ onCourseTypeHandler }
                    onSelect={ onCourseSelectHandler }/>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Metodo di pagamento" size="h4"/>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <Form.Check
                    inline
                    label="Rate"
                    type="radio"
                    name="paymentMethod"
                    id="rate"
                    onChange={ paymentMethodTypeHandler }
                  />
                </Col>
                <Col lg="3">
                  <Form.Check
                    inline
                    label="Finanziaria"
                    type="radio"
                    name="paymentMethod"
                    id="finanziaria"
                    onChange={ paymentMethodTypeHandler }
                  />
                </Col>
                <Col lg="3">
                  <Form.Check
                    inline
                    label="Cessione del credito"
                    type="radio"
                    name="paymentMethod"
                    id="cessione"
                    onChange={ paymentMethodTypeHandler }
                  />
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Costi" size="h4"/>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Form.Label>Costo del corso</Form.Label>
                    <Form.Control
                      onChange={ costInputHandler }
                      value={ subscription.costo }
                      type="number"/>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Form.Label>Acconto</Form.Label>
                    <Form.Control
                      onChange={ depositInputHandler }
                      value={ subscription.acconto }
                      type="number"/>
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Form.Label>Data scadenza acconto</Form.Label>
                    <Form.Control
                      value={ subscription.scadenzaAcconto }
                      type="date"
                      onChange={ depositExpirationHandler }/>
                  </FormGroup>
                </Col>
                <Col lg="3">
                  <FormGroup>
                    <Form.Label>Kit</Form.Label>
                    <Form.Control
                      onChange={ kitInputHandler }
                      value={ subscription.kit }
                      type="number"/>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Tipologia iscrizione" size="h4"/>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col lg="3">
                  <Form.Check
                    inline
                    label="In sede"
                    type="radio"
                    name="subscriptionType"
                    id="sede"
                    onChange={ subscriptionTypeHandler }
                  />
                </Col>
                <Col lg="3">
                  <Form.Check
                    inline
                    label="Online"
                    type="radio"
                    name="subscriptionType"
                    id="online"
                    onChange={ subscriptionTypeHandler }
                  />
                </Col>
                <Col lg="3">
                  <Form.Check
                    inline
                    label="A distanza"
                    type="radio"
                    name="subscriptionType"
                    id="distanza"
                    onChange={ subscriptionTypeHandler }
                  />
                </Col>
              </Row>
              {
                subscription.tipologiaPagamento === 'rate' && (
                  <>
                    <Row>
                      <Col sm="12">
                        <ViewTitle title="Rate" size="h4"/>
                      </Col>
                    </Row>
                    <Row>
                      <VAlignContainer lg="12" className="mb-3">
                        <Form.Check
                          type="switch"
                          id="custom-switch"
                          checked={ subscription.rateAutomatiche }
                          label={ subscription.rateAutomatiche ? "Rate generate automaticamente" : "Inserimento manuale" }
                          onChange={ installmentsModeHandler }
                        />
                      </VAlignContainer>
                    </Row>
                  </>
                )
              }
              <Row>
                {
                  subscription.tipologiaPagamento === 'rate' && subscription.rateAutomatiche && (
                    <>
                      <Col lg="3">
                        <FormGroup>
                          <Form.Label>Numero rate</Form.Label>
                          <Form.Control
                            onChange={ installmentsInputHandler }
                            value={ subscription.numeroRate }
                            min={ 0 }
                            max={ 14 }
                            type="number"/>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <Form.Label>Costo singola rata</Form.Label>
                          <Form.Control
                            disabled
                            value={ subscription.costoRata }
                            type="number"/>
                        </FormGroup>
                      </Col>
                    </>
                  )
                }
              </Row>
              <Row>
                {
                  subscription.tipologiaPagamento === 'rate' && !subscription.rateAutomatiche && (
                    [ 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ].map((el, index) => (
                      <Col key={ index } sm="12" lg="6">
                        <InstallmentContainer>
                          <Col sm="12">
                            <ViewTitle title={ `${el}° Rata` } size="h4"/>
                          </Col>
                          <Row>
                            <Col key={ `importo-${ el }` } sm="12" lg="6">
                              <FormGroup>
                                <Form.Label>Importo</Form.Label>
                                <Form.Control
                                  id={ `r${ index }` }
                                  value={ subscription.rateManuali[index].importo }
                                  type="number"
                                  onChange={ manualInstallmentsHandler }/>
                              </FormGroup>
                            </Col>
                            <Col key={ `scadenza-${ el }` } sm="12" lg="6">
                              <FormGroup>
                                <Form.Label>Scadenza</Form.Label>
                                <Form.Control
                                  id={ `d${ index }` }
                                  value={ subscription.rateManuali[index].scadenza }
                                  type="date"
                                  onChange={ manualInstallmentsHandler }/>
                              </FormGroup>
                            </Col>
                          </Row>
                        </InstallmentContainer>
                      </Col>
                    ))
                  )
                }
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Note contratto" size="h4"/>
                </Col>
                <Col sm="12" md="12" lg="12">
                  <Form.Group controlId="formCellular">
                    <Form.Control
                      as="textarea"
                      style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                      onChange={ contractNotesHandler }
                      value={ subscription.noteContratto }
                      type="text"
                      placeholder="Inserisci qui eventuali note da mostrare sul contratto"/>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm="12">
                  <ViewTitle title="Note interne" size="h4"/>
                </Col>
                <Col sm="12" md="12" lg="12">
                  <Form.Group controlId="formCellular">
                    <Form.Control
                      as="textarea"
                      style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                      onChange={ notesHandler }
                      value={ subscription.note }
                      type="text"
                      placeholder="Inserisci qui eventuali note da allegare alla pratica"/>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Tab>

          <Tab eventKey="survey" title="Questionario marketing" disabled={ !subscriptionIsValid }>
            <Form onSubmit={ addSubscriptionHandler }>
              <Row>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Qual'è il motivo per il quale hai scelto questa scuola?</Form.Label>
                    <Form.Control
                      id="chooseReason"
                      as="select"
                      value={ survey.chooseReason }
                      onChange={ selectHandler }
                      onBlur={ selectHandler }
                      required>
                      <option disabled value={ 0 }>Seleziona la motivazione</option>
                      <option value="Chiarezza nelle informazioni">Chiarezza nelle informazioni</option>
                      <option value="Vicinanza">Vicinanza</option>
                      <option value="Collegamento mezzi pubblici">Collegamento mezzi pubblici</option>
                      <option value="Prezzo">Prezzo</option>
                      <option value="Accoglienza">Accoglienza</option>
                      <option value="Professionalità">Professionalità</option>
                      <option value="Buona impressione">Buona impressione</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Come hai conosciuto questa scuola?</Form.Label>
                    <Form.Control
                      id="howKnowAboutUs"
                      as="select"
                      value={ survey.howKnowAboutUs }
                      onChange={ selectHandler }
                      onBlur={ selectHandler }
                      required>
                      <option disabled value={ 0 }>Seleziona dalla lista</option>
                      <option value="Google">Google</option>
                      <option value="Facebook">Facebook</option>
                      <option value="TikTok">TikTok</option>
                      <option value="Radio">Radio</option>
                      <option value="Passa-parola amici">Passa-parola amici</option>
                      <option value="Emagister">Emagister</option>
                      <option value="Email Marketing">Email Marketing</option>
                      <option value="Riviste di settore">Riviste di settore</option>
                      <option value="Fiera estetica">Fiera estetica</option>
                      <option value="Aziende partner">Aziende partner</option>
                      <option value="Consulenti">Consulenti</option>
                      <option value="Sono un ex-allievo">Sono un ex-allievo</option>
                      <option value="Altro">Altro</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Chi ti ha mandato da noi?</Form.Label>
                    <Form.Control
                      onChange={ sentToUsHandler }
                      value={ survey.sentToUs }
                      type="text"
                      placeholder="Inserisci il nominativo della persona che ti ha mandato da noi"/>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Dove ci hai visto?</Form.Label>
                    <Form.Control
                      onChange={ whereDidYouSeeUsHandler }
                      value={ survey.whereDidYouSeeUs }
                      type="text"
                      placeholder="Inserisci il canale tramite il quale lo studente ci ha notato"/>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Con che mezzo verrai alle lezioni?</Form.Label>
                    <Form.Control
                      id="howWillYouComeToClasses"
                      as="select"
                      value={ survey.howWillYouComeToClasses }
                      onChange={ selectHandler }
                      onBlur={ selectHandler }
                      required>
                      <option disabled value={ 0 }>Seleziona il mezzo di trasporto</option>
                      <option value="Macchina/Motorino">Macchina/Motorino</option>
                      <option value="Metro A">Metro A</option>
                      <option value="Metro + treno">Metro + treno</option>
                      <option value="Metro A + Metro B">Metro A + Metro B</option>
                      <option value="Autobus">Autobus</option>
                      <option value="Bicicletta">Bicicletta</option>
                      <option value="Monopattino">Monopattino</option>
                      <option value="A piedi">A piedi</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Qual'è la tua attuale occupazione?</Form.Label>
                    <Form.Control
                      id="occupation"
                      as="select"
                      value={ survey.occupation }
                      onChange={ selectHandler }
                      onBlur={ selectHandler }
                      required>
                      <option disabled value={ 0 }>Seleziona il mezzo di trasporto</option>
                      <option value="Casalinga">Casalinga</option>
                      <option value="Studente">Studente</option>
                      <option value="Inoccupato">Inoccupato</option>
                      <option value="Disoccupato">Disoccupato</option>
                      <option value="Occupato">Occupato</option>
                      <option value="Libero professionista/Imprenditore">Libero professionista/Imprenditore</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col>
                      <Form.Label>In passato hai partecipato ad altri corsi regionali e/o provinciali?</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <VAlignContainer>
                      <Form.Check
                        inline
                        label="Si"
                        type="radio"
                        name="attendedToOtherCourse"
                        id="si"
                        onChange={ radioHandler }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        name="attendedToOtherCourse"
                        id="no"
                        onChange={ radioHandler }
                      />
                    </VAlignContainer>
                  </Row>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Quali?</Form.Label>
                    <Form.Control
                      onChange={ attendedCoursesHandler }
                      disabled={ survey.attendedToOtherCourse === 'no' }
                      value={ survey.attendedCourses }
                      type="text"
                      placeholder="Inserisci a quali altri corsi ha preso parte lo studente"/>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col>
                      <Form.Label>In passato hai partecipato ad altri corsi privati?</Form.Label>
                    </Col>
                  </Row>
                  <Row>
                    <VAlignContainer>
                      <Form.Check
                        inline
                        label="Si"
                        type="radio"
                        name="attendedToOtherPrivateCourse"
                        id="si"
                        onChange={ radioHandler }
                      />
                      <Form.Check
                        inline
                        label="No"
                        type="radio"
                        name="attendedToOtherPrivateCourse"
                        id="no"
                        onChange={ radioHandler }
                      />
                    </VAlignContainer>
                  </Row>
                </Col>
                <Col>
                  <Form.Group>
                    <Form.Label>Quali?</Form.Label>
                    <Form.Control
                      onChange={ attendedPrivateCoursesHandler }
                      disabled={ survey.attendedToOtherPrivateCourse === 'no' }
                      value={ survey.attendedPrivateCourses }
                      type="text"
                      placeholder="Inserisci a quali altri corsi ha preso parte lo studente"/>
                  </Form.Group>
                </Col>
                <Col sm="12" md="12" lg="12">
                  <Form.Group>
                    <Form.Control
                      as="textarea"
                      style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                      onChange={ surveyNoteHandler }
                      value={ survey.noteQuestionario }
                      type="text"
                      placeholder="Inserisci qui eventuali note interne"/>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </Tab>

        </Tabs>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        {
          activeTab === 'subscription' && (
            <Button
              variant="primary"
              disabled={ !subscriptionIsValid }
              onClick={ goToSurveyHandler }>
              Avanti
            </Button>
          )
        }
        {
          activeTab === 'survey' && (
            <Button
              variant="info"
              onClick={ goToSubscription }>
              Indietro
            </Button>
          )
        }
        {
          activeTab === 'survey' && (
            <Button
              variant="primary"
              disabled={ !surveyIsValid }
              onClick={ addSubscriptionHandler }>
              Inserisci iscrizione
            </Button>
          )
        }
      </Modal.Footer>
    </>
  );

}

export default SubscriptionModalAdd;

const VAlignContainer = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  align-content: center;
`;

const InstallmentContainer = styled.div`
  padding: 20px;
  background-color: #fafafa;
  margin-bottom: 20px;
  border: 1px solid #ced4da;
  border-radius: .25rem;
`;
