import { Slices } from "../../constants/slices";
import StudentsInitialState from "./state";
import { selectStudent, closeStudentsModal } from "./actions";
import { retrieveStudentsList, addStudent, editStudent, deleteStudent, restoreStudent, textSearchStudent } from './thunk';

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.STUDENTS,
  initialState: StudentsInitialState,
  reducers: {},
  extraReducers: {
    // retrieve students list
    [retrieveStudentsList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveStudentsList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      students: [ ...action.payload ],
      error: null
    }),
    [retrieveStudentsList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new student
    [addStudent.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addStudent.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      students: [ action.payload, ...state.students ],
      error: null,
      selectedStudent: null,
      showModal: false
    }),
    [addStudent.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit student
    [editStudent.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editStudent.fulfilled.type]: (state, action) => {
      const index = state.students.findIndex((el) => el.id === action.payload.id);
      const students = [ ...state.students ];
      students[index] = action.payload;
      return {
        ...state,
        loading: false,
        students: [ ...students ],
        error: null,
        selectedStudent: null,
        showModal: false
      };
    },
    [editStudent.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete student
    [deleteStudent.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteStudent.fulfilled.type]: (state, action) => {
      const index = state.students.findIndex((el) => el.id === action.payload);
      const students = [ ...state.students ];
      students[index] = { ...students[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        students: [ ...students ],
        error: null,
        selectedStudent: null,
        showModal: false
      };
    },
    [deleteStudent.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore student
    [restoreStudent.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreStudent.fulfilled.type]: (state, action) => {
      const index = state.students.findIndex((el) => el.id === action.payload);
      const students = [ ...state.students ];
      students[index] = { ...students[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        students: [ ...students ],
        error: null,
        selectedStudent: null,
        showModal: false
      };
    },
    [restoreStudent.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific operator reference
    [selectStudent]: (state, action) => ({
      ...state,
      selectedStudent: action.payload.student
        ? action.payload.student
        : null,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeStudentsModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedStudent: null,
      modalType: null
    }),
    [textSearchStudent.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [textSearchStudent.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      students: [ ...action.payload ],
      error: null
    }),
    [textSearchStudent.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    })
  }
});

export default slice.reducer;
