import styled from "styled-components";
import { Bar, ResponsiveContainer, BarChart, XAxis, YAxis, LabelList } from "recharts";

import WidgetContainer from "../WidgetContainer/WidgetContainer";

const RealCashFlowChart = ({ kpi }) => {

  return (
    <WidgetContainer sm={ 1 } md={ 1 } lg={ 2 }>
      <TitleContainer>
        <h4>Cash Flow Effettivo Annuale</h4>
      </TitleContainer>
      <ResponsiveContainer height={ 350 } width="100%">
        <BarChart data={ kpi } width="50%">
          <XAxis dataKey="month"/>
          <YAxis padding={{ top: 50 }}/>
          <Bar dataKey="amount" fill="rgba(34, 139, 230, .6)">
            <LabelList dataKey="amount" position="top" fill="rgba(34, 139, 230, 1)"/>
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </WidgetContainer>
  );

};

export default RealCashFlowChart;

const TitleContainer = styled.div`
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
