import { useSelector } from "react-redux";
import { selectedStudentSelector, studentsListSelector, studentsLoadingSelector, studentsModalSelector, studentsModalType } from "../store/students/selectors";

const useStudents = () => {

  const studentsColumns = [
    { id: 'id', name: 'ID' },
    { id: 'nome', name: 'Nome' },
    { id: 'cognome', name: 'Cognome' },
    { id: 'sesso', name: 'Sesso' },
    { id: 'provincia_nascita', name: null },
    { id: 'nazione_nascita', name: null },
    { id: 'data_nascita', name: 'Data di Nascita' },
    { id: 'cittadinanza', name: null },
    { id: 'nazionalita', name: null },
    { id: 'numero_permesso_soggiorno', name: null },
    { id: 'scadenza_permesso_soggiorno', name: null },
    { id: 'codice_fiscale', name: 'Codice Fiscale' },
    { id: 'citta_residenza', name: null },
    { id: 'provincia_residenza', name: null },
    { id: 'indirizzo_residenza', name: null },
    { id: 'cap_residenza', name: null },
    { id: 'titolo_studio', name: null },
    { id: 'anno_conseguimento', name: null },
    { id: 'tipo_documento', name: null },
    { id: 'numero_documento', name: null },
    { id: 'cellulare', name: null },
    { id: 'telefono', name: null },
    { id: 'email', name: null },
    { id: 'email_fad', name: null },
    { id: 'note', name: null },
    { id: 'deleted', name: 'Stato' },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const studentsList = useSelector(studentsListSelector);
  const selectedStudent = useSelector(selectedStudentSelector);
  const showStudentsModal = useSelector(studentsModalSelector);
  const modalType = useSelector(studentsModalType);
  const isLoading = useSelector(studentsLoadingSelector);

  return {
    studentsColumns,
    studentsList,
    selectedStudent,
    showStudentsModal,
    modalType,
    isLoading
  };

};

export default useStudents;
