import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveCourseTitlesList = createAsyncThunk('Course Titles/List', async () => {
  let data;
  try {
    const response = await http(endpoints.COURSE_TITLES, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.titoli;
});

export const addCourseTitle = createAsyncThunk('Course Titles/Add', async ({ title }) => {
  let insertedTitle;
  try {
    const response = await http(endpoints.COURSE_TITLES, {
      method: 'POST',
      data: {
        title
      }
    });
    insertedTitle = response.data.titolo;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedTitle;
});

export const editCourseTitle = createAsyncThunk('Course Titles/Edit', async ({ id, title }) => {
  let response;
  try {
    response = await http(`${ endpoints.COURSE_TITLES }/${ id }`, {
      method: 'PATCH',
      data: {
        title
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.titolo;
});

export const deleteCourseTitle = createAsyncThunk('Course Titles/Delete', async (id) => {
  try {
    await http(`${ endpoints.COURSE_TITLES }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreCourseTitle = createAsyncThunk('Course Titles/Restore', async (id) => {
  try {
    await http(`${ endpoints.COURSE_TITLES }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

const thunks = { retrieveCourseTitlesList, addCourseTitle, editCourseTitle, deleteCourseTitle, restoreCourseTitle };

export default thunks;
