import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import CourseTitleModalDelete from "./CourseTitleModalDelete";
import CourseTitleModalRestore from "./CourseTitleModalRestore";
import CourseTitleModalAdd from "./CourseTitleModalAdd";
import CourseTitleModalEdit from "./CourseTitleModalEdit";
import { addCourseTitle, deleteCourseTitle, editCourseTitle, restoreCourseTitle } from "../../../store/courseTitles/thunk";
import useCourseTitles from "../../../hooks/useCourseTitles";
import { closeCourseTitlesModal } from "../../../store/courseTitles/actions";

const CourseTitleModal = () => {

  const dispatch = useDispatch();
  const { selectedCourseTitle, showCourseTitleModal, modalType } = useCourseTitles();

  const closeModalHandler = () => dispatch(closeCourseTitlesModal());

  const addCourseTitleHandler = (title) => dispatch(addCourseTitle({ title }));
  const editCourseTitleHandler = (title) => dispatch(editCourseTitle({ id: selectedCourseTitle.id, title }));
  const deleteCourseTitleHandler = () => dispatch(deleteCourseTitle(selectedCourseTitle.id));
  const restoreCourseTitleHandler = () => dispatch(restoreCourseTitle(selectedCourseTitle.id));

  return (
    <Modal
      size="lg"
      centered
      show={ showCourseTitleModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.CourseTitles.ADD &&
        <CourseTitleModalAdd
          onClose={ closeModalHandler }
          onAdd={ addCourseTitleHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTitles.EDIT &&
        <CourseTitleModalEdit
          title={ selectedCourseTitle }
          onClose={ closeModalHandler }
          onEdit={ editCourseTitleHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTitles.DELETE &&
        <CourseTitleModalDelete
          title={ selectedCourseTitle }
          onClose={ closeModalHandler }
          onDelete={ deleteCourseTitleHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTitles.RESTORE &&
        <CourseTitleModalRestore
          title={ selectedCourseTitle }
          onClose={ closeModalHandler }
          onRestore={ restoreCourseTitleHandler }/>
      }
    </Modal>
  );

}

export default CourseTitleModal;
