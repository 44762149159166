import useCourses from "../../hooks/useCourses";
import useLocations from "../../hooks/useLocations";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import Table from "../../components/Table/Table";
import CourseModal from "../../components/modals/courses/CourseModal";
import CourseTypeModal from "../../components/modals/types/CourseTypeModal";
import LocationModal from "../../components/modals/locations/LocationModal";
import TurnModal from "../../components/modals/turns/TurnModal";
import TabContentHeader from "../../components/TabContentHeader/TabContentHeader";
import useCoursesTypes from "../../hooks/useCoursesTypes";
import useCourseTurns from "../../hooks/useCourseTurns";
import useCourseTitles from "../../hooks/useCourseTitles";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { selectCourse } from "../../store/courses/actions";
import { retrieveCoursesList, retrieveCoursesTypeList } from "../../store/courses/thunk";
import { retrieveLocationsList } from "../../store/locations/thunk";
import { retrieveTurnsList } from "../../store/courseTurns/thunk";
import { Modals } from "../../constants/modals";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { selectLocation } from "../../store/locations/actions";
import { selectCoursesType } from "../../store/coursesType/actions";
import { selectTurn } from "../../store/courseTurns/actions";
import { selectCourseTitle } from "../../store/courseTitles/actions";
import { retrieveCourseTitlesList } from "../../store/courseTitles/thunk";
import CourseTitleModal from "../../components/modals/titles/CourseTitleModal";
import SearchBarCourse from "../../components/searchBars/SearchBarCourse/SearchBarCourse";
import { textSearchCourses } from "../../store/courseSearch/thunk";

const Courses = () => {

  const dispatch = useDispatch();

  const { coursesColumns, coursesList } = useCourses();
  const { typesColumns, coursesTypeList } = useCoursesTypes();
  const { locationsColumns, locationsList } = useLocations();
  const { turnsColumns, turnsList } = useCourseTurns();
  const { courseTitlesColumns, courseTitlesList } = useCourseTitles();

  const addCourseHandler = () => dispatch(selectCourse({ type: Modals.Courses.ADD }));
  const editCourseHandler = (row) => dispatch(selectCourse({ course: row, type: Modals.Courses.EDIT }));
  const deleteCourseHandler = (row) => dispatch(selectCourse({ course: row, type: Modals.Courses.DELETE }));
  const restoreCourseHandler = (row) => dispatch(selectCourse({ course: row, type: Modals.Courses.RESTORE }));

  const addCourseTypeHandler = () => dispatch(selectCoursesType({ type: Modals.CoursesType.ADD }));
  const editCourseTypeHandler = (row) => dispatch(selectCoursesType({ courseType: row, type: Modals.CoursesType.EDIT }));
  const deleteCourseTypeHandler = (row) => dispatch(selectCoursesType({ courseType: row, type: Modals.CoursesType.DELETE }));
  const restoreCourseTypeHandler = (row) => dispatch(selectCoursesType({ courseType: row, type: Modals.CoursesType.RESTORE }));

  const addLocationTypeHandler = () => dispatch(selectLocation({ type: Modals.Locations.ADD }));
  const editLocationHandler = (row) => dispatch(selectLocation({ location: row, type: Modals.Locations.EDIT }));
  const deleteLocationHandler = (row) => dispatch(selectLocation({ location: row, type: Modals.Locations.DELETE }));
  const restoreLocationHandler = (row) => dispatch(selectLocation({ location: row, type: Modals.Locations.RESTORE }));

  const addTurnHandler = () => dispatch(selectTurn({ type: Modals.CourseTurns.ADD }));
  const editTurnHandler = (row) => dispatch(selectTurn({ turn: row, type: Modals.CourseTurns.EDIT }));
  const deleteTurnHandler = (row) => dispatch(selectTurn({ turn: row, type: Modals.CourseTurns.DELETE }));
  const restoreTurnHandler = (row) => dispatch(selectTurn({ turn: row, type: Modals.CourseTurns.RESTORE }));

  const addCourseTitleHandler = () => dispatch(selectCourseTitle({ type: Modals.CourseTitles.ADD }));
  const editCourseTitleHandler = (row) => dispatch(selectCourseTitle({ title: row, type: Modals.CourseTitles.EDIT }));
  const deleteCourseTitleHandler = (row) => dispatch(selectCourseTitle({ title: row, type: Modals.CourseTitles.DELETE }));
  const restoreCourseTitleHandler = (row) => dispatch(selectCourseTitle({ title: row, type: Modals.CourseTitles.RESTORE }));

  const onCourseSearch = (payload) => dispatch(textSearchCourses(payload.criteria === 'titolo'
    ? { title: payload.value }
    : { startingDate: payload.value }
  ));

  useEffect(() => {
    dispatch(retrieveCoursesList());
    dispatch(retrieveCoursesTypeList());
    dispatch(retrieveLocationsList());
    dispatch(retrieveTurnsList());
    dispatch(retrieveCourseTitlesList());
  }, [ dispatch ]);

  return (
    <div className="view">
      <ViewTitle title="Corsi di formazione"/>
      <Tab.Container id="left-tabs-example" defaultActiveKey="lista-corsi">
        <Row>
          <Col sm={ 2 }>
            <Nav variant="pills" className="flex-column">
              <Nav.Item>
                <Nav.Link eventKey="lista-corsi">Corsi</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="categorie-corsi">Categorie</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="sedi">Sedi</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="turni">Turni</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="titoli-corsi">Titolo del Corso</Nav.Link>
              </Nav.Item>
            </Nav>
          </Col>
          <Col sm={ 10 }>
            <Tab.Content>
              <Tab.Pane eventKey="lista-corsi">
                <TabContentHeader
                  heading="Corsi"
                  buttonIcon={ faPlus }
                  buttonTitle="Aggiungi corso"
                  buttonHandler={ addCourseHandler }/>
                <SearchBarCourse
                  options={ [
                    { id: 1, value: 'titolo', type: 'text', text: 'Titolo del corso' },
                    { id: 2, value: 'data_inizio', type: 'date', text: 'Data di inizio' },
                  ] }
                  onSearch={ onCourseSearch }/>
                <Table
                  columns={ coursesColumns }
                  rows={ coursesList }
                  onEdit={ editCourseHandler }
                  onDelete={ deleteCourseHandler }
                  onRestore={ restoreCourseHandler }/>
              </Tab.Pane>
              <Tab.Pane eventKey="categorie-corsi">
                <TabContentHeader
                  heading="Categorie"
                  buttonIcon={ faPlus }
                  buttonTitle="Aggiungi categoria"
                  buttonHandler={ addCourseTypeHandler }/>
                <Table
                  columns={ typesColumns }
                  rows={ coursesTypeList }
                  onEdit={ editCourseTypeHandler }
                  onDelete={ deleteCourseTypeHandler }
                  onRestore={ restoreCourseTypeHandler }/>
              </Tab.Pane>
              <Tab.Pane eventKey="sedi">
                <TabContentHeader
                  heading="Sedi"
                  buttonIcon={ faPlus }
                  buttonTitle="Aggiungi sede"
                  buttonHandler={ addLocationTypeHandler }/>
                <Table
                  columns={ locationsColumns }
                  rows={ locationsList }
                  onEdit={ editLocationHandler }
                  onDelete={ deleteLocationHandler }
                  onRestore={ restoreLocationHandler }/>
              </Tab.Pane>
              <Tab.Pane eventKey="turni">
                <TabContentHeader
                  heading="Turni"
                  buttonIcon={ faPlus }
                  buttonTitle="Aggiungi turno"
                  buttonHandler={ addTurnHandler }/>
                <Table
                  columns={ turnsColumns }
                  rows={ turnsList }
                  onEdit={ editTurnHandler }
                  onDelete={ deleteTurnHandler }
                  onRestore={ restoreTurnHandler }/>
              </Tab.Pane>
              <Tab.Pane eventKey="titoli-corsi">
                <TabContentHeader
                  heading="Titoli Corsi"
                  buttonIcon={ faPlus }
                  buttonTitle="Aggiungi titolo corso"
                  buttonHandler={ addCourseTitleHandler }/>
                <Table
                  columns={ courseTitlesColumns }
                  rows={ courseTitlesList }
                  onEdit={ editCourseTitleHandler }
                  onDelete={ deleteCourseTitleHandler }
                  onRestore={ restoreCourseTitleHandler }/>
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
      <CourseModal/>
      <CourseTypeModal/>
      <LocationModal />
      <TurnModal />
      <CourseTitleModal />
    </div>
  );

}

export default Courses;
