import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const getGlobalForecastCashFlow = createAsyncThunk('KPI/Retrieve Global Forecast Cash Flow', async () => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/forecast-cash-flow`, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getGlobalRealCashFlow = createAsyncThunk('KPI/Retrieve Global Real Cash Flow', async () => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/real-cash-flow`, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getForecastCashFlow = createAsyncThunk('KPI/Retrieve Forecast Cash Flow', async (range) => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/forecast-cash-flow`, {
      method: 'POST',
      data: {
        range
      }
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getRealCashFlow = createAsyncThunk('KPI/Retrieve Real Cash Flow', async (range) => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/real-cash-flow`, {
      method: 'POST',
      data: {
        range
      }
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getCourseSubscriptionEnrollments = createAsyncThunk('KPI/Retrieve Course Subscription Enrollments Metrics', async (range) => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/course-subscription-enrollments`, {
      method: 'POST',
      data: {
        range
      }
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getCourseSubscriptionTypes = createAsyncThunk('KPI/Retrieve Course Subscription Types Metrics', async (range) => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/course-subscriptions-types`, {
      method: 'POST',
      data: {
        range
      }
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

export const getHowKnowAboutSchoolRanks = createAsyncThunk('KPI/Retrieve How Know About School Metrics', async (range) => {
  let data;
  try {
    const response = await http(`${ endpoints.KPI }/how-know-about-school`, {
      method: 'POST',
      data: {
        range
      }
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.kpi;
});

const thunks = { getForecastCashFlow, getRealCashFlow, getCourseSubscriptionEnrollments, getCourseSubscriptionTypes, getHowKnowAboutSchoolRanks };

export default thunks;
