import { Modal } from "react-bootstrap";
import { Modals } from "../../../constants/modals";
import { useDispatch } from "react-redux";
import useCourseSubscriptions from "../../../hooks/useCourseSubscriptions";
import SubscriptionModalDelete from "./SubscriptionModalDelete";
import SubscriptionModalRestore from "./SubscriptionModalRestore";
import SubscriptionModalAdd from "./SubscriptionModalAdd";
import SubscriptionModalEdit from "./SubscriptionModalEdit";
import SubscriptionModalInstallmentsEdit from "./SubscriptionModalInstallmentsEdit";
import SubscriptionModalView from "./SubscriptionModalView";
import { closeCourseSubscriptionsModal } from "../../../store/courseSubscriptions/actions";
import {
  addCourseSubscription,
  deleteCourseSubscription,
  editCourseSubscription,
  editCourseSubscriptionInstallments,
  restoreCourseSubscription
} from "../../../store/courseSubscriptions/thunk";

const SubscriptionModal = () => {

  const dispatch = useDispatch();
  const { isLoading, selectedCourseSubscription, showCourseSubscriptionsModal, modalType } = useCourseSubscriptions();

  const closeModalHandler = () => dispatch(closeCourseSubscriptionsModal());

  const addCourseSubscriptionHandler = (subscription) => dispatch(addCourseSubscription(subscription));
  const editCourseSubscriptionHandler = (subscription) => dispatch(editCourseSubscription({ id: selectedCourseSubscription.id, subscription }));
  const editCourseSubscriptionInstallmentsHandler = (installments) => dispatch(editCourseSubscriptionInstallments({ id: selectedCourseSubscription.id, installments }));
  const deleteCourseSubscriptionHandler = () => dispatch(deleteCourseSubscription(selectedCourseSubscription.id));
  const restoreCourseSubscriptionHandler = () => dispatch(restoreCourseSubscription(selectedCourseSubscription.id));

  return (
    <Modal
      size="xl"
      centered
      show={ showCourseSubscriptionsModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.CourseSubscriptions.ADD &&
        <SubscriptionModalAdd
          onClose={ closeModalHandler }
          onAdd={ addCourseSubscriptionHandler }/>
      }
      {
        modalType && modalType === Modals.CourseSubscriptions.VIEW &&
        <SubscriptionModalView
          loading={ isLoading }
          onClose={ closeModalHandler }
          sub={ selectedCourseSubscription }/>
      }
      {
        modalType && modalType === Modals.CourseSubscriptions.EDIT &&
        <SubscriptionModalEdit
          sub={ selectedCourseSubscription }
          onClose={ closeModalHandler }
          onEdit={ editCourseSubscriptionHandler }/>
      }
      {
        modalType && modalType === Modals.CourseSubscriptions.EDIT_INSTALLMENTS &&
        <SubscriptionModalInstallmentsEdit
          sub={ selectedCourseSubscription }
          onClose={ closeModalHandler }
          onInstallmentsEdit={ editCourseSubscriptionInstallmentsHandler }/>
      }
      {
        modalType && modalType === Modals.CourseSubscriptions.DELETE &&
        <SubscriptionModalDelete
          sub={ selectedCourseSubscription }
          onClose={ closeModalHandler }
          onDelete={ deleteCourseSubscriptionHandler }/>
      }
      {
        modalType && modalType === Modals.CourseSubscriptions.RESTORE &&
        <SubscriptionModalRestore
          sub={ selectedCourseSubscription }
          onClose={ closeModalHandler }
          onRestore={ restoreCourseSubscriptionHandler }/>
      }
    </Modal>
  );

}

export default SubscriptionModal;
