import { Slices } from "../../constants/slices";
import KPIInitialState from "./state";

import {
  getGlobalForecastCashFlow,
  getGlobalRealCashFlow,
  getForecastCashFlow,
  getRealCashFlow,
  getCourseSubscriptionEnrollments,
  getCourseSubscriptionTypes,
  getHowKnowAboutSchoolRanks
} from "./thunk";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.KPI,
  initialState: KPIInitialState,
  reducers: {},
  extraReducers: {
    [getGlobalForecastCashFlow.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getGlobalForecastCashFlow.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      globalForecastCashFlow: [ ...action.payload ],
      error: null
    }),
    [getGlobalForecastCashFlow.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getGlobalRealCashFlow.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getGlobalRealCashFlow.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      globalRealCashFlow: [ ...action.payload ],
      error: null
    }),
    [getGlobalRealCashFlow.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getForecastCashFlow.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getForecastCashFlow.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      forecastCashFlow: action.payload,
      error: null
    }),
    [getForecastCashFlow.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getRealCashFlow.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getRealCashFlow.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      realCashFlow: action.payload,
      error: null
    }),
    [getRealCashFlow.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getCourseSubscriptionEnrollments.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getCourseSubscriptionEnrollments.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      courseSubscriptionEnrollments: [ ...action.payload ],
      error: null
    }),
    [getCourseSubscriptionEnrollments.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getCourseSubscriptionTypes.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getCourseSubscriptionTypes.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      courseSubscriptionTypes: [ ...action.payload ],
      error: null
    }),
    [getCourseSubscriptionTypes.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [getHowKnowAboutSchoolRanks.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [getHowKnowAboutSchoolRanks.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      howKnowAboutSchoolRanks: [ ...action.payload ],
      error: null
    }),
    [getHowKnowAboutSchoolRanks.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    })
  }
});

export default slice.reducer;
