import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import CourseTypeModalDelete from "./CourseTypeModalDelete";
import CourseTypeModalRestore from "./CourseTypeModalRestore";
import CourseTypeModalAdd from "./CourseTypeModalAdd";
import useCoursesTypes from "../../../hooks/useCoursesTypes";
import { closeCoursesTypeModal } from "../../../store/coursesType/actions";
import { addCoursesType, deleteCoursesType, editCoursesType, restoreCoursesType } from "../../../store/coursesType/thunk";
import CourseTypeModalEdit from "./CourseTypeModalEdit";

const CourseTypeModal = () => {

  const dispatch = useDispatch();
  const { selectedType, showCoursesTypeModal, modalType } = useCoursesTypes();

  const closeModalHandler = () => dispatch(closeCoursesTypeModal());

  const addCourseTypeHandler = ({ type }) => dispatch(addCoursesType({ type }));
  const editCourseTypeHandler = (type) => dispatch(editCoursesType({ id: selectedType.id, type }));
  const deleteCourseTypeHandler = () => dispatch(deleteCoursesType(selectedType.id));
  const restoreCourseTypeHandler = () => dispatch(restoreCoursesType(selectedType.id));

  return (
    <Modal
      size="lg"
      centered
      show={ showCoursesTypeModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.CoursesType.ADD &&
        <CourseTypeModalAdd
          onClose={ closeModalHandler }
          onAdd={ addCourseTypeHandler }/>
      }
      {
        modalType && modalType === Modals.CoursesType.EDIT &&
        <CourseTypeModalEdit
          type={ selectedType }
          onClose={ closeModalHandler }
          onEdit={ editCourseTypeHandler }/>
      }
      {
        modalType && modalType === Modals.CoursesType.DELETE &&
        <CourseTypeModalDelete
          type={ selectedType }
          onClose={ closeModalHandler }
          onDelete={ deleteCourseTypeHandler }/>
      }
      {
        modalType && modalType === Modals.CoursesType.RESTORE &&
        <CourseTypeModalRestore
          type={ selectedType }
          onClose={ closeModalHandler }
          onRestore={ restoreCourseTypeHandler }/>
      }
    </Modal>
  );

}

export default CourseTypeModal;
