export const Routes = {
  ALL: '*',
  LOGIN: '/',
  DASHBOARD: '/dashboard',
  COURSES: '/courses',
  STUDENTS: '/students',
  SUBSCRIPTIONS: '/subscriptions',
  OPERATORS: '/operators',
  EXPORTS: '/exports'
};
