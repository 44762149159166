const { createSelector } = require("@reduxjs/toolkit");

const courseTitlesStore = (state) => state.courseTitles;

export const courseTitlesLoadingSelector = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.loading
);

export const courseTitlesErrorSelector = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.error
);

export const courseTitlesListSelector = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.titles
);

export const selectedCourseTitleSelector = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.selectedCourseTitle
);

export const courseTitleModalSelector = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.showModal
);

export const courseTitleModalType = createSelector(
  courseTitlesStore,
  (courseTitlesState) => courseTitlesState.modalType
);
