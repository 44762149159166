const { createSelector } = require("@reduxjs/toolkit");

const OperatorsStore = (state) => state.operators;

export const operatorsLoadingSelector = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.loading
);

export const operatorsErrorSelector = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.error
);

export const operatorsListSelector = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.operators
);

export const selectedOperatorSelector = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.selectedOperator
);

export const operatorModalSelector = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.showModal
);

export const operatorModalType = createSelector(
  OperatorsStore,
  (operatorsState) => operatorsState.modalType
);
