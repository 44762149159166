import classes from './OperatorListItem.module.scss';
import { Button, ButtonGroup, ButtonToolbar, ListGroup } from "react-bootstrap";
import { FEMALE_OPERATOR_DEFAULT_AVATAR, MALE_OPERATOR_DEFAULT_AVATAR } from "../../constants/ui";
import { faCheck, faExclamation, faMinusCircle, faPencilAlt, faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";

const OperatorListItem = ({
  id,
  nome,
  cognome,
  sesso,
  email,
  ruolo,
  stato,
  deleted,
  avatar,
  onEdit,
  onChangeStatus,
  onDelete,
  onRestore
}) => {

  const editHandler = () => onEdit({ id, nome, cognome, sesso, email, ruolo });
  // const changeStatusHandler = () => onChangeStatus({ id, nome, cognome, sesso, email, ruolo });
  const deleteHandler = () => onDelete({ id, nome, cognome, sesso, email, ruolo });
  const restoreHandler = () => onRestore({ id, nome, cognome, sesso, email, ruolo });

  return (
    <ListGroup.Item className={ classes['operator-list-item'] }>
      <h4 className="text-muted">#{ id }</h4>
      <div className={ classes['avatar-box'] } style={ {
        backgroundImage: `url(${ avatar
          ? avatar
          : sesso === 'M'
            ? MALE_OPERATOR_DEFAULT_AVATAR
            : FEMALE_OPERATOR_DEFAULT_AVATAR })`
      } }/>
      <div className={ classes['title-box'] }>
        <h5>{ nome } { cognome }</h5>
        <h6 className="text-muted">{ ruolo === 'admin' ? 'Amministratore' : 'Operatore' }</h6>
        <div className={ classes['status-box'] }>
          <FontAwesomeIcon
            icon={
              deleted === 1
                ? faMinusCircle
                : stato === 'disabilitato'
                ? faExclamation
                : faCheck
            }
            size="1x"
            className={
              deleted === 1
                ? 'text-danger'
                : stato === 'disabilitato'
                ? 'text-warning'
                : 'text-success'
            }/>
          { !deleted && stato === 'abilitato' && <span className={ `${ classes['status-text'] }` }>Utenza attiva</span> }
          { !deleted && stato === 'disabilitato' && <span className={ `${ classes['status-text'] } text-` }>Utenza disattivata</span> }
          { deleted === 1 && <span className={ `${ classes['status-text'] } text-` }>Utenza cancellata</span> }
        </div>
      </div>
      <div className={ classes['actions-box'] }>
        <ButtonToolbar>
          <ButtonGroup className={ classes['button-container'] }>
            <Button variant="link" onClick={ editHandler } disabled={ deleted === 1 }>
              <FontAwesomeIcon icon={ faPencilAlt } size="1x"/> Modifica
            </Button>
          </ButtonGroup>
          {/*<ButtonGroup className={ classes['button-container'] }>*/}
          {/*  <Button variant="link" onClick={ changeStatusHandler } disabled={ deleted === 1 }>*/}
          {/*    <FontAwesomeIcon icon={ faHandPaper } size="1x"/> { stato === 'abilitato' ? 'Disattiva' : 'Attiva' }*/}
          {/*  </Button>*/}
          {/*</ButtonGroup>*/}
          <ButtonGroup className={ classes['button-container'] }>
            <Button variant="link" onClick={ deleteHandler } disabled={ deleted === 1 }>
              <FontAwesomeIcon icon={ faTrashAlt } size="1x"/> Cancella
            </Button>
          </ButtonGroup>
          <ButtonGroup className={ classes['button-container'] }>
            <Button variant="link" onClick={ restoreHandler } disabled={ deleted === 0 }>
              <FontAwesomeIcon icon={ faTrashRestore } size="1x"/> Recupera
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    </ListGroup.Item>
  );

}

export default OperatorListItem;
