const { createSelector } = require("@reduxjs/toolkit");

const locationsStore = (state) => state.locations;

export const locationsLoadingSelector = createSelector(
  locationsStore,
  (locationsState) => locationsState.loading
);

export const locationsErrorSelector = createSelector(
  locationsStore,
  (locationsState) => locationsState.error
);

export const locationsListSelector = createSelector(
  locationsStore,
  (locationsState) => locationsState.locations
);

export const selectedLocationSelector = createSelector(
  locationsStore,
  (locationsState) => locationsState.selectedLocation
);

export const locationModalSelector = createSelector(
  locationsStore,
  (locationsState) => locationsState.showModal
);

export const courseModalType = createSelector(
  locationsStore,
  (locationsState) => locationsState.modalType
);
