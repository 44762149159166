import { useSelector } from "react-redux";
import {
  courseSubscriptionsListSelector,
  courseSubscriptionsLoadingSelector,
  courseSubscriptionsModalSelector,
  courseSubscriptionsModalType,
  selectedCourseSubscriptionSelector
} from "../store/courseSubscriptions/selectors";

const useCourseSubscriptions = () => {

  const courseSubscriptionColumns = [
    { id: 'id', name: 'ID' },
    { id: 'codice_contratto', name: 'Codice contratto' },
    { id: 'titolo', name: 'Corso' },
    { id: 'studente', name: 'Studente' },
    { id: 'turno', name: 'Turno' },
    { id: 'data_inizio', name: 'Data inizio' },
    { id: 'data_iscrizione', name: 'Data iscrizione' },
    { id: 'operatore', name: 'Operatore' },
    { id: 'codice_bsa', name: 'Codice BSA' },
    { id: 'deleted', name: 'Stato' },
    { id: 'id_studente', name: null },
    { id: 'id_corso', name: null },
    { id: 'id_operatore', name: null },
    { id: 'tipologia', name: null },
    { id: 'frequenza', name: null },
    { id: 'costo', name: null },
    { id: 'acconto', name: null },
    { id: 'data_scadenza_acconto', name: null },
    { id: 'numero_rate', name: null },
    { id: 'kit', name: null },
    { id: 'codice_regionale', name: null },
    { id: 'note', name: null },
    { id: 'note_contratto', name: null },
    { id: 'questionario', name: null },
    { id: 'ore', name: null },
    { id: 'rate', name: null },
    { id: 'rimanenza_saldo', name: null },
    { id: 'tipologia_iscrizione', name: null },
    { id: 'tipologia_pagamento', name: null },
    { id: 'actions', name: 'Azioni disponibili' },
  ];

  const courseSubscriptionsList = useSelector(courseSubscriptionsListSelector);
  const selectedCourseSubscription = useSelector(selectedCourseSubscriptionSelector);
  const showCourseSubscriptionsModal = useSelector(courseSubscriptionsModalSelector);
  const modalType = useSelector(courseSubscriptionsModalType);
  const isLoading = useSelector(courseSubscriptionsLoadingSelector);

  return {
    courseSubscriptionColumns,
    courseSubscriptionsList,
    selectedCourseSubscription,
    showCourseSubscriptionsModal,
    modalType,
    isLoading
  };

};

export default useCourseSubscriptions;
