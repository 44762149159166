import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const CourseTitleModalAdd = ({ onClose, onAdd }) => {

  const [ state, setState ] = useState({
    value: '',
    valid: false,
    touched: false
  });

  const validateTitle = (value) => value !== null && value.trim().length > 0;

  const titleHandler = (e) => setState((state) => ({
    ...state,
    value: e.target.value,
    valid: validateTitle(e.target.value),
    touched: true
  }));

  const addTitleHandler = (e) => {
    e.preventDefault();
    if (state.valid) {
      console.log('state.title', state.title);
      onAdd(state.value);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Creazione nuovo titolo corso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ addTitleHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseTitle">
                <Form.Label>Titolo del corso</Form.Label>
                <Form.Control
                  onChange={ titleHandler }
                  onBlur={ titleHandler }
                  value={ state.value }
                  type="text"
                  placeholder="Inserisci il titolo del corso"/>
                { !state.value && state.touched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ addTitleHandler }>Inserisci titolo corso</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTitleModalAdd;
