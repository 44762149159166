import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import ViewTitle from "../../ViewTitle/ViewTitle";

const StudentModalView = ({ student, onClose }) => {

  const [ name ] = useState(student && student.nome ? student.nome : '');
  const [ surname ] = useState(student && student.cognome ? student.cognome : '');
  const [ sex ] = useState(student && student.sesso ? student.sesso : 0);
  const [ taxCode ] = useState(student && student.codice_fiscale ? student.codice_fiscale : '');
  const [ qualificationTitle ] = useState(student && student.titolo_studio ? student.titolo_studio : 0);
  const [ qualificationYear ] = useState(student && student.anno_conseguimento ? student.anno_conseguimento : '');
  const [ documentType ] = useState(student && student.tipo_documento ? student.tipo_documento : 0);
  const [ documentNumber ] = useState(student && student.numero_documento ? student.numero_documento : '');
  const [ birthday, setBirthday ] = useState(student && student.data_nascita ? student.data_nascita.split('T')[0] : '');
  const [ citizenship, setCitizenship ] = useState(student && student.cittadinanza ? student.cittadinanza : '');
  const [ birthCountry ] = useState(student && student.provincia_nascita ? student.provincia_nascita : '');
  const [ birthNation ] = useState(student && student.nazione_nascita ? student.nazione_nascita : '');
  const [ nationality ] = useState(student && student.nazionalita ? student.nazionalita : 0);
  const [ residencePermitNumber, setResidencePermitNumber ] = useState(student && student.numero_permesso_soggiorno ? student.numero_permesso_soggiorno : '');
  const [ residencePermitExpiration, setResidencePermitExpiration ] = useState(student && student.scadenza_permesso_soggiorno ? student.scadenza_permesso_soggiorno.split('T')[0] : '');
  const [ residenceCity ] = useState(student && student.citta_residenza ? student.citta_residenza : '');
  const [ residenceCountry ] = useState(student && student.provincia_residenza ? student.provincia_residenza : '');
  const [ residenceAddress ] = useState(student && student.indirizzo_residenza ? student.indirizzo_residenza : '');
  const [ residencePostalCode ] = useState(student && student.cap_residenza ? student.cap_residenza : '');
  const [ cellular ] = useState(student && student.cellulare ? student.cellulare : '');
  const [ phone ] = useState(student && student.telefono ? student.telefono : '');
  const [ email ] = useState(student && student.email ? student.email : '');
  const [ fadEmail ] = useState(student && student.email_fad ? student.email_fad : '');
  const [ notes ] = useState(student && student.note ? student.note : '');

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Scheda studente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>

          <Row>
            <Col sm="12">
              <ViewTitle title="Generalità" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formName">
                <Form.Label>Nome</Form.Label>
                <Form.Control disabled
                  value={ name }
                  type="text"
                  required/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formSurname">
                <Form.Label>Cognome</Form.Label>
                <Form.Control disabled
                  value={ surname }
                  type="text"
                  required/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formSex">
                <Form.Label>Sesso</Form.Label>
                <Form.Control disabled
                  as="select"
                  value={ sex }
                  required>
                  <option disabled value={ 0 }>Seleziona il sesso</option>
                  <option value="M">Uomo</option>
                  <option value="F">Donna</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Codice Fiscale</Form.Label>
                <Form.Control disabled
                  value={ taxCode }
                  type="text"
                  required/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Titolo di studio</Form.Label>
                <Form.Control disabled
                  as="select"
                  value={ qualificationTitle }>
                  <option disabled value={ 0 }>Seleziona il titolo</option>
                  <option value="Licenza Media">Licenza Media</option>
                  <option value="Attestato Professionale">Attestato Professionale</option>
                  <option value="Diploma Superiore">Diploma Superiore</option>
                  <option value="Laurea">Laurea</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Anno conseguimento</Form.Label>
                <Form.Control disabled
                  value={ qualificationYear }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Tipo documento</Form.Label>
                <Form.Control disabled
                  as="select"
                  value={ documentType }>
                  <option disabled value={ 0 }>Seleziona il documento</option>
                  <option value="Carta di Identità">Carta di Identità</option>
                  <option value="Patente">Patente</option>
                  <option value="Passaporto">Passaporto</option>
                  <option value="Permesso di soggiorno">Permesso di soggiorno</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formTaxCode">
                <Form.Label>Numero documento</Form.Label>
                <Form.Control disabled
                  value={ documentNumber }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCitizenship">
                <Form.Label>Cittadinanza</Form.Label>
                <Form.Control disabled
                  type="text"
                  value={ citizenship }/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Comunitario o extracomunitario?</Form.Label>
                <Form.Control disabled
                  as="select"
                  value={ nationality }>
                  <option disabled value={ 0 }>Seleziona la nazionalità</option>
                  <option value="Comunitaria">Comunitaria</option>
                  <option value="Extracomunitaria">Extracomunitaria</option>
                </Form.Control>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formResidencePermitNumber">
                <Form.Label>Permesso di soggiorno N° <small>(solo extracomunitari)</small></Form.Label>
                <Form.Control disabled
                  type="text"
                  value={ residencePermitNumber }/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formResidencePermitExpiration">
                <Form.Label>Scadenza permesso <small>(solo extracomunitari)</small></Form.Label>
                <Form.Control disabled
                  type="date"
                  value={ residencePermitExpiration }/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Natalità" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formBirthday">
                <Form.Label>Data di nascita</Form.Label>
                <Form.Control disabled
                  value={ birthday }
                  type="date"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Provincia</Form.Label>
                <Form.Control disabled
                  value={ birthCountry }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Nazione</Form.Label>
                <Form.Control disabled
                  value={ birthNation }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Nazionalità</Form.Label>
                <Form.Control disabled
                  as="select"
                  value={ nationality }>
                  <option disabled value={ 0 }>Seleziona la nazionalità</option>
                  <option value="Comunitaria">Comunitaria</option>
                  <option value="Extracomunitaria">Extracomunitaria</option>
                </Form.Control>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Residenza" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>Città</Form.Label>
                <Form.Control disabled
                  value={ residenceCity }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="2">
              <Form.Group controlId="formEmail">
                <Form.Label>Provincia</Form.Label>
                <Form.Control disabled
                  value={ residenceCountry }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="4">
              <Form.Group controlId="formEmail">
                <Form.Label>Indirizzo</Form.Label>
                <Form.Control disabled
                  value={ residenceAddress }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="3">
              <Form.Group controlId="formEmail">
                <Form.Label>CAP</Form.Label>
                <Form.Control disabled
                  value={ residencePostalCode }
                  type="text"/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Contatti" size="h4"/>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCellular">
                <Form.Label>Cellulare</Form.Label>
                <Form.Control disabled
                  value={ cellular }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formCellular">
                <Form.Label>Telefono</Form.Label>
                <Form.Control disabled
                  value={ phone }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmail">
                <Form.Label>Email personale</Form.Label>
                <Form.Control disabled
                  value={ email }
                  type="text"/>
              </Form.Group>
            </Col>
            <Col sm="12" md="6" lg="6">
              <Form.Group controlId="formEmailFad">
                <Form.Label>Email FAD</Form.Label>
                <Form.Control disabled
                  value={ fadEmail }
                  type="text"/>
              </Form.Group>
            </Col>
          </Row>

          <br/>

          <Row>
            <Col sm="12">
              <ViewTitle title="Note" size="h4"/>
            </Col>
            <Col sm="12" md="12" lg="12">
              <Form.Group controlId="formCellular">
                <Form.Control disabled
                  as="textarea"
                  style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                  value={ notes }
                  type="text"/>
              </Form.Group>
            </Col>
          </Row>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }>Chiudi</Button>
      </Modal.Footer>
    </>
  );

}

export default StudentModalView;
