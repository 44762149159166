import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import { setAuthInterceptor } from "./utils/http";

setAuthInterceptor();

ReactDOM.render(
  /**
   * StrictMode disabled due to temporary solve a react-bootstrap bug
   * Issue ref: https://github.com/react-bootstrap/react-bootstrap/issues/5075
   */
  // <React.StrictMode>
    <Provider store={ store }>
      <HashRouter>
        <App/>
      </HashRouter>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
