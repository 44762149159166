import { Slices } from "../../constants/slices";
import LocationsInitialState from "./state";
import { addLocation, deleteLocation, editLocation, restoreLocation, retrieveLocationsList } from "./thunk";
import { closeLocationModal, selectLocation } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.LOCATIONS,
  initialState: LocationsInitialState,
  reducers: {},
  extraReducers: {
    // retrieve locations list
    [retrieveLocationsList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveLocationsList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      locations: [ ...action.payload ],
      error: null
    }),
    [retrieveLocationsList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new location
    [addLocation.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addLocation.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      locations: [ action.payload, ...state.locations ],
      error: null,
      selectedLocation: null,
      showModal: false
    }),
    [addLocation.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit location
    [editLocation.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editLocation.fulfilled.type]: (state, action) => {
      const index = state.locations.findIndex((el) => el.id === action.payload.id);
      const locations = [ ...state.locations ];
      locations[index] = action.payload;
      return {
        ...state,
        loading: false,
        locations: [ ...locations ],
        error: null,
        selectedLocation: null,
        showModal: false
      };
    },
    [editLocation.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete location
    [deleteLocation.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteLocation.fulfilled.type]: (state, action) => {
      const index = state.locations.findIndex((el) => el.id === action.payload);
      const locations = [ ...state.locations ];
      locations[index] = { ...locations[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        locations: [ ...locations ],
        error: null,
        selectedLocation: null,
        showModal: false
      };
    },
    [deleteLocation.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore location
    [restoreLocation.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreLocation.fulfilled.type]: (state, action) => {
      const index = state.locations.findIndex((el) => el.id === action.payload);
      const locations = [ ...state.locations ];
      locations[index] = { ...locations[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        locations: [ ...locations ],
        error: null,
        selectedLocation: null,
        showModal: false
      };
    },
    [restoreLocation.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific location reference
    [selectLocation]: (state, action) => ({
      ...state,
      selectedLocation: action.payload.location,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeLocationModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedLocation: null,
      modalType: null
    })
  }
});

export default slice.reducer;
