import { Col, ListGroup, Row } from "react-bootstrap";
import OperatorListItem from "../OperatorListItem/OperatorListItem";

const OperatorsList = ({ operators, onEdit, onChangeStatus, onDelete, onRestore }) => {

  return (
    <Row>
      <Col>
        <ListGroup>
          {
            operators && operators.map((operator) => (
              <OperatorListItem
                key={ operator.id }
                id={ operator.id }
                nome={ operator.nome }
                cognome={ operator.cognome }
                sesso={ operator.sesso }
                email={ operator.email }
                ruolo={ operator.ruolo }
                stato={ operator.stato }
                deleted={ operator.deleted }
                avatar={ operator.avatar }
                onEdit={ onEdit }
                onChangeStatus={ onChangeStatus }
                onDelete={ onDelete }
                onRestore={ onRestore }/>
            ))
          }
        </ListGroup>
      </Col>
    </Row>
  );

}

export default OperatorsList;
