import { Slices } from "../../constants/slices";
import CoursesInitialState from "./state";

import { closeCoursesModal, selectCourse } from "./actions";
import { addCourse, deleteCourse, editCourse, restoreCourse, retrieveCoursesList, retrieveCoursesTypeList } from "./thunk";
import { textSearchCourses } from "../courseSearch/thunk";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.COURSES,
  initialState: CoursesInitialState,
  reducers: {},
  extraReducers: {
    // retrieve courses list
    [retrieveCoursesList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveCoursesList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      courses: [ ...action.payload ],
      error: null
    }),
    [retrieveCoursesList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new course
    [addCourse.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addCourse.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      courses: [ action.payload, ...state.courses ],
      error: null,
      selectedCourse: null,
      showModal: false
    }),
    [addCourse.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit course
    [editCourse.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editCourse.fulfilled.type]: (state, action) => {
      const index = state.courses.findIndex((el) => el.id === action.payload.id);
      const courses = [ ...state.courses ];
      courses[index] = action.payload;
      return {
        ...state,
        loading: false,
        courses: [ ...courses ],
        error: null,
        selectedCourse: null,
        showModal: false
      };
    },
    [editCourse.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete course
    [deleteCourse.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteCourse.fulfilled.type]: (state, action) => {
      const index = state.courses.findIndex((el) => el.id === action.payload);
      const courses = [ ...state.courses ];
      courses[index] = { ...courses[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        courses: [ ...courses ],
        error: null,
        selectedCourse: null,
        showModal: false
      };
    },
    [deleteCourse.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore course
    [restoreCourse.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreCourse.fulfilled.type]: (state, action) => {
      const index = state.courses.findIndex((el) => el.id === action.payload);
      const courses = [ ...state.courses ];
      courses[index] = { ...courses[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        courses: [ ...courses ],
        error: null,
        selectedCourse: null,
        showModal: false
      };
    },
    [restoreCourse.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific course reference
    [selectCourse]: (state, action) => ({
      ...state,
      selectedCourse: action.payload.course,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeCoursesModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedCourse: null,
      modalType: null
    }),
    // retrieve courses type list
    [retrieveCoursesTypeList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveCoursesTypeList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      types: [ ...action.payload ],
      error: null
    }),
    [retrieveCoursesTypeList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [textSearchCourses.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [textSearchCourses.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      courses: [ ...action.payload ],
      error: null
    }),
    [textSearchCourses.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    })
  }
});

export default slice.reducer;
