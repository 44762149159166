const CoursesInitialState = {
  courses: [],
  loading: false,
  error: null,
  selectedCourse: null,
  showModal: false,
  modalType: null
};

export default CoursesInitialState;
