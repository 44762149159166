import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";
import useCoursesTypes from "../../../hooks/useCoursesTypes";
import useLocations from "../../../hooks/useLocations";
import useCourseTurns from "../../../hooks/useCourseTurns";
import useCourseTitles from "../../../hooks/useCourseTitles";
import ViewTitle from "../../ViewTitle/ViewTitle";

const CourseModalEdit = ({ course, onClose, onEdit }) => {

  const { coursesTypeList } = useCoursesTypes();
  const { turnsList } = useCourseTurns();
  const { locationsList } = useLocations();
  const { courseTitlesList } = useCourseTitles();

  // const validateTitle = (value) => value && typeof value === 'string' && value.trim().length > 5;
  const validateDuration = (value) => !isNaN(value);
  const validateSelect = (value) => value && value !== 0;

  const [ state, setState ] = useState({
    title: course && course.titolo ? course.id_titolo : 0,
    duration: course && course.ore ? course.ore : '',
    type: course && course.id_tipologia ? course.id_tipologia : 0,
    turn: course && course.id_turno ? course.id_turno : 0,
    location: course && course.id_sede ? course.id_sede : 0,
    regionalCode: course && course.codice_regionale ? course.codice_regionale : '',
    bsaCode: course && course.codice_bsa ? course.codice_bsa : '',
    frequency: course && course.frequenza ? course.frequenza : '',
    protocol: course && course.protocollo_apertura_corso ? course.protocollo_apertura_corso : '',
    teachers: course && course.docenti ? course.docenti : '',
    startingDate: course && course.data_inizio ? course.data_inizio.split('T')[0] : '',
    endingDate: course && course.data_fine ? course.data_fine.split('T')[0] : '',
    examDate: course && course.data_esame ? course.data_esame.split('T')[0] : '',
    secondExamDate: course && course.data_esame_2 ? course.data_esame_2.split('T')[0] : '',
    startingSubscribers: course && course.numero_iscritti_partenza ? course.numero_iscritti_partenza : 0,
    tenSubscribers: course && course.numero_iscritti_10 ? course.numero_iscritti_10 : 0,
    abandon: course && course.abbandoni ? course.abbandoni : 0,
    allowedToExam: course && course.numero_ammessi_esame ? course.numero_ammessi_esame : 0,
    completedMale: course && course.completed_male ? course.completed_male : 0,
    completedFemale: course && course.completed_female ? course.completed_female : 0,
    fadSync: course && course.fad_sync ? course.fad_sync : 0,
    fadAsync: course && course.fad_async ? course.fad_async : 0,
    notes: course && course.note ? course.note : '',
    titleIsValid: validateSelect(course && course.id_titolo ? course.id_titolo : 0),
    durationIsValid: validateDuration(course && course.ore ? course.ore : ''),
    typeIsValid: validateSelect(course && course.id_tipologia ? course.id_tipologia : 0),
    turnIsValid: validateSelect(course && course.id_turno ? course.id_turno : 0),
    locationIsValid: validateSelect(course && course.id_sede ? course.id_sede : 0),
    titleIsTouched: true,
    durationIsTouched: true,
    typeIsTouched: true,
    turnIsTouched: true,
    locationIsTouched: true
  });

  const formIsValid = state.typeIsValid && state.locationIsValid && state.titleIsValid && state.durationIsValid && state.turnIsValid;

  const titleHandler = (e) => setState((state) => ({ ...state, title: e.target.value }));
  const durationHandler = (e) => setState((state) => ({ ...state, duration: e.target.value }));
  const typeHandler = (e) => setState((state) => ({ ...state, type: e.target.value }));
  const turnHandler = (e) => setState((state) => ({ ...state, turn: e.target.value }));
  const locationHandler = (e) => setState((state) => ({ ...state, location: e.target.value }));
  const regionalCodeHandler = (e) => setState((state) => ({ ...state, regionalCode: e.target.value }));
  const bsaCodeHandler = (e) => setState((state) => ({ ...state, bsaCode: e.target.value }));
  const frequencyHandler = (e) => setState((state) => ({ ...state, frequency: e.target.value }));
  const protocolHandler = (e) => setState((state) => ({ ...state, protocol: e.target.value }));
  const teachersHandler = (e) => setState((state) => ({ ...state, teachers: e.target.value }));
  const startingDateHandler = (e) => setState((state) => ({ ...state, startingDate: e.target.value }));
  const endingDateHandler = (e) => setState((state) => ({ ...state, endingDate: e.target.value }));
  const examDateHandler = (e) => setState((state) => ({ ...state, examDate: e.target.value }));
  const secondExamDateHandler = (e) => setState((state) => ({ ...state, secondExamDate: e.target.value }));
  const startingSubscribersHandler = (e) => setState((state) => ({ ...state, startingSubscribers: e.target.value }));
  const tenSubscribersHandler = (e) => setState((state) => ({ ...state, tenSubscribers: e.target.value }));
  const abandonHandler = (e) => setState((state) => ({ ...state, abandon: e.target.value }));
  const allowedToExamHandler = (e) => setState((state) => ({ ...state, allowedToExam: e.target.value }));
  const completedMaleHandler = (e) => setState((state) => ({ ...state, completedMale: e.target.value }));
  const completedFemaleHandler = (e) => setState((state) => ({ ...state, completedFemale: e.target.value }));
  const fadSyncHandler = (e) => setState((state) => ({ ...state, fadSync: e.target.value }));
  const fadAsyncHandler = (e) => setState((state) => ({ ...state, fadAsync: e.target.value }));
  const notesHandler = (e) => setState((state) => ({ ...state, notes: e.target.value }));

  const titleBlurHandler = (e) => {
    setState((state) => ({
      ...state,
      titleIsTouched: true,
      titleIsValid: validateSelect(e.target.value)
    }));
  };
  const durationBlurHandler = (e) => {
    setState((state) => ({
      ...state,
      durationIsTouched: true,
      durationIsValid: validateDuration(e.target.value)
    }));
  };
  const typeBlurHandler = (e) => {
    console.log(e);
    setState((state) => ({
      ...state,
      typeIsTouched: true,
      typeIsValid: validateSelect(e.target.value)
    }));
  };
  const turnBlurHandler = (e) => {
    setState((state) => ({
      ...state,
      turnIsTouched: true,
      turnIsValid: validateSelect(e.target.value)
    }));
  };
  const locationBlurHandler = (e) => {
    setState((state) => ({
      ...state,
      locationIsTouched: true,
      locationIsValid: validateSelect(e.target.value)
    }));
  };

  const editCourseHandler = (e) => {
    e.preventDefault();
    if (formIsValid) {
      onEdit({ ...state });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica corso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editCourseHandler }>

          <Row>
            <Col sm="12">
              <ViewTitle title="Scelta del corso" size="h4"/>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseType">
                <Form.Label>Categoria del corso</Form.Label>
                <Form.Control
                  as="select"
                  value={ state.type }
                  onChange={ typeHandler }
                  onBlur={ typeBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona la categoria</option>
                  {
                    coursesTypeList && coursesTypeList
                      .filter((c) => c.deleted === 0)
                      .map((c) => <option key={ c.id } value={ c.id }>{ c.tipologia }</option>)
                  }
                </Form.Control>
                { !state.type && state.typeIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
            <Col lg="5">
              <Form.Group controlId="formCourseTitle">
                <Form.Label>Titolo del corso</Form.Label>
                <Form.Control
                  as="select"
                  onChange={ titleHandler }
                  onBlur={ titleBlurHandler }
                  value={ state.title }
                  required>
                  <option disabled value={ 0 }>Seleziona il titolo</option>
                  {
                    courseTitlesList && courseTitlesList
                      .filter((t) => t.deleted === 0)
                      .sort((a, b) => a.titolo > b.titolo ? 1 : -1)
                      .map((t) => <option key={ t.id } value={ t.id }>{ t.titolo }</option>)
                  }
                </Form.Control>
                { !state.title && state.titleIsTouched && <small className="text-danger">Titolo non valido</small> }
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group controlId="formCourseDuration">
                <Form.Label>Ore del corso</Form.Label>
                <Form.Control
                  onChange={ durationHandler }
                  onBlur={ durationBlurHandler }
                  value={ state.duration }
                  type="number"
                  placeholder="Inserisci le ore del corso"
                  required/>
                { !state.duration && state.durationIsTouched && <small className="text-danger">Durata non valida</small> }
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <ViewTitle title="Turno, Inizio, Sede e Frequenza" size="h4"/>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseTurns">
                <Form.Label>Turno del corso</Form.Label>
                <Form.Control
                  as="select"
                  value={ state.turn }
                  onChange={ turnHandler }
                  onBlur={ turnBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona il turno</option>
                  {
                    turnsList && turnsList
                      .filter((t) => t.deleted === 0)
                      .map((t) => <option key={ t.id } value={ t.id }>{ t.turno }</option>)
                  }
                </Form.Control>
                { !state.turn && state.turnIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formStartingDate">
                <Form.Label>Data di inizio</Form.Label>
                <Form.Control
                  onChange={ startingDateHandler }
                  value={ state.startingDate }
                  type="date"
                  placeholder="Inserisci la data di partenza"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseLocation">
                <Form.Label>Sede di svolgimento</Form.Label>
                <Form.Control
                  as="select"
                  value={ state.location }
                  onChange={ locationHandler }
                  onBlur={ locationBlurHandler }
                  required>
                  <option disabled value={ 0 }>Seleziona la sede</option>
                  {
                    locationsList && locationsList
                      .filter((c) => c.deleted === 0)
                      .map((c) => <option key={ c.id } value={ c.id }>{ c.sede }</option>)
                  }
                </Form.Control>
                { !state.location && state.locationIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
            <Col sm="12" md="12" lg="12">
              <Form.Group controlId="formCourseFrequency">
                <Form.Label>Frequenza corso</Form.Label>
                <Form.Control
                  onChange={ frequencyHandler }
                  value={ state.frequency }
                  type="text"
                  placeholder="Inserisci la frequenza del corso"/>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <ViewTitle title="Codici del corso" size="h4"/>
            </Col>
            <Col>
              <Form.Group controlId="formCourseRegionalCode">
                <Form.Label>Codice corso regionale</Form.Label>
                <Form.Control
                  onChange={ regionalCodeHandler }
                  value={ state.regionalCode }
                  type="text"
                  placeholder="Inserisci il codice regionale corso"/>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group controlId="formCourseBsaCode">
                <Form.Label>Codice corso BSA</Form.Label>
                <Form.Control
                  onChange={ bsaCodeHandler }
                  value={ state.bsaCode }
                  type="text"
                  placeholder="Inserisci il codice BSA corso"/>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <ViewTitle title="Specifiche del corso" size="h4"/>
            </Col>
            <Col lg="6">
              <Form.Group controlId="formCourseProtocol">
                <Form.Label>Protocollo apertura corso</Form.Label>
                <Form.Control
                  onChange={ protocolHandler }
                  value={ state.protocol }
                  type="text"
                  placeholder="Inserisci il protocollo di apertura corso"/>
              </Form.Group>
            </Col>
            <Col lg="6">
              <Form.Group controlId="formCourseTeachers">
                <Form.Label>Docenti</Form.Label>
                <Form.Control
                  onChange={ teachersHandler }
                  value={ state.teachers }
                  type="text"
                  placeholder="Inserisci i docenti del corso"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formEndingDate">
                <Form.Label>Data di fine</Form.Label>
                <Form.Control
                  onChange={ endingDateHandler }
                  value={ state.endingDate }
                  type="date"
                  placeholder="Inserisci la data di fine"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formExamDate">
                <Form.Label>Data esame <small>(primo giorno)</small></Form.Label>
                <Form.Control
                  onChange={ examDateHandler }
                  value={ state.examDate }
                  type="date"
                  placeholder="Inserisci la data del primo giorno di esame"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formSecondExamDate">
                <Form.Label>Data esame <small>(secondo giorno)</small></Form.Label>
                <Form.Control
                  onChange={ secondExamDateHandler }
                  value={ state.secondExamDate }
                  type="date"
                  placeholder="Inserisci la data del secondo giorno di esame"/>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group controlId="formCourseFadSync">
                <Form.Label>Ore FAD sincrone</Form.Label>
                <Form.Control
                  onChange={ fadSyncHandler }
                  value={ state.fadSync }
                  type="number"
                  placeholder="Inserisci il totale delle ore FAD sincrone"/>
              </Form.Group>
            </Col>
            <Col lg="3">
              <Form.Group controlId="formCourseFadAsync">
                <Form.Label>Ore FAD asincrone</Form.Label>
                <Form.Control
                  onChange={ fadAsyncHandler }
                  value={ state.fadAsync }
                  type="number"
                  placeholder="Inserisci il totale delle ore FAD asincrone"/>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <ViewTitle title="Statistiche del corso" size="h4"/>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseStartingSubscribers">
                <Form.Label>Iscritti partenza corso</Form.Label>
                <Form.Control
                  onChange={ startingSubscribersHandler }
                  value={ state.startingSubscribers }
                  type="number"
                  placeholder="Inserisci gli iscritti alla partenza del corso"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseTenSubscribers">
                <Form.Label>Iscritti 10% corso</Form.Label>
                <Form.Control
                  onChange={ tenSubscribersHandler }
                  value={ state.tenSubscribers }
                  type="number"
                  placeholder="Inserisci gli iscritti al 10% del corso"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseAbandon">
                <Form.Label>Abbandoni</Form.Label>
                <Form.Control
                  onChange={ abandonHandler }
                  value={ state.abandon }
                  type="number"
                  placeholder="Inserisci il totale degli abbandoni"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseAllowedToExam">
                <Form.Label>Ammessi all'esame</Form.Label>
                <Form.Control
                  onChange={ allowedToExamHandler }
                  value={ state.allowedToExam }
                  type="number"
                  placeholder="Inserisci il totale degli ammessi all'esame"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseCompletedMale">
                <Form.Label>Maschi promossi</Form.Label>
                <Form.Control
                  onChange={ completedMaleHandler }
                  value={ state.completedMale }
                  type="number"
                  placeholder="Inserisci il totale dei maschi promossi"/>
              </Form.Group>
            </Col>
            <Col lg="4">
              <Form.Group controlId="formCourseCompletedFemale">
                <Form.Label>Femmine promosse</Form.Label>
                <Form.Control
                  onChange={ completedFemaleHandler }
                  value={ state.completedFemale }
                  type="number"
                  placeholder="Inserisci il totale delle femmine promosse"/>
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm="12">
              <ViewTitle title="Note" size="h4"/>
            </Col>
            <Col>
              <Form.Group controlId="formNotes">
                <Form.Control
                  as="textarea"
                  style={ { height: '200px', minHeight: '200px', maxHeight: '200px' } }
                  onChange={ notesHandler }
                  value={ state.notes }
                  type="text"
                  placeholder="Inserisci qui eventuali note da allegare alla pratica"/>
              </Form.Group>
            </Col>
          </Row>

        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ editCourseHandler } disabled={ !formIsValid }>Modifica corso</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseModalEdit;
