import ViewTitle from "../../components/ViewTitle/ViewTitle";
import FloatingButton from "../../components/buttons/FloatingButton/FloatingButton";
import { useDispatch } from "react-redux";
import useStudents from "../../hooks/useStudents";
import Table from "../../components/Table/Table";
import { useEffect } from "react";
import { retrieveStudentsList, textSearchStudent } from "../../store/students/thunk";
import { Modals } from "../../constants/modals";
import { selectStudent } from "../../store/students/actions";
import StudentModal from "../../components/modals/students/StudentModal";
import SearchBarCourse from "../../components/searchBars/SearchBarCourse/SearchBarCourse";

const Students = () => {

  const dispatch = useDispatch();

  const { studentsColumns, studentsList } = useStudents();

  const viewStudentHandler = (data) => dispatch(selectStudent({ student: data, type: Modals.Students.VIEW }));
  const addStudentHandler = () => dispatch(selectStudent({ type: Modals.Students.ADD }));
  const editStudentHandler = (data) => dispatch(selectStudent({ student: data, type: Modals.Students.EDIT }));
  const deleteStudentHandler = (data) => dispatch(selectStudent({ student: data, type: Modals.Students.DELETE }));
  const restoreStudentHandler = (data) => dispatch(selectStudent({ student: data, type: Modals.Students.RESTORE }));

  const onStudentSearch = (payload) => dispatch(textSearchStudent(payload.criteria === 'nome'
    ? { nome: payload.value }
    : payload.criteria === 'cognome'
      ? { cognome: payload.value }
      : { codiceFiscale: payload.value }
  ));

  useEffect(() => {
    dispatch(retrieveStudentsList());
  }, [ dispatch ]);

  return (
    <div className="view">
      <ViewTitle
        title="Anagrafica Studenti"/>
      <SearchBarCourse
        options={ [
          { id: 1, value: 'nome', type: 'text', text: 'Nome dello studente' },
          { id: 2, value: 'cognome', type: 'text', text: 'Cognome dello studente' },
          { id: 3, value: 'codice-fiscale', type: 'text', text: 'Codice Fiscale' }
        ] }
        onSearch={ onStudentSearch }/>
      <Table
        columns={ studentsColumns }
        rows={ studentsList }
        onView={ viewStudentHandler }
        onEdit={ editStudentHandler }
        onDelete={ deleteStudentHandler }
        onRestore={ restoreStudentHandler }/>
      <FloatingButton
        onClick={ addStudentHandler }/>
      <StudentModal />
    </div>
  );

}

export default Students;
