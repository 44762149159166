import styled from "styled-components";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import { useEffect, useState } from "react";
import { retrieveCoursesList } from "../../store/courses/thunk";
import { useDispatch } from "react-redux";
import SearchCourseForm from "../../components/forms/SearchCourseForm/SearchCourseForm";
import { retrieveCourseTitlesList } from "../../store/courseTitles/thunk";
import { retrieveTurnsList } from "../../store/courseTurns/thunk";
import { retrieveLocationsList } from "../../store/locations/thunk";
import SearchCourseResults from "../../components/SearchCourseResults/SearchCourseResults";
import useCourseSearch from "../../hooks/useCourseSearch";
import { searchCourses } from "../../store/courseSearch/thunk";

const Exports = () => {

  const dispatch = useDispatch();

  const {
    loading,
    results,
    onStudentExport,
    onStudentCompleteExport,
    onSubscriptionExport,
    onInstallmentsExport,
    onFadExport,
    onDetailsExport
  } = useCourseSearch();

  const [ pristine, setPristine ] = useState(true);

  const searchHandler = (filters) => {
    setPristine(false);
    dispatch(searchCourses(filters));
  };

  useEffect(() => {
    dispatch(retrieveCoursesList());
    dispatch(retrieveLocationsList());
    dispatch(retrieveTurnsList());
    dispatch(retrieveCourseTitlesList());
  }, [ dispatch ]);

  return (
    <div
      className="view">
      <ViewTitle
        title="Esportazione"/>
      <Description>
        <p>Immetti i dati del corso che vuoi utilizzare come criteri di ricerca, poi clicca sul tasto "Avvia ricerca" per recuperare i risultati dal database.</p>
      </Description>
      <SearchCourseForm
        loading={ loading }
        onSubmit={ searchHandler } />
      {
        !pristine && !loading && (
          <ResultCounter>
            <h5>Corsi recuperati: { results.length }</h5>
          </ResultCounter>
        )
      }
      <SearchCourseResults
        pristine={ pristine }
        results={ results }
        onStudentExport={ onStudentExport }
        onStudentCompleteExport={ onStudentCompleteExport }
        onSubscriptionExport={ onSubscriptionExport }
        onInstallmentsExport={ onInstallmentsExport }
        onFadExport={ onFadExport }
        onDetailsExport={ onDetailsExport }/>
    </div>
  );

};

export default Exports;

const Description = styled.div`
  margin-bottom: 30px;
`;

const ResultCounter = styled.div`
  width: 100%;
  border-radius: 15px;
  background-color: #fff;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, .125);
  text-align: center;
  margin-top: 30px;
  
  > * {
    margin-bottom: 0;
  }
`;
