export const BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const endpoints = {
  LOGIN: '/v1/login',
  COURSES: '/v1/corsi',
  COURSES_TYPE: '/v1/tipologie-corsi',
  COURSE_SUBSCRIPTIONS: '/v1/iscrizioni',
  INSTALLMENTS: '/v1/rate',
  COURSE_TURNS: '/v1/turni-corsi',
  COURSE_TITLES: '/v1/titoli-corsi',
  LOCATIONS: '/v1/sedi',
  STUDENTS: '/v1/studenti',
  OPERATORS: '/v1/operatori',
  SEARCH: '/v1/ricerca',
  EXPORTS: '/v1/export',
  KPI: '/v1/kpi'
};
