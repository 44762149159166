import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import TurnModalDelete from "./TurnModalDelete";
import TurnModalRestore from "./TurnModalRestore";
import TurnModalAdd from "./TurnModalAdd";
import TurnModalEdit from "./TurnModalEdit";
import useCourseTurns from "../../../hooks/useCourseTurns";
import { closeTurnsModal } from "../../../store/courseTurns/actions";
import { addTurn, deleteTurn, editTurn, restoreTurn } from "../../../store/courseTurns/thunk";

const TurnModal = () => {

  const dispatch = useDispatch();
  const { selectedTurn, showTurnModal, modalType } = useCourseTurns();

  const closeModalHandler = () => dispatch(closeTurnsModal());

  const addTurnHandler = ({ turn, description }) => dispatch(addTurn({ turn, description }));
  const editTurnHandler = ({ turn, description }) => dispatch(editTurn({ id: selectedTurn.id, turn, description }));
  const deleteTurnHandler = () => dispatch(deleteTurn(selectedTurn.id));
  const restoreTurnHandler = () => dispatch(restoreTurn(selectedTurn.id));

  return (
    <Modal
      size="lg"
      centered
      show={ showTurnModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.CourseTurns.ADD &&
        <TurnModalAdd
          onClose={ closeModalHandler }
          onAdd={ addTurnHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTurns.EDIT &&
        <TurnModalEdit
          selectedTurn={ selectedTurn }
          onClose={ closeModalHandler }
          onEdit={ editTurnHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTurns.DELETE &&
        <TurnModalDelete
          selectedTurn={ selectedTurn }
          onClose={ closeModalHandler }
          onDelete={ deleteTurnHandler }/>
      }
      {
        modalType && modalType === Modals.CourseTurns.RESTORE &&
        <TurnModalRestore
          selectedTurn={ selectedTurn }
          onClose={ closeModalHandler }
          onRestore={ restoreTurnHandler }/>
      }
    </Modal>
  );

}

export default TurnModal;
