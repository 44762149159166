import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const LocationModalEdit = ({ location, onClose, onEdit }) => {

  const validateSelect = (value) => value && value.trim().length > 0;

  const [ locationName, setLocationName ] = useState(location && location.sede ? location.sede : '');
  const [ locationIsTouched, setLocationIsTouched ] = useState(!!locationName);
  const [ locationIsValid, setLocationIsValid ] = useState(validateSelect(locationName));

  const locationHandler = (e) => setLocationName(e.target.value);

  const locationBlurHandler = (e) => {
    setLocationIsTouched(true);
    setLocationIsValid(validateSelect(e.target.value));
  };

  const editCourseHandler = (e) => {
    e.preventDefault();
    if (locationIsValid) {
      onEdit(locationName);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica sede
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editCourseHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseType">
                <Form.Label>Sede</Form.Label>
                  <Form.Control
                    onChange={ locationHandler }
                    onBlur={ locationBlurHandler }
                    value={ locationName }
                    type="text"
                    placeholder="Inserisci il nome della sede"
                    required/>
                  { !locationName && locationIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ editCourseHandler } disabled={ !locationIsValid }>Modifica sede</Button>
      </Modal.Footer>
    </>
  );

}

export default LocationModalEdit;
