// Authentication
export const STORE_USER = 'Auth/Store User';
export const LOGOUT = 'Auth/Logout';

// Courses
export const SELECT_COURSE = 'Courses/Select';
export const CLOSE_COURSES_MODAL = 'Courses/Modal/Close';

// Courses Types
export const SELECT_COURSE_TYPE = 'Courses Types/Select';
export const CLOSE_COURSES_TYPE_MODAL = 'Courses Types/Modal/Close';

// Course Subscriptions
export const SELECT_COURSE_SUBSCRIPTIONS = 'Course Subscription/Select';
export const CLOSE_COURSES_SUBSCRIPTIONS_MODAL = 'Course Subscription/Modal/Close';

// Course Turns
export const SELECT_COURSE_TURNS = 'Course Turns/Select';
export const CLOSE_COURSES_TURNS_MODAL = 'Course Turns/Modal/Close';

// Course Titles
export const SELECT_COURSE_TITLE = 'Course Titles/Select';
export const CLOSE_COURSE_TITLES_MODAL = 'Course Titles/Modal/Close';

// Locations
export const SELECT_LOCATION = 'Locations/Select';
export const CLOSE_LOCATION_MODAL = 'Locations/Modal/Close';

// Operators
export const SELECT_OPERATOR = 'Operators/Select';
export const CLOSE_OPERATORS_MODAL = 'Operators/Modal/Close';

// Students
export const SELECT_STUDENT = 'Students/Select';
export const CLOSE_STUDENTS_MODAL = 'Students/Modal/Close';
