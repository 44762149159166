import { useSelector } from "react-redux";
import { coursesTypeListSelector, courseTypeModalSelector, courseTypeModalType, selectedTypeSelector } from "../store/coursesType/selectors";

const useCoursesTypes = () => {

  const typesColumns = [
    { id: 'id', name: 'ID' },
    { id: 'tipologia', name: 'Categoria' },
    { id: 'deleted', name: 'Stato' },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const coursesTypeList = useSelector(coursesTypeListSelector);
  const selectedType = useSelector(selectedTypeSelector);
  const showCoursesTypeModal = useSelector(courseTypeModalSelector);
  const modalType = useSelector(courseTypeModalType);

  return {
    typesColumns,
    coursesTypeList,
    selectedType,
    showCoursesTypeModal,
    modalType
  };

};

export default useCoursesTypes;
