import classes from './TableCell.module.scss';

const TableCell = ({ column, value, hidden }) => {

  const renderSwitch = () => {
    switch (column) {
      case 'deleted': {
        return value === 0 ? '🟢️' : '🔴';
      }
      case 'inizio_corso':
      case 'data_inizio':
      case 'data_iscrizione':
      case 'data_nascita': {
        return value
          ? new Intl.DateTimeFormat('it-IT', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            timeZone: 'Europe/Rome'
          }).format(new Date(value))
          : '';
      }
      default: {
        return Array.isArray(value)
          ? null
          : typeof value === 'object'
            ? null
            : value;
      }
    }
  };

  return (
    <td title={ renderSwitch() } hidden={ hidden } className={ `${ classes.cell } ${ column === 'deleted' ? 'text-center' : '' }` }>
      { renderSwitch() }
    </td>
  );

}

export default TableCell;
