import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveStudentsList = createAsyncThunk('Students/List', async (name = null) => {
  let data;
  let config = {
    method: 'GET'
  };
  if (name && name.trim()) {
    config.params = {
      name
    };
  }
  try {
    const response = await http(endpoints.STUDENTS, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.studenti;
});

export const addStudent = createAsyncThunk('Students/Add', async (student) => {
  let insertedStudent;
  try {
    const response = await http(endpoints.STUDENTS, {
      method: 'POST',
      data: {
        ...student
      }
    });
    insertedStudent = response.data.studente;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedStudent;
});

export const editStudent = createAsyncThunk('Students/Edit', async ({ id, student }) => {
  let response;
  try {
    response = await http(`${ endpoints.STUDENTS }/${ id }`, {
      method: 'PATCH',
      data: {
        ...student
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.studente;
});

export const deleteStudent = createAsyncThunk('Students/Delete', async (id) => {
  try {
    await http(`${ endpoints.STUDENTS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreStudent = createAsyncThunk('Students/Restore', async (id) => {
  try {
    await http(`${ endpoints.STUDENTS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const textSearchStudent = createAsyncThunk('Student/Search Student', async ({ nome, cognome, codiceFiscale }) => {
  let data;
  const params = {};
  if (nome && nome.trim() !== '') {
    params.nome = nome.trim();
  }
  if (cognome && cognome.trim() !== '') {
    params.cognome = cognome.trim();
  }
  if (codiceFiscale && codiceFiscale.trim() !== '') {
    params.codice_fiscale = codiceFiscale.trim();
  }
  const config = {
    method: 'GET',
    params: { ...params }
  };
  try {
    const response = await http(`${ endpoints.SEARCH }/studenti`, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.risultati;
});

const thunks = { retrieveStudentsList, addStudent, editStudent, deleteStudent, restoreStudent };

export default thunks;
