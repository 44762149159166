const CourseSubscriptionsInitialState = {
  subscriptions: [],
  loading: false,
  error: null,
  selectedSubscription: null,
  showModal: false,
  modalType: null
};

export default CourseSubscriptionsInitialState;
