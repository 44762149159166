import styled from "styled-components";
import useKpi from "../../hooks/useKpi";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  getGlobalForecastCashFlow,
  getGlobalRealCashFlow,
  getCourseSubscriptionEnrollments,
  getCourseSubscriptionTypes,
  getForecastCashFlow,
  getHowKnowAboutSchoolRanks,
  getRealCashFlow
} from "../../store/kpi/thunk";
import ViewTitle from "../../components/ViewTitle/ViewTitle";
import ForecastCashFlow from "../../components/widgets/ForecastCashFlow/ForecastCashFlow";
import RealCashFlow from "../../components/widgets/RealCashFlow/RealCashFlow";
import CourseSubscriptionEnrollments from "../../components/widgets/CourseSubscriptionEnrollments/CourseSubscriptionEnrollments";
import CourseSubscriptionTypes from "../../components/widgets/CourseSubscriptionTypes/CourseSubscriptionTypes";
import HowKnowAboutSchool from "../../components/widgets/HowKnowAboutSchool/HowKnowAboutSchool";
import ForecastCashFlowChart from "../../components/widgets/ForecastCashFlowChart/ForecastCashFlowChart";
import RealCashFlowChart from "../../components/widgets/RealCashFlowChart/RealCashFlowChart";

const Dashboard = () => {

  const dispatch = useDispatch();

  const {
    globalForecastCashFlow,
    globalRealCashFlow,
    forecastCashFlow,
    realCashFlow,
    courseSubscriptionEnrollments,
    courseSubscriptionTypes,
    howKnowAboutSchoolRanks
  } = useKpi();

  useEffect(() => {
    dispatch(getGlobalForecastCashFlow());
    dispatch(getGlobalRealCashFlow());
    dispatch(getForecastCashFlow('month'));
    dispatch(getRealCashFlow('month'));
    dispatch(getCourseSubscriptionEnrollments('month'));
    dispatch(getCourseSubscriptionTypes('month'));
    dispatch(getHowKnowAboutSchoolRanks('month'));
  }, [ dispatch ]);

  const onForecastRangeChangeHandler = (range) => dispatch(getForecastCashFlow(range));
  const onRealRangeChangeHandler = (range) => dispatch(getRealCashFlow(range));
  const onCourseSubscriptionEnrollmentsChangeHandler = (range) => dispatch(getCourseSubscriptionEnrollments(range));
  const onCourseSubscriptionTypesChangeHandler = (range) => dispatch(getCourseSubscriptionTypes(range));
  const onHowKnowAboutSchoolChangeHandler = (range) => dispatch(getHowKnowAboutSchoolRanks(range));

  return (
    <div
      className="view">
      <ViewTitle
        title="Dashboard"/>
      <WidgetWrapper>
        <ForecastCashFlowChart
          kpi={ globalForecastCashFlow }/>
        <RealCashFlowChart
          kpi={ globalRealCashFlow } />
        <ForecastCashFlow
          kpi={ forecastCashFlow }
          onChange={ onForecastRangeChangeHandler }/>
        <RealCashFlow
          kpi={ realCashFlow }
          onChange={ onRealRangeChangeHandler }/>
        <CourseSubscriptionEnrollments
          kpi={ courseSubscriptionEnrollments }
          onChange={ onCourseSubscriptionEnrollmentsChangeHandler } />
        <CourseSubscriptionTypes
          kpi={ courseSubscriptionTypes }
          onChange={ onCourseSubscriptionTypesChangeHandler } />
        <HowKnowAboutSchool
          kpi={ howKnowAboutSchoolRanks }
          onChange={ onHowKnowAboutSchoolChangeHandler } />
      </WidgetWrapper>
    </div>
  );

}

export default Dashboard;

const WidgetWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;
