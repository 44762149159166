import { Slices } from "../../constants/slices";
import CoursesTypesInitialState from "./state";
import { retrieveCoursesTypeList } from "../courses/thunk";
import { addCoursesType, deleteCoursesType, editCoursesType, restoreCoursesType } from "./thunk";
import { closeCoursesTypeModal, selectCoursesType } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.TYPES,
  initialState: CoursesTypesInitialState,
  reducers: {},
  extraReducers: {
    // retrieve courses type list
    [retrieveCoursesTypeList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveCoursesTypeList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      types: [ ...action.payload ],
      error: null
    }),
    [retrieveCoursesTypeList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new course type
    [addCoursesType.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addCoursesType.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      types: [ action.payload, ...state.types ],
      error: null,
      selectedType: null,
      showModal: false
    }),
    [addCoursesType.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit course type
    [editCoursesType.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editCoursesType.fulfilled.type]: (state, action) => {
      const index = state.types.findIndex((el) => el.id === action.payload.id);
      const types = [ ...state.types ];
      types[index] = action.payload;
      return {
        ...state,
        loading: false,
        types: [ ...types ],
        error: null,
        selectedType: null,
        showModal: false
      };
    },
    [editCoursesType.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete course type
    [deleteCoursesType.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteCoursesType.fulfilled.type]: (state, action) => {
      const index = state.types.findIndex((el) => el.id === action.payload);
      const types = [ ...state.types ];
      types[index] = { ...types[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        types: [ ...types ],
        error: null,
        selectedType: null,
        showModal: false
      };
    },
    [deleteCoursesType.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore course type
    [restoreCoursesType.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreCoursesType.fulfilled.type]: (state, action) => {
      const index = state.types.findIndex((el) => el.id === action.payload);
      const types = [ ...state.types ];
      types[index] = { ...types[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        types: [ ...types ],
        error: null,
        selectedType: null,
        showModal: false
      };
    },
    [restoreCoursesType.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific course type reference
    [selectCoursesType]: (state, action) => ({
      ...state,
      selectedType: action.payload.courseType,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeCoursesTypeModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedType: null,
      modalType: null
    })
  }
});

export default slice.reducer;
