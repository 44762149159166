import { Button, Modal } from "react-bootstrap";

const OperatorModalDelete = ({ loading, operator, onClose, onDelete }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso cancellazione operatore
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          operator && <p>Vuoi davvero cancellare l'operatore <strong>{ operator.nome } { operator.cognome }</strong>?</p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }>Chiudi</Button>
        <Button
          variant="danger"
          disabled={ loading }
          onClick={ onDelete }>{ loading ? 'Cancellazione in corso...' : 'Cancella operatore' }</Button>
      </Modal.Footer>
    </>
  );

}

export default OperatorModalDelete;
