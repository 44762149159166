import classes from './TabContentHeader.module.scss';
import { Breadcrumb, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const TabContentHeader = ({ heading, buttonTitle, buttonIcon, buttonHandler }) => {

  return (
    <Breadcrumb className={ classes['tab-content-header'] } listProps={{ className: classes.breadcrumb }}>
      <Breadcrumb.Item active>{ heading }</Breadcrumb.Item>
      {
        buttonTitle && buttonHandler && (
          <Button variant="outline-primary" size="sm" onClick={ buttonHandler }>
            { buttonIcon && (<FontAwesomeIcon icon={ buttonIcon }/>) } { buttonTitle }
          </Button>
        )
      }
    </Breadcrumb>
  );

}

export default TabContentHeader;
