import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveCoursesList = createAsyncThunk('Courses/List', async (title) => {
  let data;
  let config = {
    method: 'GET'
  };
  if (title && title.trim()) {
    config.params = {
      title
    };
  }
  try {
    const response = await http(endpoints.COURSES, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.corsi;
});

export const addCourse = createAsyncThunk('Courses/Add', async (course) => {
  let insertedCourse;
  try {
    const response = await http(endpoints.COURSES, {
      method: 'POST',
      data: {
        abandon: +course.abandon,
        allowedToExam: +course.allowedToExam,
        bsaCode: course.bsaCode,
        completedFemale: +course.completedFemale,
        completedMale: +course.completedMale,
        duration: +course.duration,
        fadAsync: +course.fadAsync,
        fadSync: +course.fadSync,
        frequency: course.frequency,
        location: +course.location,
        notes: course.notes,
        protocol: course.protocol,
        regionalCode: course.regionalCode,
        startingSubscribers: +course.startingSubscribers,
        teachers: course.teachers,
        startingDate: course.startingDate,
        endingDate: course.endingDate,
        examDate: course.examDate,
        secondExamDate: course.secondExamDate,
        tenSubscribers: +course.tenSubscribers,
        title: +course.title,
        turn: +course.turn,
        type: +course.type
      }
    });
    insertedCourse = response.data.corso;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedCourse;
});

export const editCourse = createAsyncThunk('Courses/Edit', async ({ id, course }) => {
  let response;
  try {
    response = await http(`${ endpoints.COURSES }/${ id }`, {
      method: 'PATCH',
      data: {
        abandon: +course.abandon,
        allowedToExam: +course.allowedToExam,
        bsaCode: course.bsaCode,
        completedFemale: +course.completedFemale,
        completedMale: +course.completedMale,
        duration: +course.duration,
        fadAsync: +course.fadAsync,
        fadSync: +course.fadSync,
        frequency: course.frequency,
        location: +course.location,
        notes: course.notes,
        protocol: course.protocol,
        regionalCode: course.regionalCode,
        startingSubscribers: +course.startingSubscribers,
        teachers: course.teachers,
        startingDate: course.startingDate,
        endingDate: course.endingDate,
        examDate: course.examDate,
        secondExamDate: course.secondExamDate,
        tenSubscribers: +course.tenSubscribers,
        title: +course.title,
        turn: +course.turn,
        type: +course.type
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.corso;
});

export const deleteCourse = createAsyncThunk('Courses/Delete', async (id) => {
  try {
    await http(`${ endpoints.COURSES }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreCourse = createAsyncThunk('Courses/Restore', async (id) => {
  try {
    await http(`${ endpoints.COURSES }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const retrieveCoursesTypeList = createAsyncThunk('Courses Types/List', async () => {
  let data;
  try {
    const response = await http(endpoints.COURSES_TYPE, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.tipologie;
});

const thunks = { retrieveCoursesList, addCourse, deleteCourse, restoreCourse, retrieveCoursesTypeList };

export default thunks;
