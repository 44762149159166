import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveCoursesTypeList = createAsyncThunk('Courses Types/List', async () => {
  let data;
  try {
    const response = await http(endpoints.COURSES_TYPE, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.tipologie;
});

export const addCoursesType = createAsyncThunk('Courses Types/Add', async ({ type }) => {
  let insertedType;
  try {
    const response = await http(endpoints.COURSES_TYPE, {
      method: 'POST',
      data: {
        type
      }
    });
    insertedType = response.data.tipologia;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedType;
});

export const editCoursesType = createAsyncThunk('Courses Types/Edit', async ({ id, type }) => {
  let response;
  try {
    response = await http(`${ endpoints.COURSES_TYPE }/${ id }`, {
      method: 'PATCH',
      data: {
        type
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.tipologia;
});

export const deleteCoursesType = createAsyncThunk('Courses Types/Delete', async (id) => {
  try {
    await http(`${ endpoints.COURSES_TYPE }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreCoursesType = createAsyncThunk('Courses Types/Restore', async (id) => {
  try {
    await http(`${ endpoints.COURSES_TYPE }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

const thunks = { retrieveCoursesTypeList, addCoursesType, deleteCoursesType, restoreCoursesType };

export default thunks;
