import ViewContainer from "./components/ViewContainer/ViewContainer";
import NavigationBar from "./components/Navbar/NavigationBar";
import useAuth from "./hooks/useAuth";
import { Storage } from "./constants/storage";

const App = () => {

  const { user, logoutSubmit } = useAuth();

  window.addEventListener('storage', () => !window.localStorage.getItem(Storage.KEY) ? logoutSubmit() : null);

  return (
    <>
      { user && <NavigationBar /> }
      <ViewContainer/>
    </>
  );

};

export default App;
