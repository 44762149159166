import { Button, Modal } from "react-bootstrap";

const CourseTypeModalDelete = ({ type, onClose, onDelete }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso cancellazione categoria
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Vuoi davvero cancellare la categoria <strong>{ type && type.tipologia }</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="danger" onClick={ onDelete }>Cancella categoria</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTypeModalDelete;
