const OperatorsInitialState = {
  operators: [],
  loading: false,
  error: null,
  selectedOperator: null,
  showModal: false,
  modalType: null
};

export default OperatorsInitialState;
