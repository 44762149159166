import { applyMiddleware, combineReducers, createStore } from "@reduxjs/toolkit";
import auth from './auth/reducer';
import courses from './courses/reducer';
import courseSubscriptions from './courseSubscriptions/reducer';
import courseTurns from './courseTurns/reducer';
import courseTitles from './courseTitles/reducer';
import types from './coursesType/reducer';
import locations from './locations/reducer';
import operators from './operators/reducer';
import students from './students/reducer';
import courseSearch from './courseSearch/reducer';
import kpi from './kpi/reducer';
import { LOGOUT } from "../constants/actions";
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { Storage } from "../constants/storage";
import { logout } from "./auth/actions";

const appReducer = combineReducers({
  auth,
  courses,
  courseSubscriptions,
  courseTurns,
  courseTitles,
  types,
  locations,
  operators,
  students,
  courseSearch,
  kpi
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

const jwtCustomMiddleware = (store) => (next) => (action) => {
  const state = store.getState();
  if (state && state.auth.user) {
    const jwt = localStorage.getItem(Storage.KEY);
    if (!jwt && action.type !== LOGOUT) {
      store.dispatch(logout());
    }
  }
  next(action);
};

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk, jwtCustomMiddleware)));
