const { createSelector } = require("@reduxjs/toolkit");

const AuthStore = (state) => state.auth;

export const authLoadingSelector = createSelector(
  AuthStore,
  (authState) => authState.loading
);

export const authErrorSelector = createSelector(
  AuthStore,
  (authState) => authState.error
);

export const authUserSelector = createSelector(
  AuthStore,
  (authState) => authState.user
);
