import { Slices } from "../../constants/slices";
import CourseTurnsInitialState from "./state";
import { retrieveTurnsList, addTurn, editTurn, deleteTurn, restoreTurn } from "./thunk";
import { selectTurn, closeTurnsModal } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.COURSE_TURNS,
  initialState: CourseTurnsInitialState,
  reducers: {},
  extraReducers: {
    // retrieve turns list
    [retrieveTurnsList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveTurnsList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      turns: [ ...action.payload ],
      error: null
    }),
    [retrieveTurnsList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new turn
    [addTurn.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addTurn.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      turns: [ action.payload, ...state.turns ],
      error: null,
      selectedTurn: null,
      showModal: false
    }),
    [addTurn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit turn
    [editTurn.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editTurn.fulfilled.type]: (state, action) => {
      const index = state.turns.findIndex((el) => el.id === action.payload.id);
      const turns = [ ...state.turns ];
      turns[index] = action.payload;
      return {
        ...state,
        loading: false,
        turns: [ ...turns ],
        error: null,
        selectedTurn: null,
        showModal: false
      };
    },
    [editTurn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete turn
    [deleteTurn.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteTurn.fulfilled.type]: (state, action) => {
      const index = state.turns.findIndex((el) => el.id === action.payload);
      const turns = [ ...state.turns ];
      turns[index] = { ...turns[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        turns: [ ...turns ],
        error: null,
        selectedTurn: null,
        showModal: false
      };
    },
    [deleteTurn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore turn
    [restoreTurn.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreTurn.fulfilled.type]: (state, action) => {
      const index = state.turns.findIndex((el) => el.id === action.payload);
      const turns = [ ...state.turns ];
      turns[index] = { ...turns[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        turns: [ ...turns ],
        error: null,
        selectedTurn: null,
        showModal: false
      };
    },
    [restoreTurn.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific turn reference
    [selectTurn]: (state, action) => ({
      ...state,
      selectedTurn: { ...action.payload.turn },
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeTurnsModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedTurn: null,
      modalType: null
    })
  }
});

export default slice.reducer;
