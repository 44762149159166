import styled from "styled-components";

const WidgetContainer = ({ sm, md, lg, children }) => {

  return (
    <Container
      sm={ sm }
      md={ md }
      lg={ lg }>
      <InnerContainer>
        { children }
      </InnerContainer>
    </Container>
  );

};

export default WidgetContainer;

const Container = styled.div`
  width: 100%;
  @media screen and (min-width: 1024px) {
    max-width: calc(100% / ${ props => props.sm });
  }
  @media screen and (min-width: 1280px) {
    max-width: calc(100% / ${ props => props.md });
  }
  @media screen and (min-width: 1920px) {
    max-width: calc(100% / ${ props => props.lg });
  }
`;

const InnerContainer = styled.div`
  background-color: white;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.15);
  margin: 20px;
  padding: 20px 40px;
  border-radius: 20px;
`;
