import { Button, Modal } from "react-bootstrap";

const StudentModalDelete = ({ loading, student, onClose, onDelete }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso cancellazione studente
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {
          student && <p>Vuoi davvero cancellare lo studente <strong>{ student.nome } { student.cognome }</strong>?</p>
        }
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="secondary"
          onClick={ onClose }>Chiudi</Button>
        <Button
          variant="danger"
          disabled={ loading }
          onClick={ onDelete }>{ loading ? 'Cancellazione in corso...' : 'Cancella studente' }</Button>
      </Modal.Footer>
    </>
  );

}

export default StudentModalDelete;
