import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import { authErrorSelector, authLoadingSelector, authUserSelector } from "../store/auth/selectors";
import login from "../store/auth/thunk";
import { Routes } from "../constants/routes";
import { logout, storeUser } from "../store/auth/actions";
import { Storage } from "../constants/storage";
import jwtDecode from "jwt-decode";
import { setAuthorizationToken } from "../utils/http";
import { GOOGLE_RECAPTCHA_PUBLIC_KEY } from "../constants/apikeys";

const useAuth = () => {

  const history = useHistory();
  const dispatch = useDispatch();

  const jwt = localStorage.getItem(Storage.KEY);

  const loading = useSelector(authLoadingSelector);
  const error = useSelector(authErrorSelector);
  const user = useSelector(authUserSelector);

  const userRole = user ? user.ruolo : null;

  const sessionNeedRestore = !user && jwt && typeof jwt === 'string' && jwt.trim().length > 0;

  const sessionExpired = !jwt;

  if (sessionNeedRestore) {
    console.log('Found a valid session, restore...');
    setAuthorizationToken(jwt);
    dispatch(storeUser(jwtDecode(jwt)));
  }

  const logoutSubmit = () => {
    localStorage.clear();
    dispatch(logout());
    history.push(Routes.LOGIN);
  };

  const isLogged = user && Object.keys(user).length > 0;

  const submitHandler = ({ email, password }) => {
    window.grecaptcha.execute(GOOGLE_RECAPTCHA_PUBLIC_KEY, { action: 'login' }).then((token) => {
      dispatch(login({ email, password, recaptchaToken: token }))
    });
  };

  return { loading, error, user, userRole, isLogged, sessionExpired, submitHandler, logoutSubmit };

};

export default useAuth;
