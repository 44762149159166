import { Slices } from "../../constants/slices";
import CourseSubscriptionsInitialState from "./state";
import { selectCourseSubscription, closeCourseSubscriptionsModal } from "./actions";
import {
  retrieveCourseSubscriptionList,
  addCourseSubscription,
  editCourseSubscription,
  deleteCourseSubscription,
  restoreCourseSubscription,
  editCourseSubscriptionInstallments, textSearchSubscription
} from './thunk';

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.COURSE_SUBSCRIPTIONS,
  initialState: CourseSubscriptionsInitialState,
  reducers: {},
  extraReducers: {
    // retrieve subscriptions list
    [retrieveCourseSubscriptionList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveCourseSubscriptionList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      subscriptions: [ ...action.payload ],
      error: null
    }),
    [retrieveCourseSubscriptionList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new subscription
    [addCourseSubscription.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addCourseSubscription.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      subscriptions: [ action.payload, ...state.subscriptions ],
      error: null,
      selectedSubscription: null,
      showModal: false
    }),
    [addCourseSubscription.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit subscription
    [editCourseSubscription.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editCourseSubscription.fulfilled.type]: (state, action) => {
      const index = state.subscriptions.findIndex((el) => el.id === action.payload.id);
      const subscriptions = [ ...state.subscriptions ];
      subscriptions[index] = action.payload;
      return {
        ...state,
        loading: false,
        subscriptions: [ ...subscriptions ],
        error: null,
        selectedSubscription: null,
        showModal: false
      };
    },
    [editCourseSubscription.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit installments
    [editCourseSubscriptionInstallments.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editCourseSubscriptionInstallments.fulfilled.type]: (state, action) => {
      const index = state.subscriptions.findIndex((el) => el.id === action.payload.id);
      const subscriptions = [ ...state.subscriptions ];
      subscriptions[index] = action.payload;
      return {
        ...state,
        loading: false,
        subscriptions: [ ...subscriptions ],
        error: null,
        selectedSubscription: null,
        showModal: false
      };
    },
    [editCourseSubscriptionInstallments.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete subscription
    [deleteCourseSubscription.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteCourseSubscription.fulfilled.type]: (state, action) => {
      const index = state.subscriptions.findIndex((el) => el.id === action.payload);
      const subscriptions = [ ...state.subscriptions ];
      subscriptions[index] = { ...subscriptions[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        subscriptions: [ ...subscriptions ],
        error: null,
        selectedSubscription: null,
        showModal: false
      };
    },
    [deleteCourseSubscription.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore subscription
    [restoreCourseSubscription.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreCourseSubscription.fulfilled.type]: (state, action) => {
      const index = state.subscriptions.findIndex((el) => el.id === action.payload);
      const subscriptions = [ ...state.subscriptions ];
      subscriptions[index] = { ...subscriptions[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        subscriptions: [ ...subscriptions ],
        error: null,
        selectedSubscription: null,
        showModal: false
      };
    },
    [restoreCourseSubscription.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific subscription reference
    [selectCourseSubscription]: (state, action) => ({
      ...state,
      selectedSubscription: action.payload.subscription
        ? action.payload.subscription
        : null,
      showModal: true,
      modalType: action.payload.type
    }),
    // ui
    [closeCourseSubscriptionsModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedSubscription: null,
      modalType: null
    }),
    // retrieve subscriptions list
    [textSearchSubscription.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [textSearchSubscription.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      subscriptions: [ ...action.payload ],
      error: null
    }),
    [textSearchSubscription.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    })
  }
});

export default slice.reducer;
