const CoursesTypesInitialState = {
  types: [],
  loading: false,
  error: null,
  selectedType: null,
  showModal: false,
  modalType: null
};

export default CoursesTypesInitialState;
