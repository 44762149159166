import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const searchCourses = createAsyncThunk('Search/Courses', async ({ title, startingDate, duration, turn, location }) => {
  let data;
  const config = {
    method: 'POST',
    data: {
      title: title && title !== 0 ? title : null,
      startingDate: startingDate && startingDate !== '' ? startingDate : null,
      duration: duration && duration !== 0 ? duration : null,
      turn: turn && turn !== 0 ? turn : null,
      location: location && location !== 0 ? location : null
    }
  };
  try {
    const response = await http(endpoints.SEARCH, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.risultati;
});

export const textSearchCourses = createAsyncThunk('Text Search/Courses', async ({ title, startingDate }) => {
  let data;
  const params = {};
  if (title && title.trim() !== '') {
    params.titolo = title.trim();
  }
  if (startingDate && startingDate.trim() !== '') {
    params.data_inizio = startingDate.trim();
  }
  const config = {
    method: 'GET',
    params: { ...params }
  };
  try {
    const response = await http(`${ endpoints.SEARCH }/corsi`, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.risultati;
});

export const exportCSV = createAsyncThunk('Search/Export', async ({ courseId, type }) => {
  let data;
  try {
    const response = await http(`${ endpoints.EXPORTS }/${ courseId }/${ type }`, {
      responseType: 'blob'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  const blob = new Blob([ data ]);
  const blobURL = window.URL.createObjectURL(blob);
  const tempLink = document.createElement('a');
  tempLink.style.display = 'none';
  tempLink.href = blobURL;
  tempLink.setAttribute('download', `${ +new Date() }_estrazione_${ type }.xlsx`);
  if (typeof tempLink.download === 'undefined') {
    tempLink.setAttribute('target', '_blank');
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  document.body.removeChild(tempLink);
  setTimeout(() => {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(blobURL);
  }, 100);
});

const thunks = { searchCourses, exportCSV };

export default thunks;
