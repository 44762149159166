import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { Storage } from "../../constants/storage";
import { http, setAuthorizationToken } from "../../utils/http";

const login = createAsyncThunk('Auth/Login', async ({ email, password, recaptchaToken }) => {
  let data;
  try {
    const response = await http(endpoints.LOGIN, {
      method: 'POST',
      data: {
        email,
        password,
        recaptchaToken
      }
    });
    data = response.data.body;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  localStorage.setItem(Storage.KEY, data.token);
  setAuthorizationToken(data.token);
  return data.user;
});

export default login;
