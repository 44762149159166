import { Slices } from "../../constants/slices";
import CourseTitlesInitialState from "./state";
import { retrieveCourseTitlesList, addCourseTitle, editCourseTitle, deleteCourseTitle, restoreCourseTitle } from "./thunk";
import { selectCourseTitle, closeCourseTitlesModal } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.COURSE_TITLES,
  initialState: CourseTitlesInitialState,
  reducers: {},
  extraReducers: {
    // retrieve course title list
    [retrieveCourseTitlesList.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [retrieveCourseTitlesList.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      titles: [ ...action.payload ],
      error: null
    }),
    [retrieveCourseTitlesList.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // add new course title
    [addCourseTitle.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [addCourseTitle.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      titles: [ action.payload, ...state.titles ],
      error: null,
      selectedCourseTitle: null,
      showModal: false
    }),
    [addCourseTitle.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // edit course title
    [editCourseTitle.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [editCourseTitle.fulfilled.type]: (state, action) => {
      const index = state.titles.findIndex((el) => el.id === action.payload.id);
      const titles = [ ...state.titles ];
      titles[index] = action.payload;
      return {
        ...state,
        loading: false,
        titles: [ ...titles ],
        error: null,
        selectedCourseTitle: null,
        showModal: false
      };
    },
    [editCourseTitle.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // delete course title
    [deleteCourseTitle.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [deleteCourseTitle.fulfilled.type]: (state, action) => {
      const index = state.titles.findIndex((el) => el.id === action.payload);
      const titles = [ ...state.titles ];
      titles[index] = { ...titles[index], deleted: 1 };
      return {
        ...state,
        loading: false,
        titles: [ ...titles ],
        error: null,
        selectedCourseTitle: null,
        showModal: false
      };
    },
    [deleteCourseTitle.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // restore course title
    [restoreCourseTitle.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [restoreCourseTitle.fulfilled.type]: (state, action) => {
      const index = state.titles.findIndex((el) => el.id === action.payload);
      const titles = [ ...state.titles ];
      titles[index] = { ...titles[index], deleted: 0 };
      return {
        ...state,
        loading: false,
        titles: [ ...titles ],
        error: null,
        selectedCourseTitle: null,
        showModal: false
      };
    },
    [restoreCourseTitle.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    // store specific course title reference
    [selectCourseTitle]: (state, action) => {
      console.log(action);
      return {
        ...state,
        selectedCourseTitle: { ...action.payload.title },
        showModal: true,
        modalType: action.payload.type
      };
    },
    // ui
    [closeCourseTitlesModal]: (state, action) => ({
      ...state,
      showModal: false,
      selectedCourseTitle: null,
      modalType: null
    })
  }
});

export default slice.reducer;
