import TableCell from "../TableCell/TableCell";
import TableCellActions from "../TableCellActions/TableCellActions";

const TableRow = ({ columns, row, onView, onEdit, onDelete, onRestore, openContract, onInstallmentsEdit }) => {

  const viewHandler = () => onView(row);
  const editHandler = () => onEdit(row);
  const deleteHandler = () => onDelete(row);
  const restoreHandler = () => onRestore(row);
  const openContractHandler = () => openContract(row);
  const installmentsEditHandler = () => onInstallmentsEdit(row);

  return (
    <tr>
      {
        row && Object.keys(row)
          .map((prop, i) =>
            <TableCell
              key={ i }
              column={ columns[i].id }
              value={ row[columns[i].id] }
              hidden={ columns[i].name === null } />
          )
      }
      <TableCellActions
        editDisabled={ row.deleted === 1 }
        deleteDisabled={ row.deleted === 1 }
        restoreDisabled={ row.deleted === 0 }
        viewHandler={ onView ? viewHandler : null }
        editHandler={ editHandler }
        deleteHandler={ deleteHandler }
        restoreHandler={ restoreHandler }
        openContractHandler={ openContractHandler }
        installmentsEditHandler={ installmentsEditHandler }/>
    </tr>
  );

}

export default TableRow;
