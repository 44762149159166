import ViewTitle from "../../components/ViewTitle/ViewTitle";
import OperatorsList from "../../components/OperatorsList/OperatorsList";
import { useDispatch } from "react-redux";
import { retrieveOperatorsList } from "../../store/operators/thunk";
import { Modals } from "../../constants/modals";
import { useEffect } from "react";
import useOperators from "../../hooks/useOperators";
import FloatingButton from "../../components/buttons/FloatingButton/FloatingButton";
import { selectOperator } from "../../store/operators/actions";
import OperatorModal from "../../components/modals/operators/OperatorModal";

const Operators = () => {

  const dispatch = useDispatch();

  const { operatorsList } = useOperators();

  const addOperatorHandler = () => dispatch(selectOperator({ type: Modals.Operators.ADD }));
  const editOperatorHandler = (data) => dispatch(selectOperator({ operator: data, type: Modals.Operators.EDIT }));
  const changeStatusOperatorHandler = () => dispatch(selectOperator({ type: Modals.Operators.CHANGE_STATUS }));
  const deleteOperatorHandler = (data) => dispatch(selectOperator({ operator: data, type: Modals.Operators.DELETE }));
  const restoreOperatorHandler = (data) => dispatch(selectOperator({ operator: data, type: Modals.Operators.RESTORE }));

  useEffect(() => {
    dispatch(retrieveOperatorsList());
  }, [ dispatch ]);

  return (
    <div className="view">
      <ViewTitle
        title="Anagrafica Operatori"/>
      <OperatorsList
        operators={ operatorsList }
        onEdit={ editOperatorHandler }
        onChangeStatus={ changeStatusOperatorHandler }
        onDelete={ deleteOperatorHandler }
        onRestore={ restoreOperatorHandler }/>
      <FloatingButton
        onClick={ addOperatorHandler } />
      <OperatorModal />
    </div>
  );

}

export default Operators;
