const CourseTurnsInitialState = {
  turns: [],
  loading: false,
  error: null,
  selectedTurn: null,
  showModal: false,
  modalType: null
};

export default CourseTurnsInitialState;
