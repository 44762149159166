import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveCourseSubscriptionList = createAsyncThunk('Course Subscriptions/List', async () => {
  let data;
  try {
    const response = await http(endpoints.COURSE_SUBSCRIPTIONS, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.iscrizioni;
});

export const addCourseSubscription = createAsyncThunk('Course Subscriptions/Add', async (courseSubscription) => {
  let insertedCourseSubscription;
  try {
    const response = await http(endpoints.COURSE_SUBSCRIPTIONS, {
      method: 'POST',
      data: {
        ...courseSubscription
      }
    });
    insertedCourseSubscription = response.data.iscrizione;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedCourseSubscription;
});

export const editCourseSubscription = createAsyncThunk('Course Subscriptions/Edit', async ({ id, subscription }) => {
  let response;
  try {
    console.log('dentro thunk', { ...subscription });
    response = await http(`${ endpoints.COURSE_SUBSCRIPTIONS }/${ id }`, {
      method: 'PATCH',
      data: {
        ...subscription
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.iscrizione;
});

export const editCourseSubscriptionInstallments = createAsyncThunk('Course Subscriptions/Edit Installments', async ({ id, installments }) => {
  let response;
  try {
    response = await http(`${ endpoints.INSTALLMENTS }/${ id }`, {
      method: 'PATCH',
      data: {
        rate: installments.map((el) => ({ ...el, importo_rata: +el.importo_rata }))
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.iscrizione;
});

export const deleteCourseSubscription = createAsyncThunk('Course Subscriptions/Delete', async (id) => {
  try {
    await http(`${ endpoints.COURSE_SUBSCRIPTIONS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreCourseSubscription = createAsyncThunk('Course Subscriptions/Restore', async (id) => {
  try {
    await http(`${ endpoints.COURSE_SUBSCRIPTIONS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const textSearchSubscription = createAsyncThunk('Course Subscriptions/Search Subscription', async (obj) => {
  let data;
  const config = {
    method: 'GET',
    params: { ...obj }
  };
  try {
    const response = await http(`${ endpoints.SEARCH }/iscrizioni`, config);
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.risultati;
});

const thunks = {
  retrieveCourseSubscriptionList,
  addCourseSubscription,
  editCourseSubscription,
  editCourseSubscriptionInstallments,
  deleteCourseSubscription,
  restoreCourseSubscription
};

export default thunks;
