const { createSelector } = require("@reduxjs/toolkit");

const turnsStore = (state) => state.courseTurns;

export const turnsLoadingSelector = createSelector(
  turnsStore,
  (turnsState) => turnsState.loading
);

export const turnsErrorSelector = createSelector(
  turnsStore,
  (turnsState) => turnsState.error
);

export const turnsListSelector = createSelector(
  turnsStore,
  (turnsState) => turnsState.turns
);

export const selectedTurnSelector = createSelector(
  turnsStore,
  (turnsState) => turnsState.selectedTurn
);

export const turnModalSelector = createSelector(
  turnsStore,
  (turnsState) => turnsState.showModal
);

export const turnModalType = createSelector(
  turnsStore,
  (turnsState) => turnsState.modalType
);
