import classes from './Table.module.scss';
import TableRow from "../TableRow/TableRow";
import { Table as BootstrapTable } from 'react-bootstrap';

const Table = ({ columns, rows, onView, onEdit, onDelete, onRestore, openContract, onInstallmentsEdit }) => {

  return (
    <div className={ classes['table-container'] }>
      <BootstrapTable responsive bordered hover size="sm">
        <thead>
        <tr>
          {
            columns && columns
              .map((col) => <th key={ col.id } hidden={ col.name === null }>{ col.name }</th>)
          }
        </tr>
        </thead>
        <tbody>
        {
          rows && columns && rows.map((row) => (
            <TableRow
              key={ row.id }
              columns={ columns }
              row={ row }
              onView={ onView }
              onEdit={ onEdit }
              onDelete={ onDelete }
              onRestore={ onRestore }
              openContract={ openContract }
              onInstallmentsEdit={ onInstallmentsEdit }/>
          ))
        }
        </tbody>
      </BootstrapTable>
    </div>
  );

}

export default Table;
