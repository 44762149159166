import useAuth from "../../hooks/useAuth";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "../../constants/routes";

const ProtectedRoute = ({ component: Component, ...rest }) => {

  const { sessionExpired } = useAuth();

  return (
    <Route
      { ...rest }
      render={
        (props) => !sessionExpired
          ? (<Component { ...rest } { ...props } />)
          : (<Redirect to={{
            pathname: Routes.LOGIN,
            state: { from: props.location }
          }}/>)
      }
    />
  );

}

export default ProtectedRoute;
