import classes from './TableCellActions.module.scss';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileSignature, faPencilAlt, faTrashAlt, faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";
import SubscriptionActions from "./SubscriptionActions/SubscriptionActions";

const TableCellActions = ({
  viewHandler,
  editDisabled,
  deleteDisabled,
  restoreDisabled,
  editHandler,
  deleteHandler,
  restoreHandler,
  openContractHandler,
  installmentsEditHandler
}) => {

  const { userRole } = useAuth();

  const { location } = useHistory();

  if (location.pathname === '/subscriptions') {
    return <SubscriptionActions
      editDisabled={ editDisabled }
      deleteDisabled={ deleteDisabled }
      restoreDisabled={ restoreDisabled }
      viewHandler={ viewHandler }
      editHandler={ editHandler }
      installmentsEditHandler={ installmentsEditHandler }
      deleteHandler={ deleteHandler }
      restoreHandler={ restoreHandler }
      openContractHandler={ openContractHandler } />;
  }

  return (
    <td className={ classes.cell }>
      {
        viewHandler && (
          <Button
            variant="link"
            size="sm"
            disabled={ false }
            onClick={ viewHandler }>
            <FontAwesomeIcon icon={ faEye }/> Visualizza
          </Button>
        )
      }
      {/*{*/}
      {/*  location.pathname === '/subscriptions' && (*/}
      {/*    <Button*/}
      {/*      variant="link"*/}
      {/*      size="sm"*/}
      {/*      disabled={ false }*/}
      {/*      onClick={ openContractHandler }>*/}
      {/*      <FontAwesomeIcon icon={ faFileSignature }/> Contratto*/}
      {/*    </Button>*/}
      {/*  )*/}
      {/*}*/}
      <Button
        variant="link"
        size="sm"
        disabled={ editDisabled }
        onClick={ editHandler }>
        <FontAwesomeIcon icon={ faPencilAlt }/> Modifica
        {/*{ location.pathname === '/subscriptions' ? ' Aggiorna rate' : ' Modifica' }*/}
      </Button>
      {
        userRole && userRole === 'admin' && (
          <Button
            variant="link"
            size="sm"
            disabled={ deleteDisabled }
            onClick={ deleteHandler }>
            <FontAwesomeIcon icon={ faTrashAlt }/> Cancella
            {/*{ location.pathname === '/subscriptions' ? ' Annulla iscrizione' : ' Cancella' }*/}
          </Button>
        )
      }
      {
        userRole && userRole === 'admin' && (
          <Button
            variant="link"
            size="sm"
            disabled={ restoreDisabled }
            onClick={ restoreHandler }>
            <FontAwesomeIcon icon={ faTrashRestore }/> Recupera
            {/*{ location.pathname === '/subscriptions' ? ' Ripristina iscrizione' : ' Recupera' }*/}
          </Button>
        )
      }
    </td>
  );

}

export default TableCellActions;
