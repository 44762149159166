import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const CourseTypeModalEdit = ({ type, onClose, onEdit }) => {

  const validateCourseType = (value) => value && value.trim().length > 0;

  const [ courseType, setCourseType ] = useState(type && type.tipologia ? type.tipologia : '');
  const [ courseTypeIsTouched, setCourseTypeIsTouched ] = useState(!!courseType);
  const [ courseTypeIsValid, setCourseTypeIsValid ] = useState(validateCourseType(courseType));

  const courseTypeHandler = (e) => setCourseType(e.target.value);

  const courseTypeBlurHandler = (e) => {
    setCourseTypeIsTouched(true);
    setCourseTypeIsValid(validateCourseType(e.target.value));
  };

  const editCourseTypeHandler = (e) => {
    e.preventDefault();
    if (courseTypeIsValid) {
      onEdit(courseType);
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica categoria
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editCourseTypeHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseType">
                <Form.Label>Categoria</Form.Label>
                  <Form.Control
                    onChange={ courseTypeHandler }
                    onBlur={ courseTypeBlurHandler }
                    value={ courseType }
                    type="text"
                    placeholder="Inserisci la categoria"
                    required/>
                  { !courseType && courseTypeIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ editCourseTypeHandler } disabled={ !courseTypeIsValid }>Modifica categoria</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTypeModalEdit;
