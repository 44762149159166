import { GOOGLE_RECAPTCHA_PUBLIC_KEY } from "../constants/apikeys";

export const reCAPTCHAInitialize = () => {
  const script = document.createElement('script');
  script.id = 'google-recaptcha';
  script.src = `https://www.google.com/recaptcha/api.js?render=${ GOOGLE_RECAPTCHA_PUBLIC_KEY }`;
  document.body.appendChild(script);
};

export const reCAPTCHADestroy = () => {
  const scripts = document.querySelectorAll('script');
  const badges = document.querySelectorAll('.grecaptcha-badge');
  scripts.forEach((script) => {
    if (script.src.indexOf('recaptcha') !== -1) {
      script.parentElement.removeChild(script);
    }
  });
  badges.forEach((badge) => badge.parentElement.parentElement.removeChild(badge.parentElement));
};
