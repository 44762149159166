import { Button, Modal } from "react-bootstrap";

const CourseModalDelete = ({ course, onClose, onDelete }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso cancellazione corso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Vuoi davvero cancellare il corso <strong>{ course && course.titolo }</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="danger" onClick={ onDelete }>Cancella corso</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseModalDelete;
