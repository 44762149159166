import ViewTitle from "../../components/ViewTitle/ViewTitle";
import Table from "../../components/Table/Table";
import { Modals } from "../../constants/modals";
import { useDispatch } from "react-redux";
import useCourseSubscriptions from "../../hooks/useCourseSubscriptions";
import { selectCourseSubscription } from "../../store/courseSubscriptions/actions";
import { useEffect } from "react";
import { retrieveCourseSubscriptionList, textSearchSubscription } from "../../store/courseSubscriptions/thunk";
import FloatingButton from "../../components/buttons/FloatingButton/FloatingButton";
import SubscriptionModal from "../../components/modals/subscriptions/SubscriptionModal";
import { retrieveStudentsList } from "../../store/students/thunk";
import { retrieveCoursesList } from "../../store/courses/thunk";
import { retrieveOperatorsList } from "../../store/operators/thunk";
import SearchBarCourse from "../../components/searchBars/SearchBarCourse/SearchBarCourse";

const { REACT_APP_API_BASE_URL } = process.env;

const CourseSubscriptions = () => {

  const dispatch = useDispatch();

  const { courseSubscriptionColumns, courseSubscriptionsList } = useCourseSubscriptions();

  const viewCourseSubscriptionHandler = (data) => dispatch(selectCourseSubscription({ subscription: data, type: Modals.CourseSubscriptions.VIEW }));
  const addCourseSubscriptionHandler = () => dispatch(selectCourseSubscription({ type: Modals.CourseSubscriptions.ADD }));
  const editCourseSubscriptionHandler = (data) => dispatch(selectCourseSubscription({ subscription: data, type: Modals.CourseSubscriptions.EDIT }));
  const editCourseSubscriptionInstallmentsHandler = (data) => dispatch(selectCourseSubscription({ subscription: data, type: Modals.CourseSubscriptions.EDIT_INSTALLMENTS }));
  const deleteCourseSubscriptionHandler = (data) => dispatch(selectCourseSubscription({ subscription: data, type: Modals.CourseSubscriptions.DELETE }));
  const restoreCourseSubscriptionHandler = (data) => dispatch(selectCourseSubscription({ subscription: data, type: Modals.CourseSubscriptions.RESTORE }));
  const openSubscriptionContractHandler = (data) => window.open(`${ REACT_APP_API_BASE_URL }/v1/contratto/${ data.codice_contratto }`, '_blank');

  const onSubscriptionSearch = (payload) => {
    const obj = {};
    obj[ payload.criteria ] = payload.value;
    console.log(obj);
    return dispatch(textSearchSubscription(obj));
  };

  useEffect(() => {
    dispatch(retrieveCourseSubscriptionList());
    dispatch(retrieveStudentsList());
    dispatch(retrieveCoursesList())
    dispatch(retrieveOperatorsList())
  }, [ dispatch ]);

  return (
    <div className="view">
      <ViewTitle title="Iscrizioni corsi"/>
      <SearchBarCourse
        options={ [
          { id: 1, value: 'nome-studente', type: 'text', text: 'Nome dello studente' },
          { id: 2, value: 'cognome-studente', type: 'text', text: 'Cognome dello studente' },
          { id: 3, value: 'titolo-corso', type: 'text', text: 'Titolo del corso' },
          { id: 4, value: 'data-inizio', type: 'date', text: 'Data inizio corso' },
          { id: 5, value: 'data-iscrizione', type: 'date', text: 'Data iscrizione' },
          { id: 6, value: 'codice-bsa', type: 'text', text: 'Codice BSA' }
        ] }
        onSearch={ onSubscriptionSearch }/>
      <Table
        columns={ courseSubscriptionColumns }
        rows={ courseSubscriptionsList }
        onView={ viewCourseSubscriptionHandler }
        onEdit={ editCourseSubscriptionHandler }
        onDelete={ deleteCourseSubscriptionHandler }
        onRestore={ restoreCourseSubscriptionHandler }
        openContract={ openSubscriptionContractHandler }
        onInstallmentsEdit={ editCourseSubscriptionInstallmentsHandler }/>
      <FloatingButton
        onClick={ addCourseSubscriptionHandler }/>
      <SubscriptionModal />
    </div>
  );

}

export default CourseSubscriptions;
