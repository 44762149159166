const LocationsInitialState = {
  locations: [],
  loading: false,
  error: null,
  selectedLocation: null,
  showModal: false,
  modalType: null
};

export default LocationsInitialState;
