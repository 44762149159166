import { Button, Modal } from "react-bootstrap";

const LocationModalRestore = ({ location, onClose, onRestore }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso ripristino sede
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Vuoi davvero ripristinare la sede <strong>{ location && location.sede }</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ onRestore }>Ripristina sede</Button>
      </Modal.Footer>
    </>
  );

}

export default LocationModalRestore;
