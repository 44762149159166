import useAuth from "../../hooks/useAuth";
import { Redirect, Route } from "react-router-dom";
import { Routes } from "../../constants/routes";

const PublicRoute = ({ component: Component, ...rest }) => {

  const { sessionExpired } = useAuth();

  return (
    <Route
      { ...rest }
      render={
        (props) => !sessionExpired
          ? (<Redirect to={{
            pathname: Routes.DASHBOARD,
            state: { from: props.location }
          }}/>)
          : (<Component { ...rest } { ...props } />)
      }
    />
  );

}

export default PublicRoute;
