import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import OperatorModalAdd from "./OperatorModalAdd";
import OperatorModalEdit from "./OperatorModalEdit";
import OperatorModalDelete from "./OperatorModalDelete";
import OperatorModalRestore from "./OperatorModalRestore";
import useOperators from "../../../hooks/useOperators";
import { closeOperatorsModal } from "../../../store/operators/actions";
import { addOperator, deleteOperator, editOperator, restoreOperator } from "../../../store/operators/thunk";

const OperatorModal = () => {

  const dispatch = useDispatch();

  const { selectedOperator, showOperatorsModal, modalType, isLoading } = useOperators();

  const closeModalHandler = () => dispatch(closeOperatorsModal());

  const addOperatorHandler = ({ name, surname, sex, email, role }) => dispatch(addOperator({ name, surname, sex, email, role }));
  const editOperatorHandler = (operator) => dispatch(editOperator({ id: selectedOperator.id, operator }));
  const deleteOperatorHandler = () => dispatch(deleteOperator(selectedOperator.id));
  const restoreOperatorHandler = () => dispatch(restoreOperator(selectedOperator.id));

  return (
    <Modal
      size="lg"
      centered
      show={ showOperatorsModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.Operators.ADD &&
        <OperatorModalAdd
          loading={ isLoading }
          onClose={ closeModalHandler }
          onAdd={ addOperatorHandler }/>
      }
      {
        modalType && modalType === Modals.Operators.EDIT &&
        <OperatorModalEdit
          loading={ isLoading }
          onClose={ closeModalHandler }
          onEdit={ editOperatorHandler }
          operator={ selectedOperator }/>
      }
      {
        modalType && modalType === Modals.Operators.DELETE &&
        <OperatorModalDelete
          loading={ isLoading }
          onClose={ closeModalHandler }
          onDelete={ deleteOperatorHandler }
          operator={ selectedOperator }/>
      }
      {
        modalType && modalType === Modals.Operators.RESTORE &&
        <OperatorModalRestore
          loading={ isLoading }
          onClose={ closeModalHandler }
          onRestore={ restoreOperatorHandler }
          operator={ selectedOperator }/>
      }
    </Modal>
  );

}

export default OperatorModal;
