import styled from "styled-components";
import { useEffect, useState } from "react";
import { Button, Form, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

const SearchBarCourse = ({ loading, options, onSearch }) => {

  const [ selectedOption, setSelectedOption ] = useState(options[ 0 ].value);
  const [ inputType, setInputType ] = useState('text');
  const [ value, setValue ] = useState('');
  const [ btnDisabled, setBtnDisabled ] = useState(true);

  const onSelectChangeHandler = (e) => {
    const option = options.find((option) => option.value === e.target.value);
    setInputType(option.type);
    setSelectedOption(e.target.value);
  };
  const onValueChangeHandler = (e) => setValue(e.target.value);
  const onKeyPressHandler = (e) => {
    if (e.charCode === 13 && e.code === 'Enter') {
      e.preventDefault();
      onSearch({ criteria: selectedOption, value });
      setBtnDisabled(true);
    }
  };
  const onButtonClickHandler = () => {
    onSearch({ criteria: selectedOption, value });
    setBtnDisabled(true);
  };

  useEffect(() => {
    if (value && value.trim() && value.trim().length > 0) {
      setBtnDisabled(false);
    }
    // else {
    //   setBtnDisabled(true);
    // }
  }, [ value ]);

  return (
    <Container>
      <TextContainer>
        Ricerca per:
      </TextContainer>
      <SelectContainer>
        <Form.Control
          id="searchCriteria"
          as="select"
          value={ selectedOption }
          onChange={ onSelectChangeHandler }
          onBlur={ onSelectChangeHandler }
          required>
          {
            options && options.length > 0 && options.map((option) => (
              <option
                key={ option.id }
                disabled={ option.id === 0 }
                value={ option.value }>{ option.text }</option>
            ))
          }
        </Form.Control>
      </SelectContainer>
      <SearchContainer>
        <Form.Control
          onKeyPress={ onKeyPressHandler }
          onChange={ onValueChangeHandler }
          value={ value }
          type={ inputType }
          placeholder="Scrivi il testo da cercare..."/>
      </SearchContainer>
      <ButtonContainer>
        <Button
          variant="outline-primary"
          size="sm"
          disabled={ btnDisabled }
          onClick={ onButtonClickHandler }>
          {
            loading && (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> Ricerca in corso...
              </>
            )
          }
          {
            !loading && (
              <>
                <FontAwesomeIcon
                  icon={ faSearch }/> Avvia la ricerca
              </>
            )
          }
        </Button>
      </ButtonContainer>
    </Container>
  );

};

export default SearchBarCourse;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem 1rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
  margin-bottom: 1rem;
`;

const TextContainer = styled.div`
  margin-right: 10px;
`;

const SelectContainer = styled.div`
  margin-right: 10px;
`;

const SearchContainer = styled.div`
  flex: 1;
  margin-right: 10px;
`;

const ButtonContainer = styled.div`
  
`;
