import { Slices } from "../../constants/slices";
import AuthInitialState from "./state";
import login from "./thunk";
import { storeUser } from "./actions";

const { createSlice } = require("@reduxjs/toolkit");

const slice = createSlice({
  name: Slices.AUTH,
  initialState: AuthInitialState,
  reducers: {},
  extraReducers: {
    [login.pending.type]: (state, action) => ({
      ...state,
      loading: true
    }),
    [login.fulfilled.type]: (state, action) => ({
      ...state,
      loading: false,
      user: action.payload,
      error: null
    }),
    [login.rejected.type]: (state, action) => ({
      ...state,
      loading: false,
      error: action.error.message
    }),
    [storeUser]: (state, action) => ({
      ...state,
      user: action.payload
    })
  }
});

export default slice.reducer;
