import { useDispatch, useSelector } from "react-redux";
import { courseSearchErrorSelector, courseSearchLoadingSelector, courseSearchResultsSelector } from "../store/courseSearch/selectors";
import { exportCSV } from "../store/courseSearch/thunk";

const useCourseSearch = () => {

  const dispatch = useDispatch();

  const loading = useSelector(courseSearchLoadingSelector);
  const results = useSelector(courseSearchResultsSelector);
  const error = useSelector(courseSearchErrorSelector);

  const onStudentExport = (courseId) => dispatch(exportCSV({ courseId, type: 'studenti' }));
  const onStudentCompleteExport = (courseId) => dispatch(exportCSV({ courseId, type: 'studenti-completo' }));
  const onSubscriptionExport = (courseId) => dispatch(exportCSV({ courseId, type: 'iscrizioni' }));
  const onInstallmentsExport = (courseId) => dispatch(exportCSV({ courseId, type: 'pagamenti' }));
  const onFadExport = (courseId) => dispatch(exportCSV({ courseId, type: 'fad' }));
  const onDetailsExport = (courseId) => dispatch(exportCSV({ courseId, type: 'dettagli' }));

  return {
    loading,
    results,
    error,
    onStudentExport,
    onStudentCompleteExport,
    onSubscriptionExport,
    onInstallmentsExport,
    onFadExport,
    onDetailsExport
  };

};

export default useCourseSearch;
