import { useSelector } from "react-redux";
import { courseModalSelector, courseModalType, coursesListSelector, coursesLoadingSelector, selectedCourseSelector } from "../store/courses/selectors";
import { studentsLoadingSelector } from "../store/students/selectors";

const useCourses = () => {

  const coursesColumns = [
    { id: 'id', name: 'ID' },
    { id: 'id_tipologia', name: null },
    { id: 'id_sede', name: null },
    { id: 'id_turno', name: null },
    { id: 'id_titolo', name: null },
    { id: 'turno', name: null },
    { id: 'tipologia', name: 'Categoria' },
    { id: 'codice_regionale', name: null },
    { id: 'codice_bsa', name: 'Cod. BSA' },
    { id: 'frequenza', name: null },
    { id: 'titolo', name: 'Corso' },
    { id: 'ore', name: 'Ore' },
    { id: 'data_inizio', name: 'Data Inizio' },
    { id: 'data_fine', name: null },
    { id: 'data_esame', name: null },
    { id: 'data_esame_2', name: null },
    { id: 'protocollo_apertura_corso', name: null },
    { id: 'numero_iscritti_partenza', name: null },
    { id: 'numero_iscritti_10', name: null },
    { id: 'abbandoni', name: null },
    { id: 'numero_ammessi_esame', name: null },
    { id: 'note', name: null },
    { id: 'sede', name: 'Sede' },
    { id: 'deleted', name: 'Stato' },
    { id: 'fad_sync', name: null },
    { id: 'fad_async', name: null },
    { id: 'completed_female', name: null },
    { id: 'completed_male', name: null },
    { id: 'docenti', name: null },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const coursesList = useSelector(coursesListSelector);
  const selectedCourse = useSelector(selectedCourseSelector);
  const showCoursesModal = useSelector(courseModalSelector);
  const modalType = useSelector(courseModalType);
  const isLoading = useSelector(coursesLoadingSelector);

  return {
    coursesColumns,
    coursesList,
    selectedCourse,
    showCoursesModal,
    modalType,
    isLoading
  };

};

export default useCourses;
