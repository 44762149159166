const { createSelector } = require("@reduxjs/toolkit");

const StudentsStore = (state) => state.students;

export const studentsLoadingSelector = createSelector(
  StudentsStore,
  (studentsState) => studentsState.loading
);

export const studentsErrorSelector = createSelector(
  StudentsStore,
  (studentsState) => studentsState.error
);

export const studentsListSelector = createSelector(
  StudentsStore,
  (studentsState) => studentsState.students
);

export const selectedStudentSelector = createSelector(
  StudentsStore,
  (studentsState) => studentsState.selectedStudent
);

export const studentsModalSelector = createSelector(
  StudentsStore,
  (studentsState) => studentsState.showModal
);

export const studentsModalType = createSelector(
  StudentsStore,
  (studentsState) => studentsState.modalType
);
