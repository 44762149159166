import { Switch } from "react-router-dom";
import { Routes } from "../../constants/routes";
import ProtectedRoute from "../routes/ProtectedRoute";
import PublicRoute from "../routes/PublicRoute";
import Login from "../../pages/Login/Login";
import Dashboard from "../../pages/Dashboard/Dashboard";
import Courses from "../../pages/Courses/Courses";
import Students from "../../pages/Students/Students";
import Operators from "../../pages/Operators/Operators";
import CourseSubscriptions from "../../pages/CourseSubscriptions/CourseSubscriptions";
import Exports from "../../pages/Exports/Exports";

const ViewContainer = () => {

  return (
    <Switch>
      <PublicRoute exact path={ Routes.LOGIN } component={ Login }/>
      <ProtectedRoute exact path={ Routes.DASHBOARD } component={ Dashboard }/>
      <ProtectedRoute exact path={ Routes.COURSES } component={ Courses }/>
      <ProtectedRoute exact path={ Routes.STUDENTS } component={ Students }/>
      <ProtectedRoute exact path={ Routes.SUBSCRIPTIONS } component={ CourseSubscriptions }/>
      <ProtectedRoute exact path={ Routes.OPERATORS } component={ Operators }/>
      <ProtectedRoute exact path={ Routes.EXPORTS } component={ Exports }/>
    </Switch>
  );

};

export default ViewContainer;
