const { createSelector } = require("@reduxjs/toolkit");

const CourseSubscriptionsStore = (state) => state.courseSubscriptions;

export const courseSubscriptionsLoadingSelector = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.loading
);

export const courseSubscriptionsErrorSelector = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.error
);

export const courseSubscriptionsListSelector = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.subscriptions
);

export const selectedCourseSubscriptionSelector = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.selectedSubscription
);

export const courseSubscriptionsModalSelector = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.showModal
);

export const courseSubscriptionsModalType = createSelector(
  CourseSubscriptionsStore,
  (courseSubscriptionsState) => courseSubscriptionsState.modalType
);
