import { Button, Nav, Navbar, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChartLine, faDownload, faFeatherAlt, faSchool, faSignOutAlt, faUser, faUserGraduate } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../hooks/useAuth";
import { withRouter } from 'react-router-dom';
import { Routes } from "../../constants/routes";
import logo from '../../assets/images/logo.png';

const NavigationBar = withRouter((props) => {

  const { location } = props;

  const { logoutSubmit, user } = useAuth();

  return (
    <Navbar expand="lg" bg="dark" variant="dark" className="justify-content-between" sticky="top">
      <Navbar.Brand href={ `/#${ Routes.DASHBOARD }` }>
        <img
          alt=""
          height="40"
          src={ logo }
          className="d-inline-block align-top"
        />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav"/>
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav activeKey={ `/#${ location.pathname }` } className="mr-auto">
          <NavLink href={ `/#${ Routes.DASHBOARD }` }>
            <FontAwesomeIcon icon={ faChartLine } size="sm"/> Dashboard
          </NavLink>
          <NavLink href={ `/#${ Routes.COURSES }` }>
            <FontAwesomeIcon icon={ faSchool } size="sm"/> Corsi
          </NavLink>
          <NavLink href={ `/#${ Routes.STUDENTS }` }>
            <FontAwesomeIcon icon={ faUserGraduate } size="sm"/> Studenti
          </NavLink>
          <NavLink href={ `/#${ Routes.SUBSCRIPTIONS }` }>
            <FontAwesomeIcon icon={ faFeatherAlt } size="sm"/> Iscrizioni
          </NavLink>
          {
            user.ruolo === 'admin' && (
              <NavLink href={ `/#${ Routes.OPERATORS }` }>
                <FontAwesomeIcon icon={ faUser } size="sm"/> Operatori
              </NavLink>
            )
          }
          <NavLink href={ `/#${ Routes.EXPORTS }` }>
            <FontAwesomeIcon icon={ faDownload } size="sm"/> Esportazione
          </NavLink>
        </Nav>
        <Navbar.Text className="current-operator">
          Operatore: { user.nome } { user.cognome }
        </Navbar.Text>
        <Navbar.Text>
          <Button variant="outline-light" size="sm" onClick={ logoutSubmit }>
            <FontAwesomeIcon icon={ faSignOutAlt }/> Logout
          </Button>
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );

});

export default NavigationBar;
