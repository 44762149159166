const { createSelector } = require("@reduxjs/toolkit");

const CoursesStore = (state) => state.courses;

export const coursesLoadingSelector = createSelector(
  CoursesStore,
  (coursesState) => coursesState.loading
);

export const coursesErrorSelector = createSelector(
  CoursesStore,
  (coursesState) => coursesState.error
);

export const coursesListSelector = createSelector(
  CoursesStore,
  (coursesState) => coursesState.courses
);

export const selectedCourseSelector = createSelector(
  CoursesStore,
  (coursesState) => coursesState.selectedCourse
);

export const courseModalSelector = createSelector(
  CoursesStore,
  (coursesState) => coursesState.showModal
);

export const courseModalType = createSelector(
  CoursesStore,
  (coursesState) => coursesState.modalType
);
