import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const CourseTypeModalAdd = ({ onClose, onAdd }) => {

  const [ type, setType ] = useState('');
  const [ typeIsTouched, setTypeIsTouched ] = useState(false);
  const [ typeIsValid, setTypeIsValid ] = useState(false);

  const typeHandler = (e) => setType(e.target.value);

  const typeBlurHandler = (e) => {
    setTypeIsTouched(true);
    setTypeIsValid(validateType(e.target.value));
  };

  const validateType = (value) => value && typeof value === 'string' && value.trim().length > 0;

  const addTypeHandler = (e) => {
    e.preventDefault();
    if (typeIsValid) {
      onAdd({ type });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Creazione nuova categoria
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ addTypeHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseType">
                <Form.Label>Categoria del corso</Form.Label>
                <Form.Control
                  onChange={ typeHandler }
                  onBlur={ typeBlurHandler }
                  value={ type }
                  type="text"
                  placeholder="Inserisci il nome della categoria"/>
                { !type && typeIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ addTypeHandler }>Inserisci categoria</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTypeModalAdd;
