const { createSelector } = require("@reduxjs/toolkit");

const KpiStore = (state) => state.kpi;

export const coursesLoadingSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.loading
);

export const coursesErrorSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.error
);

export const globalForecastCashFlowSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.globalForecastCashFlow
);

export const globalRealCashFlowSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.globalRealCashFlow
);

export const forecastCashFlowSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.forecastCashFlow
);

export const realCashFlowSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.realCashFlow
);

export const courseSubscriptionEnrollmentsSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.courseSubscriptionEnrollments
);

export const courseSubscriptionTypesSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.courseSubscriptionTypes
);

export const howKnowAboutSchoolRanksSelector = createSelector(
  KpiStore,
  (kpiState) => kpiState.howKnowAboutSchoolRanks
);
