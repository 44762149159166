const KPIInitialState = {
  loading: false,
  error: null,
  globalForecastCashFlow: [
    {
      month: "Gennaio",
      amount: 0
    },
    {
      month: "Febbraio",
      amount: 0
    },
    {
      month: "Marzo",
      amount:0
    },
    {
      month: "Aprile",
      amount: 0
    },
    {
      month: "Maggio",
      amount: 0
    },
    {
      month: "Giugno",
      amount: 0
    },
    {
      month: "Luglio",
      amount: 0
    },
    {
      month: "Agosto",
      amount: 0
    },
    {
      month: "Settembre",
      amount: 0
    },
    {
      month: "Ottobre",
      amount: 0
    },
    {
      month: "Novembre",
      amount: 0
    },
    {
      month: "Dicembre",
      amount: 0
    }
  ],
  globalRealCashFlow: [
    {
      month: "Gennaio",
      amount: 0
    },
    {
      month: "Febbraio",
      amount: 0
    },
    {
      month: "Marzo",
      amount: 0
    },
    {
      month: "Aprile",
      amount: 0
    },
    {
      month: "Maggio",
      amount: 0
    },
    {
      month: "Giugno",
      amount: 0
    },
    {
      month: "Luglio",
      amount: 0
    },
    {
      month: "Agosto",
      amount: 0
    },
    {
      month: "Settembre",
      amount: 0
    },
    {
      month: "Ottobre",
      amount: 0
    },
    {
      month: "Novembre",
      amount: 0
    },
    {
      month: "Dicembre",
      amount: 0
    }
  ],
  forecastCashFlow: 0,
  realCashFlow: 0,
  courseSubscriptionEnrollments: [],
  courseSubscriptionTypes: [],
  howKnowAboutSchoolRanks: []
};

export default KPIInitialState;
