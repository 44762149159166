export const Modals = {
  Courses: {
    ADD: 'courses-add',
    EDIT: 'courses-edit',
    DELETE: 'courses-delete',
    RESTORE: 'courses-restore'
  },
  CourseSubscriptions: {
    VIEW: 'courseSubscription-view',
    ADD: 'courseSubscription-add',
    EDIT: 'courseSubscription-edit',
    EDIT_INSTALLMENTS: 'courseSubscription-edit-installments',
    DELETE: 'courseSubscription-delete',
    RESTORE: 'courseSubscription-restore'
  },
  CoursesType: {
    ADD: 'courses-type-add',
    EDIT: 'courses-type-edit',
    DELETE: 'courses-type-delete',
    RESTORE: 'courses-type-restore'
  },
  CourseTurns: {
    ADD: 'course-turn-add',
    EDIT: 'course-turn-edit',
    DELETE: 'course-turn-delete',
    RESTORE: 'course-turn-restore'
  },
  CourseTitles: {
    ADD: 'course-title-add',
    EDIT: 'course-title-edit',
    DELETE: 'course-title-delete',
    RESTORE: 'course-title-restore'
  },
  Locations: {
    ADD: 'locations-add',
    EDIT: 'locations-edit',
    DELETE: 'locations-delete',
    RESTORE: 'locations-restore'
  },
  Operators: {
    ADD: 'operators-add',
    EDIT: 'operators-edit',
    CHANGE_STATUS: 'operators-change-status',
    DELETE: 'operators-delete',
    RESTORE: 'operators-restore'
  },
  Students: {
    VIEW: 'student-view',
    ADD: 'students-add',
    EDIT: 'students-edit',
    DELETE: 'students-delete',
    RESTORE: 'students-restore'
  }
};
