import { useSelector } from "react-redux";
import { selectedTurnSelector, turnModalSelector, turnModalType, turnsListSelector } from "../store/courseTurns/selectors";

const useCourseTurns = () => {

  const turnsColumns = [
    { id: 'id', name: 'ID' },
    { id: 'turno', name: 'Turno' },
    { id: 'descrizione', name: null },
    { id: 'deleted', name: 'Stato' },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const turnsList = useSelector(turnsListSelector);
  const selectedTurn = useSelector(selectedTurnSelector);
  const showTurnModal = useSelector(turnModalSelector);
  const modalType = useSelector(turnModalType);

  return {
    turnsColumns,
    turnsList,
    selectedTurn,
    showTurnModal,
    modalType
  };

};

export default useCourseTurns;
