import classes from './Login.module.scss';
import Card from "../../components/Card/Card";
import LoginForm from "../../components/forms/LoginForm/LoginForm";
import backgroundImage from '../../assets/images/login-background.jpeg';
import logo from '../../assets/images/logo.png';
import useAuth from "../../hooks/useAuth";
import { GOOGLE_RECAPTCHA_PUBLIC_KEY } from "../../constants/apikeys";
import { useEffect } from "react";
import { reCAPTCHADestroy, reCAPTCHAInitialize } from "../../utils/recaptcha";

const Login = () => {

  const { loading, error, submitHandler } = useAuth();

  useEffect(() => {
    reCAPTCHAInitialize();
    return () => reCAPTCHADestroy();
  }, []);

  return (
    <div className="login" style={{ backgroundImage: `url(${ backgroundImage })` }}>
      <Card>
        <div className={ classes['image-container'] }>
          <img
            alt=""
            src={ logo }
            className="d-inline-block align-top"
          />
        </div>
        <LoginForm
          loading={ loading }
          errorMessage={ error }
          onSubmit={ submitHandler }/>
      </Card>
      <div className="g-recaptcha" data-size="invisible" data-sitekey={ GOOGLE_RECAPTCHA_PUBLIC_KEY } />
    </div>
  );

}

export default Login;
