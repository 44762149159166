import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const TurnModalEdit = ({ selectedTurn, onClose, onEdit }) => {

  const validateTurn = (value) => value && value.trim().length >= 3;

  const [ turn, setTurn ] = useState(selectedTurn && selectedTurn.turno ? selectedTurn.turno : '');
  const [ description, setDescription ] = useState(selectedTurn && selectedTurn.descrizione ? selectedTurn.descrizione : '');
  const [ turnIsTouched, setTurnIsTouched ] = useState(true);
  const [ turnIsValid, setTurnIsValid ] = useState(validateTurn(selectedTurn && selectedTurn.turno ? selectedTurn.turno : ''));

  const turnHandler = (e) => {
    setTurn(e.target.value);
    setTurnIsValid(validateTurn(e.target.value));
  };
  const descriptionHandler = (e) => setDescription(e.target.value);

  const turnBlurHandler = (e) => {
    setTurnIsTouched(true);
    setTurnIsValid(validateTurn(e.target.value));
  };

  const editTurnHandler = (e) => {
    e.preventDefault();
    if (turnIsValid) {
      onEdit({ turn, description });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Modifica turno
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ editTurnHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formTurn">
                <Form.Label>Turno</Form.Label>
                <Form.Control
                  onChange={ turnHandler }
                  onBlur={ turnBlurHandler }
                  value={ turn }
                  type="text"
                  placeholder="Inserisci il turno"
                  required/>
                { !turnIsValid && turnIsTouched && <small className="text-danger">Lunghezza minima 3 caratteri</small> }
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group controlId="formTurn">
                <Form.Label>Descrizione</Form.Label>
                <Form.Control
                  as="textarea"
                  onChange={ descriptionHandler }
                  value={ description }
                  placeholder="Inserisci una descrizione"/>
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ editTurnHandler } disabled={ !turnIsValid }>Modifica turno</Button>
      </Modal.Footer>
    </>
  );

}

export default TurnModalEdit;
