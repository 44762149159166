import { Modal } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Modals } from "../../../constants/modals";
import useStudents from "../../../hooks/useStudents";
import { closeStudentsModal } from "../../../store/students/actions";
import { addStudent, deleteStudent, editStudent, restoreStudent } from "../../../store/students/thunk";
import StudentModalAdd from "./StudentModalAdd";
import StudentModalEdit from "./StudentModalEdit";
import StudentModalDelete from "./StudentModalDelete";
import StudentModalRestore from "./StudentModalRestore";
import StudentModalView from "./StudentModalView";

const StudentModal = () => {

  const dispatch = useDispatch();

  const { selectedStudent, showStudentsModal, modalType, isLoading } = useStudents();

  const closeModalHandler = () => dispatch(closeStudentsModal());

  const addStudentHandler = (student) => dispatch(addStudent(student));
  const editStudentHandler = (student) => dispatch(editStudent({ id: selectedStudent.id, student }));
  const deleteStudentHandler = () => dispatch(deleteStudent(selectedStudent.id));
  const restoreStudentHandler = () => dispatch(restoreStudent(selectedStudent.id));

  return (
    <Modal
      size="xl"
      centered
      show={ showStudentsModal }
      onHide={ closeModalHandler }>
      {
        modalType && modalType === Modals.Students.ADD &&
        <StudentModalAdd
          loading={ isLoading }
          onClose={ closeModalHandler }
          onAdd={ addStudentHandler }/>
      }
      {
        modalType && modalType === Modals.Students.VIEW &&
        <StudentModalView
          loading={ isLoading }
          onClose={ closeModalHandler }
          student={ selectedStudent }/>
      }
      {
        modalType && modalType === Modals.Students.EDIT &&
        <StudentModalEdit
          loading={ isLoading }
          onClose={ closeModalHandler }
          onEdit={ editStudentHandler }
          student={ selectedStudent }/>
      }
      {
        modalType && modalType === Modals.Students.DELETE &&
        <StudentModalDelete
          loading={ isLoading }
          onClose={ closeModalHandler }
          onDelete={ deleteStudentHandler }
          student={ selectedStudent }/>
      }
      {
        modalType && modalType === Modals.Students.RESTORE &&
        <StudentModalRestore
          loading={ isLoading }
          onClose={ closeModalHandler }
          onRestore={ restoreStudentHandler }
          student={ selectedStudent }/>
      }
    </Modal>
  );

}

export default StudentModal;
