import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { useState } from "react";

const LocationModalAdd = ({ onClose, onAdd }) => {

  const [ location, setLocation ] = useState('');
  const [ locationIsTouched, setLocationIsTouched ] = useState(false);
  const [ locationIsValid, setLocationIsValid ] = useState(false);

  const locationHandler = (e) => setLocation(e.target.value);

  const locationBlurHandler = (e) => {
    setLocationIsTouched(true);
    setLocationIsValid(validateLocation(e.target.value));
  };

  const validateLocation = (value) => value && typeof value === 'string' && value.trim().length > 0;

  const addLocationHandler = (e) => {
    e.preventDefault();
    if (locationIsValid) {
      onAdd({ location });
    }
  };

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Creazione nuova sede
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form onSubmit={ addLocationHandler }>
          <Row>
            <Col>
              <Form.Group controlId="formCourseType">
                <Form.Label>Sede</Form.Label>
                <Form.Control
                  onChange={ locationHandler }
                  onBlur={ locationBlurHandler }
                  value={ location }
                  type="text"
                  placeholder="Inserisci il nome della sede"/>
                { !location && locationIsTouched && <small className="text-danger">Campo obbligatorio</small> }
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ addLocationHandler }>Inserisci sede</Button>
      </Modal.Footer>
    </>
  );

}

export default LocationModalAdd;
