import classes from './ViewTitle.module.scss';

const ViewTitle = ({ title, size = 'h1' }) => {

  return (
    <div className={ classes['view-title'] }>
      {
        size === 'h1' && (
          <h1>{ title }</h1>
        )
      }
      {
        size === 'h2' && (
          <h2>{ title }</h2>
        )
      }
      {
        size === 'h3' && (
          <h3>{ title }</h3>
        )
      }
      {
        size === 'h4' && (
          <h4>{ title }</h4>
        )
      }
      {
        size === 'h5' && (
          <h5>{ title }</h5>
        )
      }
      {
        size === 'h6' && (
          <h6>{ title }</h6>
        )
      }
    </div>
  );

}

export default ViewTitle;
