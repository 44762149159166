import { Button, Modal } from "react-bootstrap";

const CourseTitleModalRestore = ({ title, onClose, onRestore }) => {

  return (
    <>
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter">
          Avviso ripristino titolo corso
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Vuoi davvero ripristinare il titolo <strong>{ title && title.titolo }</strong>?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={ onClose }>Chiudi</Button>
        <Button variant="primary" onClick={ onRestore }>Ripristina titolo</Button>
      </Modal.Footer>
    </>
  );

}

export default CourseTitleModalRestore;
