import { createAsyncThunk } from "@reduxjs/toolkit";
import { endpoints } from "../../constants/endpoints";
import { http } from "../../utils/http";

export const retrieveLocationsList = createAsyncThunk('Locations/List', async () => {
  let data;
  try {
    const response = await http(endpoints.LOCATIONS, {
      method: 'GET'
    });
    data = response.data;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return data.sedi;
});

export const addLocation = createAsyncThunk('Locations/Add', async ({ location }) => {
  let insertedLocation;
  try {
    const response = await http(endpoints.LOCATIONS, {
      method: 'POST',
      data: {
        name: location
      }
    });
    insertedLocation = response.data.sede;
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return insertedLocation;
});

export const editLocation = createAsyncThunk('Locations/Edit', async ({ id, location }) => {
  let response;
  try {
    response = await http(`${ endpoints.LOCATIONS }/${ id }`, {
      method: 'PATCH',
      data: {
        name: location
      }
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return response.data.sede;
});

export const deleteLocation = createAsyncThunk('Locations/Delete', async (id) => {
  try {
    await http(`${ endpoints.LOCATIONS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

export const restoreLocation = createAsyncThunk('Locations/Restore', async (id) => {
  try {
    await http(`${ endpoints.LOCATIONS }/${ id }`, {
      method: 'DELETE'
    });
  } catch (e) {
    throw Error(e.response.data.message);
  }
  return id;
});

const thunks = { retrieveLocationsList, addLocation, deleteLocation, restoreLocation };

export default thunks;
