import classes from '../TableCellActions.module.scss';
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faFileSignature, faPencilAlt, faTrashAlt, faTrashRestore } from "@fortawesome/free-solid-svg-icons";
import useAuth from "../../../hooks/useAuth";

const SubscriptionActions = ({
  viewHandler,
  editDisabled,
  deleteDisabled,
  restoreDisabled,
  editHandler,
  installmentsEditHandler,
  deleteHandler,
  restoreHandler,
  openContractHandler
}) => {

  const { userRole } = useAuth();

  return (
    <td className={ classes.cell }>
      {
        viewHandler && (
          <Button
            variant="link"
            size="sm"
            disabled={ false }
            onClick={ viewHandler }>
            <FontAwesomeIcon icon={ faEye }/> Visualizza
          </Button>
        )
      }
      <Button
        variant="link"
        size="sm"
        disabled={ false }
        onClick={ openContractHandler }>
        <FontAwesomeIcon icon={ faFileSignature }/> Contratto
      </Button>
      <Button
        variant="link"
        size="sm"
        disabled={ editDisabled }
        onClick={ editHandler }>
        <FontAwesomeIcon icon={ faPencilAlt }/> Modifica iscrizione
      </Button>
      <Button
        variant="link"
        size="sm"
        disabled={ editDisabled }
        onClick={ installmentsEditHandler }>
        <FontAwesomeIcon icon={ faPencilAlt }/> Aggiorna rate
      </Button>
      {
        userRole && userRole === 'admin' && (
          <Button
            variant="link"
            size="sm"
            disabled={ deleteDisabled }
            onClick={ deleteHandler }>
            <FontAwesomeIcon icon={ faTrashAlt }/> Annulla iscrizione
          </Button>
        )
      }
      {
        userRole && userRole === 'admin' && (
          <Button
            variant="link"
            size="sm"
            disabled={ restoreDisabled }
            onClick={ restoreHandler }>
            <FontAwesomeIcon icon={ faTrashRestore }/> Ripristina iscrizione
          </Button>
        )
      }
    </td>
  );

}

export default SubscriptionActions;
