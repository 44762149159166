import { useSelector } from "react-redux";
import { courseModalType, locationModalSelector, locationsListSelector, selectedLocationSelector } from "../store/locations/selectors";

const useLocations = () => {

  const locationsColumns = [
    { id: 'id', name: 'ID' },
    { id: 'sede', name: 'Sede' },
    { id: 'deleted', name: 'Stato' },
    { id: 'actions', name: 'Azioni disponibili' }
  ];

  const locationsList = useSelector(locationsListSelector);
  const selectedLocation = useSelector(selectedLocationSelector);
  const showLocationModal = useSelector(locationModalSelector);
  const modalType = useSelector(courseModalType);

  return {
    locationsColumns,
    locationsList,
    selectedLocation,
    showLocationModal,
    modalType
  };

};

export default useLocations;
